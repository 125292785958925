// @import './style/varables.scss';
// @import './style/ok-styleguide.scss';

// @include osg-mq-tablet-and-up() {
//   .bymelding-content-container {
//     margin-top: 100px;
//     // height: calc(100vh - 100px);
//   }
// }

// @include osg-mq-mobile-only() {
//   .bymelding-content-container {
//     margin-top: 74px;
//     // height: calc(100vh - 74px);
//   }
// }

.snarvei {
  left: -20000px;
  position: fixed;
  top: 0;
  background: #fff;
  display: block;
  z-index: 99999;
  width: 100%;
  padding: 0 15px;
}

.snarvei:focus {
  left: 0;
}

body {
  font-variant-ligatures: no-contextual;
  -moz-font-feature-settings: 'calt' 0;
  -webkit-font-feature-settings: 'calt' 0;
  -ms-font-feature-settings: 'calt' 0;
  font-feature-settings: 'calt' 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant-ligatures: contextual;
  -moz-font-feature-settings: 'calt';
  -webkit-font-feature-settings: 'calt';
  -ms-font-feature-settings: 'calt';
  font-feature-settings: 'calt';
}
