.leaflet-touch .leaflet-control-layers,
.leaflet-retina .leaflet-control-layers,
.leaflet-control-layers-toggle {
  display: none;
}

.grunnkart-options-mobile {
  display: none;
}

.grunnkart-options {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.grunnkart-options {
  position: fixed;
  bottom: 95px;
  right: 10px;
  .grunnkart-option {
    background-size: 119px 90px;
    &:disabled {
      color: rgb(170, 170, 170);
    }
  }

  .grunnkart-option-grunnkart {
    background-image: url('../../images/map-preview-base.jpg');
  }

  .grunnkart-option-detaljert_grunnkart {
    background-image: url('../../images/map-preview-detail.jpg');
  }

  .grunnkart-option-satelite_grunnkart {
    background-image: url('../../images/map-preview-sattelite.jpg');
    color: #fff;
  }
}

@media (max-width: 450px) {
  .grunnkart-options-mobile {
    position: fixed;
    bottom: 85px;
    right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .grunnkart-options {
    bottom: 85px;
    &.closed {
      display: none;
    }
  }
}
