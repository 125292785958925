*, ::before, ::after {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  ;
}

::backdrop {

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-gradient-from-position:  ;

    --tw-gradient-via-position:  ;

    --tw-gradient-to-position:  ;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  ;
}

.container {

    width: 100%;

    margin-right: auto;

    margin-left: auto;
}

@media (min-width: 576px) {

    .container {

        max-width: 576px;
    }
}

@media (min-width: 1024px) {

    .container {

        max-width: 1024px;
    }
}

@media (min-width: 1280px) {

    .container {

        max-width: 1280px;
    }
}

@media (min-width: 1600px) {

    .container {

        max-width: 1600px;
    }
}

.heading-1 {

    font-size: 1.75rem !important;

    font-weight: 400 !important;

    letter-spacing: -0.9px;
}

@media (min-width: 576px) {

    .heading-1 {

        font-size: 2.5rem !important;
    }
}

@media (min-width: 1024px) {

    .heading-1 {

        font-size: 3.375rem !important;
    }
}

.heading-2 {

    font-size: 1.5rem !important;

    font-weight: 400 !important;

    letter-spacing: 0.3px;
}

@media (min-width: 576px) {

    .heading-2 {

        font-size: 1.875rem !important;
    }
}

@media (min-width: 1024px) {

    .heading-2 {

        font-size: 2.25rem !important;
    }
}

.heading-3 {

    font-size: 1.375rem !important;

    font-weight: 400 !important;

    letter-spacing: -0.3px;
}

@media (min-width: 576px) {

    .heading-3 {

        font-size: 1.625rem !important;
    }
}

@media (min-width: 1024px) {

    .heading-3 {

        font-size: 1.875rem !important;
    }
}

.heading-4 {

    font-size: 1.25rem !important;

    font-weight: 500 !important;

    letter-spacing: -0.3px;
}

@media (min-width: 576px) {

    .heading-4 {

        font-size: 1.375rem !important;
    }
}

@media (min-width: 1024px) {

    .heading-4 {

        font-size: 1.5rem !important;
    }
}

.heading-5 {

    font-size: 1rem !important;

    font-weight: 500 !important;

    letter-spacing: -0.3px;
}

@media (min-width: 576px) {

    .heading-5 {

        font-size: 1rem !important;
    }
}

@media (min-width: 1024px) {

    .heading-5 {

        font-size: 1.125rem !important;
    }
}

.heading-modal {

    font-size: 1rem !important;

    font-weight: normal !important;

    letter-spacing: -0.2px;
}

@media (min-width: 576px) {

    .heading-modal {

        font-size: 1.625rem !important;
    }
}

@media (min-width: 1024px) {

    .heading-modal {

        font-size: 1.75rem !important;
    }
}

.heading-modal-sub {

    font-size: .875rem !important;

    font-weight: 300 !important;

    letter-spacing: normal;
}

@media (min-width: 576px) {

    .heading-modal-sub {

        font-size: .875rem !important;
    }
}

@media (min-width: 1024px) {

    .heading-modal-sub {

        font-size: .875rem !important;
    }
}

.text-1 {

    font-size: 1.375rem;

    font-weight: 300;

    line-height: 1.6;
}

@media (min-width: 576px) {

    .text-1 {

        font-size: 1.5rem;
    }
}

@media (min-width: 1024px) {

    .text-1 {

        font-size: 1.875rem;
    }
}

.text-2 {

    font-size: 1.25rem;

    font-weight: 300;

    line-height: 1.5;
}

@media (min-width: 576px) {

    .text-2 {

        font-size: 1.375rem;
    }
}

@media (min-width: 1024px) {

    .text-2 {

        font-size: 1.5rem;
    }
}

.text-3 {

    font-size: 1.125rem;

    font-weight: 300;

    line-height: 1.5;
}

@media (min-width: 576px) {

    .text-3 {

        font-size: 1.125rem;
    }
}

@media (min-width: 1024px) {

    .text-3 {

        font-size: 1.25rem;
    }
}

.text-4 {

    font-size: 1rem;

    font-weight: 500;

    line-height: 1.5;
}

@media (min-width: 576px) {

    .text-4 {

        font-size: 1rem;
    }
}

@media (min-width: 1024px) {

    .text-4 {

        font-size: 1.125rem;
    }
}

.text-5 {

    font-size: 1rem;

    font-weight: 300;

    line-height: 1.5;
}

@media (min-width: 576px) {

    .text-5 {

        font-size: 1rem;
    }
}

@media (min-width: 1024px) {

    .text-5 {

        font-size: 1.125rem;
    }
}

.text-6 {

    font-size: .875rem;

    font-weight: 500;

    line-height: 1.5;
}

@media (min-width: 576px) {

    .text-6 {

        font-size: .875rem;
    }
}

@media (min-width: 1024px) {

    .text-6 {

        font-size: .875rem;
    }
}

.text-7 {

    font-size: 1rem;

    font-weight: 300;

    line-height: 1.5;
}

@media (min-width: 576px) {

    .text-7 {

        font-size: 1rem;
    }
}

@media (min-width: 1024px) {

    .text-7 {

        font-size: 1.125rem;
    }
}

.sr-only {

    position: absolute;

    width: 1px;

    height: 1px;

    padding: 0;

    margin: -1px;

    overflow: hidden;

    clip: rect(0, 0, 0, 0);

    white-space: nowrap;

    border-width: 0;
}

.\!visible {

    visibility: visible !important;
}

.visible {

    visibility: visible;
}

.static {

    position: static;
}

.fixed {

    position: fixed;
}

.absolute {

    position: absolute;
}

.relative {

    position: relative;
}

.inset-x-0 {

    left: 0;

    right: 0;
}

.bottom-0 {

    bottom: 0;
}

.left-0 {

    left: 0;
}

.top-0 {

    top: 0;
}

.top-6 {

    top: 75px;
}

.top-nav-large {

    top: 91px;
}

.z-modal {

    z-index: 130;
}

.z-modalBackdrop {

    z-index: 120;
}

.z-nav {

    z-index: 90;
}

.m-1 {

    margin: 5px;
}

.m-2 {

    margin: 10px;
}

.m-3 {

    margin: 15px;
}

.m-auto {

    margin: auto;
}

.mx-2 {

    margin-left: 10px;

    margin-right: 10px;
}

.mx-3 {

    margin-left: 15px;

    margin-right: 15px;
}

.mx-6 {

    margin-left: 75px;

    margin-right: 75px;
}

.mx-auto {

    margin-left: auto;

    margin-right: auto;
}

.my-2 {

    margin-top: 10px;

    margin-bottom: 10px;
}

.my-3 {

    margin-top: 15px;

    margin-bottom: 15px;
}

.my-4 {

    margin-top: 30px;

    margin-bottom: 30px;
}

.my-5 {

    margin-top: 50px;

    margin-bottom: 50px;
}

.mb-0 {

    margin-bottom: 0px;
}

.mb-2 {

    margin-bottom: 10px;
}

.mb-3 {

    margin-bottom: 15px;
}

.mb-4 {

    margin-bottom: 30px;
}

.mb-6 {

    margin-bottom: 75px;
}

.ml-1 {

    margin-left: 5px;
}

.ml-2 {

    margin-left: 10px;
}

.ml-3 {

    margin-left: 15px;
}

.mr-1 {

    margin-right: 5px;
}

.mr-2 {

    margin-right: 10px;
}

.mr-3 {

    margin-right: 15px;
}

.mr-\[24px\] {

    margin-right: 24px;
}

.mt-2 {

    margin-top: 10px;
}

.mt-3 {

    margin-top: 15px;
}

.mt-6 {

    margin-top: 75px;
}

.mt-7 {

    margin-top: 100px;
}

.block {

    display: block;
}

.\!inline {

    display: inline !important;
}

.inline {

    display: inline;
}

.flex {

    display: flex;
}

.list-item {

    display: list-item;
}

.hidden {

    display: none;
}

.h-4 {

    height: 30px;
}

.h-5 {

    height: 50px;
}

.h-7 {

    height: 100px;
}

.h-body-large-header {

    height: calc(100vh - 91px);
}

.h-logo-large {

    height: 60px;
}

.h-nav-large {

    height: 91px;
}

.max-h-full {

    max-height: 100%;
}

.min-h-\[24px\] {

    min-height: 24px;
}

.min-h-screen {

    min-height: 100vh;
}

.w-1\/4 {

    width: 25%;
}

.w-16 {

    width: 4rem;
}

.w-4 {

    width: 30px;
}

.w-40 {

    width: 10rem;
}

.w-5 {

    width: 50px;
}

.w-\[300px\] {

    width: 300px;
}

.w-fit {

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
}

.w-full {

    width: 100%;
}

.w-screen {

    width: 100vw;
}

.min-w-\[24px\] {

    min-width: 24px;
}

.max-w-3\/4 {

    max-width: 75%;
}

.max-w-\[800px\] {

    max-width: 800px;
}

.flex-auto {

    flex: 1 1 auto;
}

.flex-grow {

    flex-grow: 1;
}

@keyframes spin {

    to {

        transform: rotate(360deg);
    }
}

.animate-spin {

    animation: spin 1s linear infinite;
}

.cursor-not-allowed {

    cursor: not-allowed;
}

.cursor-pointer {

    cursor: pointer;
}

.resize {

    resize: both;
}

.list-disc {

    list-style-type: disc;
}

.flex-row {

    flex-direction: row;
}

.flex-col {

    flex-direction: column;
}

.flex-wrap {

    flex-wrap: wrap;
}

.items-center {

    align-items: center;
}

.items-stretch {

    align-items: stretch;
}

.justify-start {

    justify-content: flex-start;
}

.justify-end {

    justify-content: flex-end;
}

.justify-center {

    justify-content: center;
}

.justify-between {

    justify-content: space-between;
}

.justify-around {

    justify-content: space-around;
}

.justify-evenly {

    justify-content: space-evenly;
}

.gap-\[16px\] {

    gap: 16px;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(15px * var(--tw-space-x-reverse));

    margin-left: calc(15px * calc(1 - var(--tw-space-x-reverse)));
}

.self-end {

    align-self: flex-end;
}

.overflow-x-auto {

    overflow-x: auto;
}

.overflow-y-auto {

    overflow-y: auto;
}

.overflow-y-hidden {

    overflow-y: hidden;
}

.text-ellipsis {

    text-overflow: ellipsis;
}

.text-clip {

    text-overflow: clip;
}

.whitespace-pre-line {

    white-space: pre-line;
}

.whitespace-pre-wrap {

    white-space: pre-wrap;
}

.text-wrap {

    text-wrap: wrap;
}

.text-nowrap {

    text-wrap: nowrap;
}

.text-balance {

    text-wrap: balance;
}

.text-pretty {

    text-wrap: pretty;
}

.rounded-full {

    border-radius: 9999px;
}

.rounded-none {

    border-radius: 0px;
}

.border {

    border-width: 1px;
}

.border-0 {

    border-width: 0px;
}

.border-2 {

    border-width: 2px;
}

.border-l-2 {

    border-left-width: 2px;
}

.border-l-4 {

    border-left-width: 4px;
}

.border-solid {

    border-style: solid;
}

.border-blue {

    --tw-border-opacity: 1;

    border-color: rgb(111 233 255 / var(--tw-border-opacity));
}

.border-blue-dark {

    --tw-border-opacity: 1;

    border-color: rgb(42 40 89 / var(--tw-border-opacity));
}

.border-green {

    --tw-border-opacity: 1;

    border-color: rgb(67 248 182 / var(--tw-border-opacity));
}

.border-grey-dark {

    --tw-border-opacity: 1;

    border-color: rgb(225 225 225 / var(--tw-border-opacity));
}

.border-red {

    --tw-border-opacity: 1;

    border-color: rgb(255 130 116 / var(--tw-border-opacity));
}

.border-state-danger {

    border-color: #FF8274B3;
}

.border-state-success {

    border-color: #C7F6C9B3;
}

.border-state-warning {

    border-color: #F9C66BB3;
}

.border-white {

    --tw-border-opacity: 1;

    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow {

    --tw-border-opacity: 1;

    border-color: rgb(249 198 107 / var(--tw-border-opacity));
}

.bg-beige {

    --tw-bg-opacity: 1;

    background-color: rgb(248 240 221 / var(--tw-bg-opacity));
}

.bg-beige-dark {

    --tw-bg-opacity: 1;

    background-color: rgb(208 191 174 / var(--tw-bg-opacity));
}

.bg-beige-dark\/0 {

    background-color: rgb(208 191 174 / 0);
}

.bg-beige-dark\/10 {

    background-color: rgb(208 191 174 / 0.1);
}

.bg-beige-dark\/100 {

    background-color: rgb(208 191 174 / 1);
}

.bg-beige-dark\/15 {

    background-color: rgb(208 191 174 / 0.15);
}

.bg-beige-dark\/20 {

    background-color: rgb(208 191 174 / 0.2);
}

.bg-beige-dark\/25 {

    background-color: rgb(208 191 174 / 0.25);
}

.bg-beige-dark\/30 {

    background-color: rgb(208 191 174 / 0.3);
}

.bg-beige-dark\/35 {

    background-color: rgb(208 191 174 / 0.35);
}

.bg-beige-dark\/40 {

    background-color: rgb(208 191 174 / 0.4);
}

.bg-beige-dark\/45 {

    background-color: rgb(208 191 174 / 0.45);
}

.bg-beige-dark\/5 {

    background-color: rgb(208 191 174 / 0.05);
}

.bg-beige-dark\/50 {

    background-color: rgb(208 191 174 / 0.5);
}

.bg-beige-dark\/55 {

    background-color: rgb(208 191 174 / 0.55);
}

.bg-beige-dark\/60 {

    background-color: rgb(208 191 174 / 0.6);
}

.bg-beige-dark\/65 {

    background-color: rgb(208 191 174 / 0.65);
}

.bg-beige-dark\/70 {

    background-color: rgb(208 191 174 / 0.7);
}

.bg-beige-dark\/75 {

    background-color: rgb(208 191 174 / 0.75);
}

.bg-beige-dark\/80 {

    background-color: rgb(208 191 174 / 0.8);
}

.bg-beige-dark\/85 {

    background-color: rgb(208 191 174 / 0.85);
}

.bg-beige-dark\/90 {

    background-color: rgb(208 191 174 / 0.9);
}

.bg-beige-dark\/95 {

    background-color: rgb(208 191 174 / 0.95);
}

.bg-beige\/0 {

    background-color: rgb(248 240 221 / 0);
}

.bg-beige\/10 {

    background-color: rgb(248 240 221 / 0.1);
}

.bg-beige\/100 {

    background-color: rgb(248 240 221 / 1);
}

.bg-beige\/15 {

    background-color: rgb(248 240 221 / 0.15);
}

.bg-beige\/20 {

    background-color: rgb(248 240 221 / 0.2);
}

.bg-beige\/25 {

    background-color: rgb(248 240 221 / 0.25);
}

.bg-beige\/30 {

    background-color: rgb(248 240 221 / 0.3);
}

.bg-beige\/35 {

    background-color: rgb(248 240 221 / 0.35);
}

.bg-beige\/40 {

    background-color: rgb(248 240 221 / 0.4);
}

.bg-beige\/45 {

    background-color: rgb(248 240 221 / 0.45);
}

.bg-beige\/5 {

    background-color: rgb(248 240 221 / 0.05);
}

.bg-beige\/50 {

    background-color: rgb(248 240 221 / 0.5);
}

.bg-beige\/55 {

    background-color: rgb(248 240 221 / 0.55);
}

.bg-beige\/60 {

    background-color: rgb(248 240 221 / 0.6);
}

.bg-beige\/65 {

    background-color: rgb(248 240 221 / 0.65);
}

.bg-beige\/70 {

    background-color: rgb(248 240 221 / 0.7);
}

.bg-beige\/75 {

    background-color: rgb(248 240 221 / 0.75);
}

.bg-beige\/80 {

    background-color: rgb(248 240 221 / 0.8);
}

.bg-beige\/85 {

    background-color: rgb(248 240 221 / 0.85);
}

.bg-beige\/90 {

    background-color: rgb(248 240 221 / 0.9);
}

.bg-beige\/95 {

    background-color: rgb(248 240 221 / 0.95);
}

.bg-black {

    --tw-bg-opacity: 1;

    background-color: rgb(44 44 44 / var(--tw-bg-opacity));
}

.bg-black-backdrop {

    background-color: #2c2c2c99;
}

.bg-black-backdrop\/0 {

    background-color: rgb(44 44 44 / 0);
}

.bg-black-backdrop\/10 {

    background-color: rgb(44 44 44 / 0.1);
}

.bg-black-backdrop\/100 {

    background-color: rgb(44 44 44 / 1);
}

.bg-black-backdrop\/15 {

    background-color: rgb(44 44 44 / 0.15);
}

.bg-black-backdrop\/20 {

    background-color: rgb(44 44 44 / 0.2);
}

.bg-black-backdrop\/25 {

    background-color: rgb(44 44 44 / 0.25);
}

.bg-black-backdrop\/30 {

    background-color: rgb(44 44 44 / 0.3);
}

.bg-black-backdrop\/35 {

    background-color: rgb(44 44 44 / 0.35);
}

.bg-black-backdrop\/40 {

    background-color: rgb(44 44 44 / 0.4);
}

.bg-black-backdrop\/45 {

    background-color: rgb(44 44 44 / 0.45);
}

.bg-black-backdrop\/5 {

    background-color: rgb(44 44 44 / 0.05);
}

.bg-black-backdrop\/50 {

    background-color: rgb(44 44 44 / 0.5);
}

.bg-black-backdrop\/55 {

    background-color: rgb(44 44 44 / 0.55);
}

.bg-black-backdrop\/60 {

    background-color: rgb(44 44 44 / 0.6);
}

.bg-black-backdrop\/65 {

    background-color: rgb(44 44 44 / 0.65);
}

.bg-black-backdrop\/70 {

    background-color: rgb(44 44 44 / 0.7);
}

.bg-black-backdrop\/75 {

    background-color: rgb(44 44 44 / 0.75);
}

.bg-black-backdrop\/80 {

    background-color: rgb(44 44 44 / 0.8);
}

.bg-black-backdrop\/85 {

    background-color: rgb(44 44 44 / 0.85);
}

.bg-black-backdrop\/90 {

    background-color: rgb(44 44 44 / 0.9);
}

.bg-black-backdrop\/95 {

    background-color: rgb(44 44 44 / 0.95);
}

.bg-black\/0 {

    background-color: rgb(44 44 44 / 0);
}

.bg-black\/10 {

    background-color: rgb(44 44 44 / 0.1);
}

.bg-black\/100 {

    background-color: rgb(44 44 44 / 1);
}

.bg-black\/15 {

    background-color: rgb(44 44 44 / 0.15);
}

.bg-black\/20 {

    background-color: rgb(44 44 44 / 0.2);
}

.bg-black\/25 {

    background-color: rgb(44 44 44 / 0.25);
}

.bg-black\/30 {

    background-color: rgb(44 44 44 / 0.3);
}

.bg-black\/35 {

    background-color: rgb(44 44 44 / 0.35);
}

.bg-black\/40 {

    background-color: rgb(44 44 44 / 0.4);
}

.bg-black\/45 {

    background-color: rgb(44 44 44 / 0.45);
}

.bg-black\/5 {

    background-color: rgb(44 44 44 / 0.05);
}

.bg-black\/50 {

    background-color: rgb(44 44 44 / 0.5);
}

.bg-black\/55 {

    background-color: rgb(44 44 44 / 0.55);
}

.bg-black\/60 {

    background-color: rgb(44 44 44 / 0.6);
}

.bg-black\/65 {

    background-color: rgb(44 44 44 / 0.65);
}

.bg-black\/70 {

    background-color: rgb(44 44 44 / 0.7);
}

.bg-black\/75 {

    background-color: rgb(44 44 44 / 0.75);
}

.bg-black\/80 {

    background-color: rgb(44 44 44 / 0.8);
}

.bg-black\/85 {

    background-color: rgb(44 44 44 / 0.85);
}

.bg-black\/90 {

    background-color: rgb(44 44 44 / 0.9);
}

.bg-black\/95 {

    background-color: rgb(44 44 44 / 0.95);
}

.bg-blue {

    --tw-bg-opacity: 1;

    background-color: rgb(111 233 255 / var(--tw-bg-opacity));
}

.bg-blue-60 {

    --tw-bg-opacity: 1;

    background-color: rgb(209 249 255 / var(--tw-bg-opacity));
}

.bg-blue-60\/0 {

    background-color: rgb(209 249 255 / 0);
}

.bg-blue-60\/10 {

    background-color: rgb(209 249 255 / 0.1);
}

.bg-blue-60\/100 {

    background-color: rgb(209 249 255 / 1);
}

.bg-blue-60\/15 {

    background-color: rgb(209 249 255 / 0.15);
}

.bg-blue-60\/20 {

    background-color: rgb(209 249 255 / 0.2);
}

.bg-blue-60\/25 {

    background-color: rgb(209 249 255 / 0.25);
}

.bg-blue-60\/30 {

    background-color: rgb(209 249 255 / 0.3);
}

.bg-blue-60\/35 {

    background-color: rgb(209 249 255 / 0.35);
}

.bg-blue-60\/40 {

    background-color: rgb(209 249 255 / 0.4);
}

.bg-blue-60\/45 {

    background-color: rgb(209 249 255 / 0.45);
}

.bg-blue-60\/5 {

    background-color: rgb(209 249 255 / 0.05);
}

.bg-blue-60\/50 {

    background-color: rgb(209 249 255 / 0.5);
}

.bg-blue-60\/55 {

    background-color: rgb(209 249 255 / 0.55);
}

.bg-blue-60\/60 {

    background-color: rgb(209 249 255 / 0.6);
}

.bg-blue-60\/65 {

    background-color: rgb(209 249 255 / 0.65);
}

.bg-blue-60\/70 {

    background-color: rgb(209 249 255 / 0.7);
}

.bg-blue-60\/75 {

    background-color: rgb(209 249 255 / 0.75);
}

.bg-blue-60\/80 {

    background-color: rgb(209 249 255 / 0.8);
}

.bg-blue-60\/85 {

    background-color: rgb(209 249 255 / 0.85);
}

.bg-blue-60\/90 {

    background-color: rgb(209 249 255 / 0.9);
}

.bg-blue-60\/95 {

    background-color: rgb(209 249 255 / 0.95);
}

.bg-blue-dark {

    --tw-bg-opacity: 1;

    background-color: rgb(42 40 89 / var(--tw-bg-opacity));
}

.bg-blue-dark\/0 {

    background-color: rgb(42 40 89 / 0);
}

.bg-blue-dark\/10 {

    background-color: rgb(42 40 89 / 0.1);
}

.bg-blue-dark\/100 {

    background-color: rgb(42 40 89 / 1);
}

.bg-blue-dark\/15 {

    background-color: rgb(42 40 89 / 0.15);
}

.bg-blue-dark\/20 {

    background-color: rgb(42 40 89 / 0.2);
}

.bg-blue-dark\/25 {

    background-color: rgb(42 40 89 / 0.25);
}

.bg-blue-dark\/30 {

    background-color: rgb(42 40 89 / 0.3);
}

.bg-blue-dark\/35 {

    background-color: rgb(42 40 89 / 0.35);
}

.bg-blue-dark\/40 {

    background-color: rgb(42 40 89 / 0.4);
}

.bg-blue-dark\/45 {

    background-color: rgb(42 40 89 / 0.45);
}

.bg-blue-dark\/5 {

    background-color: rgb(42 40 89 / 0.05);
}

.bg-blue-dark\/50 {

    background-color: rgb(42 40 89 / 0.5);
}

.bg-blue-dark\/55 {

    background-color: rgb(42 40 89 / 0.55);
}

.bg-blue-dark\/60 {

    background-color: rgb(42 40 89 / 0.6);
}

.bg-blue-dark\/65 {

    background-color: rgb(42 40 89 / 0.65);
}

.bg-blue-dark\/70 {

    background-color: rgb(42 40 89 / 0.7);
}

.bg-blue-dark\/75 {

    background-color: rgb(42 40 89 / 0.75);
}

.bg-blue-dark\/80 {

    background-color: rgb(42 40 89 / 0.8);
}

.bg-blue-dark\/85 {

    background-color: rgb(42 40 89 / 0.85);
}

.bg-blue-dark\/90 {

    background-color: rgb(42 40 89 / 0.9);
}

.bg-blue-dark\/95 {

    background-color: rgb(42 40 89 / 0.95);
}

.bg-blue-focus {

    --tw-bg-opacity: 1;

    background-color: rgb(31 66 170 / var(--tw-bg-opacity));
}

.bg-blue-focus\/0 {

    background-color: rgb(31 66 170 / 0);
}

.bg-blue-focus\/10 {

    background-color: rgb(31 66 170 / 0.1);
}

.bg-blue-focus\/100 {

    background-color: rgb(31 66 170 / 1);
}

.bg-blue-focus\/15 {

    background-color: rgb(31 66 170 / 0.15);
}

.bg-blue-focus\/20 {

    background-color: rgb(31 66 170 / 0.2);
}

.bg-blue-focus\/25 {

    background-color: rgb(31 66 170 / 0.25);
}

.bg-blue-focus\/30 {

    background-color: rgb(31 66 170 / 0.3);
}

.bg-blue-focus\/35 {

    background-color: rgb(31 66 170 / 0.35);
}

.bg-blue-focus\/40 {

    background-color: rgb(31 66 170 / 0.4);
}

.bg-blue-focus\/45 {

    background-color: rgb(31 66 170 / 0.45);
}

.bg-blue-focus\/5 {

    background-color: rgb(31 66 170 / 0.05);
}

.bg-blue-focus\/50 {

    background-color: rgb(31 66 170 / 0.5);
}

.bg-blue-focus\/55 {

    background-color: rgb(31 66 170 / 0.55);
}

.bg-blue-focus\/60 {

    background-color: rgb(31 66 170 / 0.6);
}

.bg-blue-focus\/65 {

    background-color: rgb(31 66 170 / 0.65);
}

.bg-blue-focus\/70 {

    background-color: rgb(31 66 170 / 0.7);
}

.bg-blue-focus\/75 {

    background-color: rgb(31 66 170 / 0.75);
}

.bg-blue-focus\/80 {

    background-color: rgb(31 66 170 / 0.8);
}

.bg-blue-focus\/85 {

    background-color: rgb(31 66 170 / 0.85);
}

.bg-blue-focus\/90 {

    background-color: rgb(31 66 170 / 0.9);
}

.bg-blue-focus\/95 {

    background-color: rgb(31 66 170 / 0.95);
}

.bg-blue-hover {

    --tw-bg-opacity: 1;

    background-color: rgb(31 66 170 / var(--tw-bg-opacity));
}

.bg-blue-hover\/0 {

    background-color: rgb(31 66 170 / 0);
}

.bg-blue-hover\/10 {

    background-color: rgb(31 66 170 / 0.1);
}

.bg-blue-hover\/100 {

    background-color: rgb(31 66 170 / 1);
}

.bg-blue-hover\/15 {

    background-color: rgb(31 66 170 / 0.15);
}

.bg-blue-hover\/20 {

    background-color: rgb(31 66 170 / 0.2);
}

.bg-blue-hover\/25 {

    background-color: rgb(31 66 170 / 0.25);
}

.bg-blue-hover\/30 {

    background-color: rgb(31 66 170 / 0.3);
}

.bg-blue-hover\/35 {

    background-color: rgb(31 66 170 / 0.35);
}

.bg-blue-hover\/40 {

    background-color: rgb(31 66 170 / 0.4);
}

.bg-blue-hover\/45 {

    background-color: rgb(31 66 170 / 0.45);
}

.bg-blue-hover\/5 {

    background-color: rgb(31 66 170 / 0.05);
}

.bg-blue-hover\/50 {

    background-color: rgb(31 66 170 / 0.5);
}

.bg-blue-hover\/55 {

    background-color: rgb(31 66 170 / 0.55);
}

.bg-blue-hover\/60 {

    background-color: rgb(31 66 170 / 0.6);
}

.bg-blue-hover\/65 {

    background-color: rgb(31 66 170 / 0.65);
}

.bg-blue-hover\/70 {

    background-color: rgb(31 66 170 / 0.7);
}

.bg-blue-hover\/75 {

    background-color: rgb(31 66 170 / 0.75);
}

.bg-blue-hover\/80 {

    background-color: rgb(31 66 170 / 0.8);
}

.bg-blue-hover\/85 {

    background-color: rgb(31 66 170 / 0.85);
}

.bg-blue-hover\/90 {

    background-color: rgb(31 66 170 / 0.9);
}

.bg-blue-hover\/95 {

    background-color: rgb(31 66 170 / 0.95);
}

.bg-blue-light {

    --tw-bg-opacity: 1;

    background-color: rgb(179 245 255 / var(--tw-bg-opacity));
}

.bg-blue-light\/0 {

    background-color: rgb(179 245 255 / 0);
}

.bg-blue-light\/10 {

    background-color: rgb(179 245 255 / 0.1);
}

.bg-blue-light\/100 {

    background-color: rgb(179 245 255 / 1);
}

.bg-blue-light\/15 {

    background-color: rgb(179 245 255 / 0.15);
}

.bg-blue-light\/20 {

    background-color: rgb(179 245 255 / 0.2);
}

.bg-blue-light\/25 {

    background-color: rgb(179 245 255 / 0.25);
}

.bg-blue-light\/30 {

    background-color: rgb(179 245 255 / 0.3);
}

.bg-blue-light\/35 {

    background-color: rgb(179 245 255 / 0.35);
}

.bg-blue-light\/40 {

    background-color: rgb(179 245 255 / 0.4);
}

.bg-blue-light\/45 {

    background-color: rgb(179 245 255 / 0.45);
}

.bg-blue-light\/5 {

    background-color: rgb(179 245 255 / 0.05);
}

.bg-blue-light\/50 {

    background-color: rgb(179 245 255 / 0.5);
}

.bg-blue-light\/55 {

    background-color: rgb(179 245 255 / 0.55);
}

.bg-blue-light\/60 {

    background-color: rgb(179 245 255 / 0.6);
}

.bg-blue-light\/65 {

    background-color: rgb(179 245 255 / 0.65);
}

.bg-blue-light\/70 {

    background-color: rgb(179 245 255 / 0.7);
}

.bg-blue-light\/75 {

    background-color: rgb(179 245 255 / 0.75);
}

.bg-blue-light\/80 {

    background-color: rgb(179 245 255 / 0.8);
}

.bg-blue-light\/85 {

    background-color: rgb(179 245 255 / 0.85);
}

.bg-blue-light\/90 {

    background-color: rgb(179 245 255 / 0.9);
}

.bg-blue-light\/95 {

    background-color: rgb(179 245 255 / 0.95);
}

.bg-blue\/0 {

    background-color: rgb(111 233 255 / 0);
}

.bg-blue\/10 {

    background-color: rgb(111 233 255 / 0.1);
}

.bg-blue\/100 {

    background-color: rgb(111 233 255 / 1);
}

.bg-blue\/15 {

    background-color: rgb(111 233 255 / 0.15);
}

.bg-blue\/20 {

    background-color: rgb(111 233 255 / 0.2);
}

.bg-blue\/25 {

    background-color: rgb(111 233 255 / 0.25);
}

.bg-blue\/30 {

    background-color: rgb(111 233 255 / 0.3);
}

.bg-blue\/35 {

    background-color: rgb(111 233 255 / 0.35);
}

.bg-blue\/40 {

    background-color: rgb(111 233 255 / 0.4);
}

.bg-blue\/45 {

    background-color: rgb(111 233 255 / 0.45);
}

.bg-blue\/5 {

    background-color: rgb(111 233 255 / 0.05);
}

.bg-blue\/50 {

    background-color: rgb(111 233 255 / 0.5);
}

.bg-blue\/55 {

    background-color: rgb(111 233 255 / 0.55);
}

.bg-blue\/60 {

    background-color: rgb(111 233 255 / 0.6);
}

.bg-blue\/65 {

    background-color: rgb(111 233 255 / 0.65);
}

.bg-blue\/70 {

    background-color: rgb(111 233 255 / 0.7);
}

.bg-blue\/75 {

    background-color: rgb(111 233 255 / 0.75);
}

.bg-blue\/80 {

    background-color: rgb(111 233 255 / 0.8);
}

.bg-blue\/85 {

    background-color: rgb(111 233 255 / 0.85);
}

.bg-blue\/90 {

    background-color: rgb(111 233 255 / 0.9);
}

.bg-blue\/95 {

    background-color: rgb(111 233 255 / 0.95);
}

.bg-green {

    --tw-bg-opacity: 1;

    background-color: rgb(67 248 182 / var(--tw-bg-opacity));
}

.bg-green-30 {

    --tw-bg-opacity: 1;

    background-color: rgb(199 253 233 / var(--tw-bg-opacity));
}

.bg-green-30\/0 {

    background-color: rgb(199 253 233 / 0);
}

.bg-green-30\/10 {

    background-color: rgb(199 253 233 / 0.1);
}

.bg-green-30\/100 {

    background-color: rgb(199 253 233 / 1);
}

.bg-green-30\/15 {

    background-color: rgb(199 253 233 / 0.15);
}

.bg-green-30\/20 {

    background-color: rgb(199 253 233 / 0.2);
}

.bg-green-30\/25 {

    background-color: rgb(199 253 233 / 0.25);
}

.bg-green-30\/30 {

    background-color: rgb(199 253 233 / 0.3);
}

.bg-green-30\/35 {

    background-color: rgb(199 253 233 / 0.35);
}

.bg-green-30\/40 {

    background-color: rgb(199 253 233 / 0.4);
}

.bg-green-30\/45 {

    background-color: rgb(199 253 233 / 0.45);
}

.bg-green-30\/5 {

    background-color: rgb(199 253 233 / 0.05);
}

.bg-green-30\/50 {

    background-color: rgb(199 253 233 / 0.5);
}

.bg-green-30\/55 {

    background-color: rgb(199 253 233 / 0.55);
}

.bg-green-30\/60 {

    background-color: rgb(199 253 233 / 0.6);
}

.bg-green-30\/65 {

    background-color: rgb(199 253 233 / 0.65);
}

.bg-green-30\/70 {

    background-color: rgb(199 253 233 / 0.7);
}

.bg-green-30\/75 {

    background-color: rgb(199 253 233 / 0.75);
}

.bg-green-30\/80 {

    background-color: rgb(199 253 233 / 0.8);
}

.bg-green-30\/85 {

    background-color: rgb(199 253 233 / 0.85);
}

.bg-green-30\/90 {

    background-color: rgb(199 253 233 / 0.9);
}

.bg-green-30\/95 {

    background-color: rgb(199 253 233 / 0.95);
}

.bg-green-dark {

    --tw-bg-opacity: 1;

    background-color: rgb(3 75 69 / var(--tw-bg-opacity));
}

.bg-green-dark\/0 {

    background-color: rgb(3 75 69 / 0);
}

.bg-green-dark\/10 {

    background-color: rgb(3 75 69 / 0.1);
}

.bg-green-dark\/100 {

    background-color: rgb(3 75 69 / 1);
}

.bg-green-dark\/15 {

    background-color: rgb(3 75 69 / 0.15);
}

.bg-green-dark\/20 {

    background-color: rgb(3 75 69 / 0.2);
}

.bg-green-dark\/25 {

    background-color: rgb(3 75 69 / 0.25);
}

.bg-green-dark\/30 {

    background-color: rgb(3 75 69 / 0.3);
}

.bg-green-dark\/35 {

    background-color: rgb(3 75 69 / 0.35);
}

.bg-green-dark\/40 {

    background-color: rgb(3 75 69 / 0.4);
}

.bg-green-dark\/45 {

    background-color: rgb(3 75 69 / 0.45);
}

.bg-green-dark\/5 {

    background-color: rgb(3 75 69 / 0.05);
}

.bg-green-dark\/50 {

    background-color: rgb(3 75 69 / 0.5);
}

.bg-green-dark\/55 {

    background-color: rgb(3 75 69 / 0.55);
}

.bg-green-dark\/60 {

    background-color: rgb(3 75 69 / 0.6);
}

.bg-green-dark\/65 {

    background-color: rgb(3 75 69 / 0.65);
}

.bg-green-dark\/70 {

    background-color: rgb(3 75 69 / 0.7);
}

.bg-green-dark\/75 {

    background-color: rgb(3 75 69 / 0.75);
}

.bg-green-dark\/80 {

    background-color: rgb(3 75 69 / 0.8);
}

.bg-green-dark\/85 {

    background-color: rgb(3 75 69 / 0.85);
}

.bg-green-dark\/90 {

    background-color: rgb(3 75 69 / 0.9);
}

.bg-green-dark\/95 {

    background-color: rgb(3 75 69 / 0.95);
}

.bg-green-focus {

    --tw-bg-opacity: 1;

    background-color: rgb(31 66 170 / var(--tw-bg-opacity));
}

.bg-green-focus\/0 {

    background-color: rgb(31 66 170 / 0);
}

.bg-green-focus\/10 {

    background-color: rgb(31 66 170 / 0.1);
}

.bg-green-focus\/100 {

    background-color: rgb(31 66 170 / 1);
}

.bg-green-focus\/15 {

    background-color: rgb(31 66 170 / 0.15);
}

.bg-green-focus\/20 {

    background-color: rgb(31 66 170 / 0.2);
}

.bg-green-focus\/25 {

    background-color: rgb(31 66 170 / 0.25);
}

.bg-green-focus\/30 {

    background-color: rgb(31 66 170 / 0.3);
}

.bg-green-focus\/35 {

    background-color: rgb(31 66 170 / 0.35);
}

.bg-green-focus\/40 {

    background-color: rgb(31 66 170 / 0.4);
}

.bg-green-focus\/45 {

    background-color: rgb(31 66 170 / 0.45);
}

.bg-green-focus\/5 {

    background-color: rgb(31 66 170 / 0.05);
}

.bg-green-focus\/50 {

    background-color: rgb(31 66 170 / 0.5);
}

.bg-green-focus\/55 {

    background-color: rgb(31 66 170 / 0.55);
}

.bg-green-focus\/60 {

    background-color: rgb(31 66 170 / 0.6);
}

.bg-green-focus\/65 {

    background-color: rgb(31 66 170 / 0.65);
}

.bg-green-focus\/70 {

    background-color: rgb(31 66 170 / 0.7);
}

.bg-green-focus\/75 {

    background-color: rgb(31 66 170 / 0.75);
}

.bg-green-focus\/80 {

    background-color: rgb(31 66 170 / 0.8);
}

.bg-green-focus\/85 {

    background-color: rgb(31 66 170 / 0.85);
}

.bg-green-focus\/90 {

    background-color: rgb(31 66 170 / 0.9);
}

.bg-green-focus\/95 {

    background-color: rgb(31 66 170 / 0.95);
}

.bg-green-hover {

    --tw-bg-opacity: 1;

    background-color: rgb(31 66 170 / var(--tw-bg-opacity));
}

.bg-green-hover\/0 {

    background-color: rgb(31 66 170 / 0);
}

.bg-green-hover\/10 {

    background-color: rgb(31 66 170 / 0.1);
}

.bg-green-hover\/100 {

    background-color: rgb(31 66 170 / 1);
}

.bg-green-hover\/15 {

    background-color: rgb(31 66 170 / 0.15);
}

.bg-green-hover\/20 {

    background-color: rgb(31 66 170 / 0.2);
}

.bg-green-hover\/25 {

    background-color: rgb(31 66 170 / 0.25);
}

.bg-green-hover\/30 {

    background-color: rgb(31 66 170 / 0.3);
}

.bg-green-hover\/35 {

    background-color: rgb(31 66 170 / 0.35);
}

.bg-green-hover\/40 {

    background-color: rgb(31 66 170 / 0.4);
}

.bg-green-hover\/45 {

    background-color: rgb(31 66 170 / 0.45);
}

.bg-green-hover\/5 {

    background-color: rgb(31 66 170 / 0.05);
}

.bg-green-hover\/50 {

    background-color: rgb(31 66 170 / 0.5);
}

.bg-green-hover\/55 {

    background-color: rgb(31 66 170 / 0.55);
}

.bg-green-hover\/60 {

    background-color: rgb(31 66 170 / 0.6);
}

.bg-green-hover\/65 {

    background-color: rgb(31 66 170 / 0.65);
}

.bg-green-hover\/70 {

    background-color: rgb(31 66 170 / 0.7);
}

.bg-green-hover\/75 {

    background-color: rgb(31 66 170 / 0.75);
}

.bg-green-hover\/80 {

    background-color: rgb(31 66 170 / 0.8);
}

.bg-green-hover\/85 {

    background-color: rgb(31 66 170 / 0.85);
}

.bg-green-hover\/90 {

    background-color: rgb(31 66 170 / 0.9);
}

.bg-green-hover\/95 {

    background-color: rgb(31 66 170 / 0.95);
}

.bg-green-light {

    --tw-bg-opacity: 1;

    background-color: rgb(199 246 201 / var(--tw-bg-opacity));
}

.bg-green-light\/0 {

    background-color: rgb(199 246 201 / 0);
}

.bg-green-light\/10 {

    background-color: rgb(199 246 201 / 0.1);
}

.bg-green-light\/100 {

    background-color: rgb(199 246 201 / 1);
}

.bg-green-light\/15 {

    background-color: rgb(199 246 201 / 0.15);
}

.bg-green-light\/20 {

    background-color: rgb(199 246 201 / 0.2);
}

.bg-green-light\/25 {

    background-color: rgb(199 246 201 / 0.25);
}

.bg-green-light\/30 {

    background-color: rgb(199 246 201 / 0.3);
}

.bg-green-light\/35 {

    background-color: rgb(199 246 201 / 0.35);
}

.bg-green-light\/40 {

    background-color: rgb(199 246 201 / 0.4);
}

.bg-green-light\/45 {

    background-color: rgb(199 246 201 / 0.45);
}

.bg-green-light\/5 {

    background-color: rgb(199 246 201 / 0.05);
}

.bg-green-light\/50 {

    background-color: rgb(199 246 201 / 0.5);
}

.bg-green-light\/55 {

    background-color: rgb(199 246 201 / 0.55);
}

.bg-green-light\/60 {

    background-color: rgb(199 246 201 / 0.6);
}

.bg-green-light\/65 {

    background-color: rgb(199 246 201 / 0.65);
}

.bg-green-light\/70 {

    background-color: rgb(199 246 201 / 0.7);
}

.bg-green-light\/75 {

    background-color: rgb(199 246 201 / 0.75);
}

.bg-green-light\/80 {

    background-color: rgb(199 246 201 / 0.8);
}

.bg-green-light\/85 {

    background-color: rgb(199 246 201 / 0.85);
}

.bg-green-light\/90 {

    background-color: rgb(199 246 201 / 0.9);
}

.bg-green-light\/95 {

    background-color: rgb(199 246 201 / 0.95);
}

.bg-green\/0 {

    background-color: rgb(67 248 182 / 0);
}

.bg-green\/10 {

    background-color: rgb(67 248 182 / 0.1);
}

.bg-green\/100 {

    background-color: rgb(67 248 182 / 1);
}

.bg-green\/15 {

    background-color: rgb(67 248 182 / 0.15);
}

.bg-green\/20 {

    background-color: rgb(67 248 182 / 0.2);
}

.bg-green\/25 {

    background-color: rgb(67 248 182 / 0.25);
}

.bg-green\/30 {

    background-color: rgb(67 248 182 / 0.3);
}

.bg-green\/35 {

    background-color: rgb(67 248 182 / 0.35);
}

.bg-green\/40 {

    background-color: rgb(67 248 182 / 0.4);
}

.bg-green\/45 {

    background-color: rgb(67 248 182 / 0.45);
}

.bg-green\/5 {

    background-color: rgb(67 248 182 / 0.05);
}

.bg-green\/50 {

    background-color: rgb(67 248 182 / 0.5);
}

.bg-green\/55 {

    background-color: rgb(67 248 182 / 0.55);
}

.bg-green\/60 {

    background-color: rgb(67 248 182 / 0.6);
}

.bg-green\/65 {

    background-color: rgb(67 248 182 / 0.65);
}

.bg-green\/70 {

    background-color: rgb(67 248 182 / 0.7);
}

.bg-green\/75 {

    background-color: rgb(67 248 182 / 0.75);
}

.bg-green\/80 {

    background-color: rgb(67 248 182 / 0.8);
}

.bg-green\/85 {

    background-color: rgb(67 248 182 / 0.85);
}

.bg-green\/90 {

    background-color: rgb(67 248 182 / 0.9);
}

.bg-green\/95 {

    background-color: rgb(67 248 182 / 0.95);
}

.bg-grey {

    --tw-bg-opacity: 1;

    background-color: rgb(242 242 242 / var(--tw-bg-opacity));
}

.bg-grey-10 {

    --tw-bg-opacity: 1;

    background-color: rgb(230 230 230 / var(--tw-bg-opacity));
}

.bg-grey-10\/0 {

    background-color: rgb(230 230 230 / 0);
}

.bg-grey-10\/10 {

    background-color: rgb(230 230 230 / 0.1);
}

.bg-grey-10\/100 {

    background-color: rgb(230 230 230 / 1);
}

.bg-grey-10\/15 {

    background-color: rgb(230 230 230 / 0.15);
}

.bg-grey-10\/20 {

    background-color: rgb(230 230 230 / 0.2);
}

.bg-grey-10\/25 {

    background-color: rgb(230 230 230 / 0.25);
}

.bg-grey-10\/30 {

    background-color: rgb(230 230 230 / 0.3);
}

.bg-grey-10\/35 {

    background-color: rgb(230 230 230 / 0.35);
}

.bg-grey-10\/40 {

    background-color: rgb(230 230 230 / 0.4);
}

.bg-grey-10\/45 {

    background-color: rgb(230 230 230 / 0.45);
}

.bg-grey-10\/5 {

    background-color: rgb(230 230 230 / 0.05);
}

.bg-grey-10\/50 {

    background-color: rgb(230 230 230 / 0.5);
}

.bg-grey-10\/55 {

    background-color: rgb(230 230 230 / 0.55);
}

.bg-grey-10\/60 {

    background-color: rgb(230 230 230 / 0.6);
}

.bg-grey-10\/65 {

    background-color: rgb(230 230 230 / 0.65);
}

.bg-grey-10\/70 {

    background-color: rgb(230 230 230 / 0.7);
}

.bg-grey-10\/75 {

    background-color: rgb(230 230 230 / 0.75);
}

.bg-grey-10\/80 {

    background-color: rgb(230 230 230 / 0.8);
}

.bg-grey-10\/85 {

    background-color: rgb(230 230 230 / 0.85);
}

.bg-grey-10\/90 {

    background-color: rgb(230 230 230 / 0.9);
}

.bg-grey-10\/95 {

    background-color: rgb(230 230 230 / 0.95);
}

.bg-grey-20 {

    --tw-bg-opacity: 1;

    background-color: rgb(204 204 204 / var(--tw-bg-opacity));
}

.bg-grey-20\/0 {

    background-color: rgb(204 204 204 / 0);
}

.bg-grey-20\/10 {

    background-color: rgb(204 204 204 / 0.1);
}

.bg-grey-20\/100 {

    background-color: rgb(204 204 204 / 1);
}

.bg-grey-20\/15 {

    background-color: rgb(204 204 204 / 0.15);
}

.bg-grey-20\/20 {

    background-color: rgb(204 204 204 / 0.2);
}

.bg-grey-20\/25 {

    background-color: rgb(204 204 204 / 0.25);
}

.bg-grey-20\/30 {

    background-color: rgb(204 204 204 / 0.3);
}

.bg-grey-20\/35 {

    background-color: rgb(204 204 204 / 0.35);
}

.bg-grey-20\/40 {

    background-color: rgb(204 204 204 / 0.4);
}

.bg-grey-20\/45 {

    background-color: rgb(204 204 204 / 0.45);
}

.bg-grey-20\/5 {

    background-color: rgb(204 204 204 / 0.05);
}

.bg-grey-20\/50 {

    background-color: rgb(204 204 204 / 0.5);
}

.bg-grey-20\/55 {

    background-color: rgb(204 204 204 / 0.55);
}

.bg-grey-20\/60 {

    background-color: rgb(204 204 204 / 0.6);
}

.bg-grey-20\/65 {

    background-color: rgb(204 204 204 / 0.65);
}

.bg-grey-20\/70 {

    background-color: rgb(204 204 204 / 0.7);
}

.bg-grey-20\/75 {

    background-color: rgb(204 204 204 / 0.75);
}

.bg-grey-20\/80 {

    background-color: rgb(204 204 204 / 0.8);
}

.bg-grey-20\/85 {

    background-color: rgb(204 204 204 / 0.85);
}

.bg-grey-20\/90 {

    background-color: rgb(204 204 204 / 0.9);
}

.bg-grey-20\/95 {

    background-color: rgb(204 204 204 / 0.95);
}

.bg-grey-30 {

    --tw-bg-opacity: 1;

    background-color: rgb(179 179 179 / var(--tw-bg-opacity));
}

.bg-grey-30\/0 {

    background-color: rgb(179 179 179 / 0);
}

.bg-grey-30\/10 {

    background-color: rgb(179 179 179 / 0.1);
}

.bg-grey-30\/100 {

    background-color: rgb(179 179 179 / 1);
}

.bg-grey-30\/15 {

    background-color: rgb(179 179 179 / 0.15);
}

.bg-grey-30\/20 {

    background-color: rgb(179 179 179 / 0.2);
}

.bg-grey-30\/25 {

    background-color: rgb(179 179 179 / 0.25);
}

.bg-grey-30\/30 {

    background-color: rgb(179 179 179 / 0.3);
}

.bg-grey-30\/35 {

    background-color: rgb(179 179 179 / 0.35);
}

.bg-grey-30\/40 {

    background-color: rgb(179 179 179 / 0.4);
}

.bg-grey-30\/45 {

    background-color: rgb(179 179 179 / 0.45);
}

.bg-grey-30\/5 {

    background-color: rgb(179 179 179 / 0.05);
}

.bg-grey-30\/50 {

    background-color: rgb(179 179 179 / 0.5);
}

.bg-grey-30\/55 {

    background-color: rgb(179 179 179 / 0.55);
}

.bg-grey-30\/60 {

    background-color: rgb(179 179 179 / 0.6);
}

.bg-grey-30\/65 {

    background-color: rgb(179 179 179 / 0.65);
}

.bg-grey-30\/70 {

    background-color: rgb(179 179 179 / 0.7);
}

.bg-grey-30\/75 {

    background-color: rgb(179 179 179 / 0.75);
}

.bg-grey-30\/80 {

    background-color: rgb(179 179 179 / 0.8);
}

.bg-grey-30\/85 {

    background-color: rgb(179 179 179 / 0.85);
}

.bg-grey-30\/90 {

    background-color: rgb(179 179 179 / 0.9);
}

.bg-grey-30\/95 {

    background-color: rgb(179 179 179 / 0.95);
}

.bg-grey-40 {

    --tw-bg-opacity: 1;

    background-color: rgb(153 153 153 / var(--tw-bg-opacity));
}

.bg-grey-40\/0 {

    background-color: rgb(153 153 153 / 0);
}

.bg-grey-40\/10 {

    background-color: rgb(153 153 153 / 0.1);
}

.bg-grey-40\/100 {

    background-color: rgb(153 153 153 / 1);
}

.bg-grey-40\/15 {

    background-color: rgb(153 153 153 / 0.15);
}

.bg-grey-40\/20 {

    background-color: rgb(153 153 153 / 0.2);
}

.bg-grey-40\/25 {

    background-color: rgb(153 153 153 / 0.25);
}

.bg-grey-40\/30 {

    background-color: rgb(153 153 153 / 0.3);
}

.bg-grey-40\/35 {

    background-color: rgb(153 153 153 / 0.35);
}

.bg-grey-40\/40 {

    background-color: rgb(153 153 153 / 0.4);
}

.bg-grey-40\/45 {

    background-color: rgb(153 153 153 / 0.45);
}

.bg-grey-40\/5 {

    background-color: rgb(153 153 153 / 0.05);
}

.bg-grey-40\/50 {

    background-color: rgb(153 153 153 / 0.5);
}

.bg-grey-40\/55 {

    background-color: rgb(153 153 153 / 0.55);
}

.bg-grey-40\/60 {

    background-color: rgb(153 153 153 / 0.6);
}

.bg-grey-40\/65 {

    background-color: rgb(153 153 153 / 0.65);
}

.bg-grey-40\/70 {

    background-color: rgb(153 153 153 / 0.7);
}

.bg-grey-40\/75 {

    background-color: rgb(153 153 153 / 0.75);
}

.bg-grey-40\/80 {

    background-color: rgb(153 153 153 / 0.8);
}

.bg-grey-40\/85 {

    background-color: rgb(153 153 153 / 0.85);
}

.bg-grey-40\/90 {

    background-color: rgb(153 153 153 / 0.9);
}

.bg-grey-40\/95 {

    background-color: rgb(153 153 153 / 0.95);
}

.bg-grey-50 {

    --tw-bg-opacity: 1;

    background-color: rgb(128 128 128 / var(--tw-bg-opacity));
}

.bg-grey-50\/0 {

    background-color: rgb(128 128 128 / 0);
}

.bg-grey-50\/10 {

    background-color: rgb(128 128 128 / 0.1);
}

.bg-grey-50\/100 {

    background-color: rgb(128 128 128 / 1);
}

.bg-grey-50\/15 {

    background-color: rgb(128 128 128 / 0.15);
}

.bg-grey-50\/20 {

    background-color: rgb(128 128 128 / 0.2);
}

.bg-grey-50\/25 {

    background-color: rgb(128 128 128 / 0.25);
}

.bg-grey-50\/30 {

    background-color: rgb(128 128 128 / 0.3);
}

.bg-grey-50\/35 {

    background-color: rgb(128 128 128 / 0.35);
}

.bg-grey-50\/40 {

    background-color: rgb(128 128 128 / 0.4);
}

.bg-grey-50\/45 {

    background-color: rgb(128 128 128 / 0.45);
}

.bg-grey-50\/5 {

    background-color: rgb(128 128 128 / 0.05);
}

.bg-grey-50\/50 {

    background-color: rgb(128 128 128 / 0.5);
}

.bg-grey-50\/55 {

    background-color: rgb(128 128 128 / 0.55);
}

.bg-grey-50\/60 {

    background-color: rgb(128 128 128 / 0.6);
}

.bg-grey-50\/65 {

    background-color: rgb(128 128 128 / 0.65);
}

.bg-grey-50\/70 {

    background-color: rgb(128 128 128 / 0.7);
}

.bg-grey-50\/75 {

    background-color: rgb(128 128 128 / 0.75);
}

.bg-grey-50\/80 {

    background-color: rgb(128 128 128 / 0.8);
}

.bg-grey-50\/85 {

    background-color: rgb(128 128 128 / 0.85);
}

.bg-grey-50\/90 {

    background-color: rgb(128 128 128 / 0.9);
}

.bg-grey-50\/95 {

    background-color: rgb(128 128 128 / 0.95);
}

.bg-grey-60 {

    --tw-bg-opacity: 1;

    background-color: rgb(102 102 102 / var(--tw-bg-opacity));
}

.bg-grey-60\/0 {

    background-color: rgb(102 102 102 / 0);
}

.bg-grey-60\/10 {

    background-color: rgb(102 102 102 / 0.1);
}

.bg-grey-60\/100 {

    background-color: rgb(102 102 102 / 1);
}

.bg-grey-60\/15 {

    background-color: rgb(102 102 102 / 0.15);
}

.bg-grey-60\/20 {

    background-color: rgb(102 102 102 / 0.2);
}

.bg-grey-60\/25 {

    background-color: rgb(102 102 102 / 0.25);
}

.bg-grey-60\/30 {

    background-color: rgb(102 102 102 / 0.3);
}

.bg-grey-60\/35 {

    background-color: rgb(102 102 102 / 0.35);
}

.bg-grey-60\/40 {

    background-color: rgb(102 102 102 / 0.4);
}

.bg-grey-60\/45 {

    background-color: rgb(102 102 102 / 0.45);
}

.bg-grey-60\/5 {

    background-color: rgb(102 102 102 / 0.05);
}

.bg-grey-60\/50 {

    background-color: rgb(102 102 102 / 0.5);
}

.bg-grey-60\/55 {

    background-color: rgb(102 102 102 / 0.55);
}

.bg-grey-60\/60 {

    background-color: rgb(102 102 102 / 0.6);
}

.bg-grey-60\/65 {

    background-color: rgb(102 102 102 / 0.65);
}

.bg-grey-60\/70 {

    background-color: rgb(102 102 102 / 0.7);
}

.bg-grey-60\/75 {

    background-color: rgb(102 102 102 / 0.75);
}

.bg-grey-60\/80 {

    background-color: rgb(102 102 102 / 0.8);
}

.bg-grey-60\/85 {

    background-color: rgb(102 102 102 / 0.85);
}

.bg-grey-60\/90 {

    background-color: rgb(102 102 102 / 0.9);
}

.bg-grey-60\/95 {

    background-color: rgb(102 102 102 / 0.95);
}

.bg-grey-70 {

    --tw-bg-opacity: 1;

    background-color: rgb(77 77 77 / var(--tw-bg-opacity));
}

.bg-grey-70\/0 {

    background-color: rgb(77 77 77 / 0);
}

.bg-grey-70\/10 {

    background-color: rgb(77 77 77 / 0.1);
}

.bg-grey-70\/100 {

    background-color: rgb(77 77 77 / 1);
}

.bg-grey-70\/15 {

    background-color: rgb(77 77 77 / 0.15);
}

.bg-grey-70\/20 {

    background-color: rgb(77 77 77 / 0.2);
}

.bg-grey-70\/25 {

    background-color: rgb(77 77 77 / 0.25);
}

.bg-grey-70\/30 {

    background-color: rgb(77 77 77 / 0.3);
}

.bg-grey-70\/35 {

    background-color: rgb(77 77 77 / 0.35);
}

.bg-grey-70\/40 {

    background-color: rgb(77 77 77 / 0.4);
}

.bg-grey-70\/45 {

    background-color: rgb(77 77 77 / 0.45);
}

.bg-grey-70\/5 {

    background-color: rgb(77 77 77 / 0.05);
}

.bg-grey-70\/50 {

    background-color: rgb(77 77 77 / 0.5);
}

.bg-grey-70\/55 {

    background-color: rgb(77 77 77 / 0.55);
}

.bg-grey-70\/60 {

    background-color: rgb(77 77 77 / 0.6);
}

.bg-grey-70\/65 {

    background-color: rgb(77 77 77 / 0.65);
}

.bg-grey-70\/70 {

    background-color: rgb(77 77 77 / 0.7);
}

.bg-grey-70\/75 {

    background-color: rgb(77 77 77 / 0.75);
}

.bg-grey-70\/80 {

    background-color: rgb(77 77 77 / 0.8);
}

.bg-grey-70\/85 {

    background-color: rgb(77 77 77 / 0.85);
}

.bg-grey-70\/90 {

    background-color: rgb(77 77 77 / 0.9);
}

.bg-grey-70\/95 {

    background-color: rgb(77 77 77 / 0.95);
}

.bg-grey-80 {

    --tw-bg-opacity: 1;

    background-color: rgb(51 51 51 / var(--tw-bg-opacity));
}

.bg-grey-80\/0 {

    background-color: rgb(51 51 51 / 0);
}

.bg-grey-80\/10 {

    background-color: rgb(51 51 51 / 0.1);
}

.bg-grey-80\/100 {

    background-color: rgb(51 51 51 / 1);
}

.bg-grey-80\/15 {

    background-color: rgb(51 51 51 / 0.15);
}

.bg-grey-80\/20 {

    background-color: rgb(51 51 51 / 0.2);
}

.bg-grey-80\/25 {

    background-color: rgb(51 51 51 / 0.25);
}

.bg-grey-80\/30 {

    background-color: rgb(51 51 51 / 0.3);
}

.bg-grey-80\/35 {

    background-color: rgb(51 51 51 / 0.35);
}

.bg-grey-80\/40 {

    background-color: rgb(51 51 51 / 0.4);
}

.bg-grey-80\/45 {

    background-color: rgb(51 51 51 / 0.45);
}

.bg-grey-80\/5 {

    background-color: rgb(51 51 51 / 0.05);
}

.bg-grey-80\/50 {

    background-color: rgb(51 51 51 / 0.5);
}

.bg-grey-80\/55 {

    background-color: rgb(51 51 51 / 0.55);
}

.bg-grey-80\/60 {

    background-color: rgb(51 51 51 / 0.6);
}

.bg-grey-80\/65 {

    background-color: rgb(51 51 51 / 0.65);
}

.bg-grey-80\/70 {

    background-color: rgb(51 51 51 / 0.7);
}

.bg-grey-80\/75 {

    background-color: rgb(51 51 51 / 0.75);
}

.bg-grey-80\/80 {

    background-color: rgb(51 51 51 / 0.8);
}

.bg-grey-80\/85 {

    background-color: rgb(51 51 51 / 0.85);
}

.bg-grey-80\/90 {

    background-color: rgb(51 51 51 / 0.9);
}

.bg-grey-80\/95 {

    background-color: rgb(51 51 51 / 0.95);
}

.bg-grey-90 {

    --tw-bg-opacity: 1;

    background-color: rgb(26 26 26 / var(--tw-bg-opacity));
}

.bg-grey-90\/0 {

    background-color: rgb(26 26 26 / 0);
}

.bg-grey-90\/10 {

    background-color: rgb(26 26 26 / 0.1);
}

.bg-grey-90\/100 {

    background-color: rgb(26 26 26 / 1);
}

.bg-grey-90\/15 {

    background-color: rgb(26 26 26 / 0.15);
}

.bg-grey-90\/20 {

    background-color: rgb(26 26 26 / 0.2);
}

.bg-grey-90\/25 {

    background-color: rgb(26 26 26 / 0.25);
}

.bg-grey-90\/30 {

    background-color: rgb(26 26 26 / 0.3);
}

.bg-grey-90\/35 {

    background-color: rgb(26 26 26 / 0.35);
}

.bg-grey-90\/40 {

    background-color: rgb(26 26 26 / 0.4);
}

.bg-grey-90\/45 {

    background-color: rgb(26 26 26 / 0.45);
}

.bg-grey-90\/5 {

    background-color: rgb(26 26 26 / 0.05);
}

.bg-grey-90\/50 {

    background-color: rgb(26 26 26 / 0.5);
}

.bg-grey-90\/55 {

    background-color: rgb(26 26 26 / 0.55);
}

.bg-grey-90\/60 {

    background-color: rgb(26 26 26 / 0.6);
}

.bg-grey-90\/65 {

    background-color: rgb(26 26 26 / 0.65);
}

.bg-grey-90\/70 {

    background-color: rgb(26 26 26 / 0.7);
}

.bg-grey-90\/75 {

    background-color: rgb(26 26 26 / 0.75);
}

.bg-grey-90\/80 {

    background-color: rgb(26 26 26 / 0.8);
}

.bg-grey-90\/85 {

    background-color: rgb(26 26 26 / 0.85);
}

.bg-grey-90\/90 {

    background-color: rgb(26 26 26 / 0.9);
}

.bg-grey-90\/95 {

    background-color: rgb(26 26 26 / 0.95);
}

.bg-grey-dark {

    --tw-bg-opacity: 1;

    background-color: rgb(225 225 225 / var(--tw-bg-opacity));
}

.bg-grey-dark\/0 {

    background-color: rgb(225 225 225 / 0);
}

.bg-grey-dark\/10 {

    background-color: rgb(225 225 225 / 0.1);
}

.bg-grey-dark\/100 {

    background-color: rgb(225 225 225 / 1);
}

.bg-grey-dark\/15 {

    background-color: rgb(225 225 225 / 0.15);
}

.bg-grey-dark\/20 {

    background-color: rgb(225 225 225 / 0.2);
}

.bg-grey-dark\/25 {

    background-color: rgb(225 225 225 / 0.25);
}

.bg-grey-dark\/30 {

    background-color: rgb(225 225 225 / 0.3);
}

.bg-grey-dark\/35 {

    background-color: rgb(225 225 225 / 0.35);
}

.bg-grey-dark\/40 {

    background-color: rgb(225 225 225 / 0.4);
}

.bg-grey-dark\/45 {

    background-color: rgb(225 225 225 / 0.45);
}

.bg-grey-dark\/5 {

    background-color: rgb(225 225 225 / 0.05);
}

.bg-grey-dark\/50 {

    background-color: rgb(225 225 225 / 0.5);
}

.bg-grey-dark\/55 {

    background-color: rgb(225 225 225 / 0.55);
}

.bg-grey-dark\/60 {

    background-color: rgb(225 225 225 / 0.6);
}

.bg-grey-dark\/65 {

    background-color: rgb(225 225 225 / 0.65);
}

.bg-grey-dark\/70 {

    background-color: rgb(225 225 225 / 0.7);
}

.bg-grey-dark\/75 {

    background-color: rgb(225 225 225 / 0.75);
}

.bg-grey-dark\/80 {

    background-color: rgb(225 225 225 / 0.8);
}

.bg-grey-dark\/85 {

    background-color: rgb(225 225 225 / 0.85);
}

.bg-grey-dark\/90 {

    background-color: rgb(225 225 225 / 0.9);
}

.bg-grey-dark\/95 {

    background-color: rgb(225 225 225 / 0.95);
}

.bg-grey-light {

    --tw-bg-opacity: 1;

    background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

.bg-grey-light\/0 {

    background-color: rgb(249 249 249 / 0);
}

.bg-grey-light\/10 {

    background-color: rgb(249 249 249 / 0.1);
}

.bg-grey-light\/100 {

    background-color: rgb(249 249 249 / 1);
}

.bg-grey-light\/15 {

    background-color: rgb(249 249 249 / 0.15);
}

.bg-grey-light\/20 {

    background-color: rgb(249 249 249 / 0.2);
}

.bg-grey-light\/25 {

    background-color: rgb(249 249 249 / 0.25);
}

.bg-grey-light\/30 {

    background-color: rgb(249 249 249 / 0.3);
}

.bg-grey-light\/35 {

    background-color: rgb(249 249 249 / 0.35);
}

.bg-grey-light\/40 {

    background-color: rgb(249 249 249 / 0.4);
}

.bg-grey-light\/45 {

    background-color: rgb(249 249 249 / 0.45);
}

.bg-grey-light\/5 {

    background-color: rgb(249 249 249 / 0.05);
}

.bg-grey-light\/50 {

    background-color: rgb(249 249 249 / 0.5);
}

.bg-grey-light\/55 {

    background-color: rgb(249 249 249 / 0.55);
}

.bg-grey-light\/60 {

    background-color: rgb(249 249 249 / 0.6);
}

.bg-grey-light\/65 {

    background-color: rgb(249 249 249 / 0.65);
}

.bg-grey-light\/70 {

    background-color: rgb(249 249 249 / 0.7);
}

.bg-grey-light\/75 {

    background-color: rgb(249 249 249 / 0.75);
}

.bg-grey-light\/80 {

    background-color: rgb(249 249 249 / 0.8);
}

.bg-grey-light\/85 {

    background-color: rgb(249 249 249 / 0.85);
}

.bg-grey-light\/90 {

    background-color: rgb(249 249 249 / 0.9);
}

.bg-grey-light\/95 {

    background-color: rgb(249 249 249 / 0.95);
}

.bg-grey\/0 {

    background-color: rgb(242 242 242 / 0);
}

.bg-grey\/10 {

    background-color: rgb(242 242 242 / 0.1);
}

.bg-grey\/100 {

    background-color: rgb(242 242 242 / 1);
}

.bg-grey\/15 {

    background-color: rgb(242 242 242 / 0.15);
}

.bg-grey\/20 {

    background-color: rgb(242 242 242 / 0.2);
}

.bg-grey\/25 {

    background-color: rgb(242 242 242 / 0.25);
}

.bg-grey\/30 {

    background-color: rgb(242 242 242 / 0.3);
}

.bg-grey\/35 {

    background-color: rgb(242 242 242 / 0.35);
}

.bg-grey\/40 {

    background-color: rgb(242 242 242 / 0.4);
}

.bg-grey\/45 {

    background-color: rgb(242 242 242 / 0.45);
}

.bg-grey\/5 {

    background-color: rgb(242 242 242 / 0.05);
}

.bg-grey\/50 {

    background-color: rgb(242 242 242 / 0.5);
}

.bg-grey\/55 {

    background-color: rgb(242 242 242 / 0.55);
}

.bg-grey\/60 {

    background-color: rgb(242 242 242 / 0.6);
}

.bg-grey\/65 {

    background-color: rgb(242 242 242 / 0.65);
}

.bg-grey\/70 {

    background-color: rgb(242 242 242 / 0.7);
}

.bg-grey\/75 {

    background-color: rgb(242 242 242 / 0.75);
}

.bg-grey\/80 {

    background-color: rgb(242 242 242 / 0.8);
}

.bg-grey\/85 {

    background-color: rgb(242 242 242 / 0.85);
}

.bg-grey\/90 {

    background-color: rgb(242 242 242 / 0.9);
}

.bg-grey\/95 {

    background-color: rgb(242 242 242 / 0.95);
}

.bg-purple-visited {

    --tw-bg-opacity: 1;

    background-color: rgb(141 80 177 / var(--tw-bg-opacity));
}

.bg-purple-visited-light {

    --tw-bg-opacity: 1;

    background-color: rgb(224 173 255 / var(--tw-bg-opacity));
}

.bg-purple-visited-light\/0 {

    background-color: rgb(224 173 255 / 0);
}

.bg-purple-visited-light\/10 {

    background-color: rgb(224 173 255 / 0.1);
}

.bg-purple-visited-light\/100 {

    background-color: rgb(224 173 255 / 1);
}

.bg-purple-visited-light\/15 {

    background-color: rgb(224 173 255 / 0.15);
}

.bg-purple-visited-light\/20 {

    background-color: rgb(224 173 255 / 0.2);
}

.bg-purple-visited-light\/25 {

    background-color: rgb(224 173 255 / 0.25);
}

.bg-purple-visited-light\/30 {

    background-color: rgb(224 173 255 / 0.3);
}

.bg-purple-visited-light\/35 {

    background-color: rgb(224 173 255 / 0.35);
}

.bg-purple-visited-light\/40 {

    background-color: rgb(224 173 255 / 0.4);
}

.bg-purple-visited-light\/45 {

    background-color: rgb(224 173 255 / 0.45);
}

.bg-purple-visited-light\/5 {

    background-color: rgb(224 173 255 / 0.05);
}

.bg-purple-visited-light\/50 {

    background-color: rgb(224 173 255 / 0.5);
}

.bg-purple-visited-light\/55 {

    background-color: rgb(224 173 255 / 0.55);
}

.bg-purple-visited-light\/60 {

    background-color: rgb(224 173 255 / 0.6);
}

.bg-purple-visited-light\/65 {

    background-color: rgb(224 173 255 / 0.65);
}

.bg-purple-visited-light\/70 {

    background-color: rgb(224 173 255 / 0.7);
}

.bg-purple-visited-light\/75 {

    background-color: rgb(224 173 255 / 0.75);
}

.bg-purple-visited-light\/80 {

    background-color: rgb(224 173 255 / 0.8);
}

.bg-purple-visited-light\/85 {

    background-color: rgb(224 173 255 / 0.85);
}

.bg-purple-visited-light\/90 {

    background-color: rgb(224 173 255 / 0.9);
}

.bg-purple-visited-light\/95 {

    background-color: rgb(224 173 255 / 0.95);
}

.bg-purple-visited\/0 {

    background-color: rgb(141 80 177 / 0);
}

.bg-purple-visited\/10 {

    background-color: rgb(141 80 177 / 0.1);
}

.bg-purple-visited\/100 {

    background-color: rgb(141 80 177 / 1);
}

.bg-purple-visited\/15 {

    background-color: rgb(141 80 177 / 0.15);
}

.bg-purple-visited\/20 {

    background-color: rgb(141 80 177 / 0.2);
}

.bg-purple-visited\/25 {

    background-color: rgb(141 80 177 / 0.25);
}

.bg-purple-visited\/30 {

    background-color: rgb(141 80 177 / 0.3);
}

.bg-purple-visited\/35 {

    background-color: rgb(141 80 177 / 0.35);
}

.bg-purple-visited\/40 {

    background-color: rgb(141 80 177 / 0.4);
}

.bg-purple-visited\/45 {

    background-color: rgb(141 80 177 / 0.45);
}

.bg-purple-visited\/5 {

    background-color: rgb(141 80 177 / 0.05);
}

.bg-purple-visited\/50 {

    background-color: rgb(141 80 177 / 0.5);
}

.bg-purple-visited\/55 {

    background-color: rgb(141 80 177 / 0.55);
}

.bg-purple-visited\/60 {

    background-color: rgb(141 80 177 / 0.6);
}

.bg-purple-visited\/65 {

    background-color: rgb(141 80 177 / 0.65);
}

.bg-purple-visited\/70 {

    background-color: rgb(141 80 177 / 0.7);
}

.bg-purple-visited\/75 {

    background-color: rgb(141 80 177 / 0.75);
}

.bg-purple-visited\/80 {

    background-color: rgb(141 80 177 / 0.8);
}

.bg-purple-visited\/85 {

    background-color: rgb(141 80 177 / 0.85);
}

.bg-purple-visited\/90 {

    background-color: rgb(141 80 177 / 0.9);
}

.bg-purple-visited\/95 {

    background-color: rgb(141 80 177 / 0.95);
}

.bg-red {

    --tw-bg-opacity: 1;

    background-color: rgb(255 130 116 / var(--tw-bg-opacity));
}

.bg-red-30 {

    --tw-bg-opacity: 1;

    background-color: rgb(255 223 220 / var(--tw-bg-opacity));
}

.bg-red-30\/0 {

    background-color: rgb(255 223 220 / 0);
}

.bg-red-30\/10 {

    background-color: rgb(255 223 220 / 0.1);
}

.bg-red-30\/100 {

    background-color: rgb(255 223 220 / 1);
}

.bg-red-30\/15 {

    background-color: rgb(255 223 220 / 0.15);
}

.bg-red-30\/20 {

    background-color: rgb(255 223 220 / 0.2);
}

.bg-red-30\/25 {

    background-color: rgb(255 223 220 / 0.25);
}

.bg-red-30\/30 {

    background-color: rgb(255 223 220 / 0.3);
}

.bg-red-30\/35 {

    background-color: rgb(255 223 220 / 0.35);
}

.bg-red-30\/40 {

    background-color: rgb(255 223 220 / 0.4);
}

.bg-red-30\/45 {

    background-color: rgb(255 223 220 / 0.45);
}

.bg-red-30\/5 {

    background-color: rgb(255 223 220 / 0.05);
}

.bg-red-30\/50 {

    background-color: rgb(255 223 220 / 0.5);
}

.bg-red-30\/55 {

    background-color: rgb(255 223 220 / 0.55);
}

.bg-red-30\/60 {

    background-color: rgb(255 223 220 / 0.6);
}

.bg-red-30\/65 {

    background-color: rgb(255 223 220 / 0.65);
}

.bg-red-30\/70 {

    background-color: rgb(255 223 220 / 0.7);
}

.bg-red-30\/75 {

    background-color: rgb(255 223 220 / 0.75);
}

.bg-red-30\/80 {

    background-color: rgb(255 223 220 / 0.8);
}

.bg-red-30\/85 {

    background-color: rgb(255 223 220 / 0.85);
}

.bg-red-30\/90 {

    background-color: rgb(255 223 220 / 0.9);
}

.bg-red-30\/95 {

    background-color: rgb(255 223 220 / 0.95);
}

.bg-red\/0 {

    background-color: rgb(255 130 116 / 0);
}

.bg-red\/10 {

    background-color: rgb(255 130 116 / 0.1);
}

.bg-red\/100 {

    background-color: rgb(255 130 116 / 1);
}

.bg-red\/15 {

    background-color: rgb(255 130 116 / 0.15);
}

.bg-red\/20 {

    background-color: rgb(255 130 116 / 0.2);
}

.bg-red\/25 {

    background-color: rgb(255 130 116 / 0.25);
}

.bg-red\/30 {

    background-color: rgb(255 130 116 / 0.3);
}

.bg-red\/35 {

    background-color: rgb(255 130 116 / 0.35);
}

.bg-red\/40 {

    background-color: rgb(255 130 116 / 0.4);
}

.bg-red\/45 {

    background-color: rgb(255 130 116 / 0.45);
}

.bg-red\/5 {

    background-color: rgb(255 130 116 / 0.05);
}

.bg-red\/50 {

    background-color: rgb(255 130 116 / 0.5);
}

.bg-red\/55 {

    background-color: rgb(255 130 116 / 0.55);
}

.bg-red\/60 {

    background-color: rgb(255 130 116 / 0.6);
}

.bg-red\/65 {

    background-color: rgb(255 130 116 / 0.65);
}

.bg-red\/70 {

    background-color: rgb(255 130 116 / 0.7);
}

.bg-red\/75 {

    background-color: rgb(255 130 116 / 0.75);
}

.bg-red\/80 {

    background-color: rgb(255 130 116 / 0.8);
}

.bg-red\/85 {

    background-color: rgb(255 130 116 / 0.85);
}

.bg-red\/90 {

    background-color: rgb(255 130 116 / 0.9);
}

.bg-red\/95 {

    background-color: rgb(255 130 116 / 0.95);
}

.bg-state-danger {

    background-color: #FF8274B3;
}

.bg-state-danger\/0 {

    background-color: rgb(255 130 116 / 0);
}

.bg-state-danger\/10 {

    background-color: rgb(255 130 116 / 0.1);
}

.bg-state-danger\/100 {

    background-color: rgb(255 130 116 / 1);
}

.bg-state-danger\/15 {

    background-color: rgb(255 130 116 / 0.15);
}

.bg-state-danger\/20 {

    background-color: rgb(255 130 116 / 0.2);
}

.bg-state-danger\/25 {

    background-color: rgb(255 130 116 / 0.25);
}

.bg-state-danger\/30 {

    background-color: rgb(255 130 116 / 0.3);
}

.bg-state-danger\/35 {

    background-color: rgb(255 130 116 / 0.35);
}

.bg-state-danger\/40 {

    background-color: rgb(255 130 116 / 0.4);
}

.bg-state-danger\/45 {

    background-color: rgb(255 130 116 / 0.45);
}

.bg-state-danger\/5 {

    background-color: rgb(255 130 116 / 0.05);
}

.bg-state-danger\/50 {

    background-color: rgb(255 130 116 / 0.5);
}

.bg-state-danger\/55 {

    background-color: rgb(255 130 116 / 0.55);
}

.bg-state-danger\/60 {

    background-color: rgb(255 130 116 / 0.6);
}

.bg-state-danger\/65 {

    background-color: rgb(255 130 116 / 0.65);
}

.bg-state-danger\/70 {

    background-color: rgb(255 130 116 / 0.7);
}

.bg-state-danger\/75 {

    background-color: rgb(255 130 116 / 0.75);
}

.bg-state-danger\/80 {

    background-color: rgb(255 130 116 / 0.8);
}

.bg-state-danger\/85 {

    background-color: rgb(255 130 116 / 0.85);
}

.bg-state-danger\/90 {

    background-color: rgb(255 130 116 / 0.9);
}

.bg-state-danger\/95 {

    background-color: rgb(255 130 116 / 0.95);
}

.bg-state-success {

    background-color: #C7F6C9B3;
}

.bg-state-success\/0 {

    background-color: rgb(199 246 201 / 0);
}

.bg-state-success\/10 {

    background-color: rgb(199 246 201 / 0.1);
}

.bg-state-success\/100 {

    background-color: rgb(199 246 201 / 1);
}

.bg-state-success\/15 {

    background-color: rgb(199 246 201 / 0.15);
}

.bg-state-success\/20 {

    background-color: rgb(199 246 201 / 0.2);
}

.bg-state-success\/25 {

    background-color: rgb(199 246 201 / 0.25);
}

.bg-state-success\/30 {

    background-color: rgb(199 246 201 / 0.3);
}

.bg-state-success\/35 {

    background-color: rgb(199 246 201 / 0.35);
}

.bg-state-success\/40 {

    background-color: rgb(199 246 201 / 0.4);
}

.bg-state-success\/45 {

    background-color: rgb(199 246 201 / 0.45);
}

.bg-state-success\/5 {

    background-color: rgb(199 246 201 / 0.05);
}

.bg-state-success\/50 {

    background-color: rgb(199 246 201 / 0.5);
}

.bg-state-success\/55 {

    background-color: rgb(199 246 201 / 0.55);
}

.bg-state-success\/60 {

    background-color: rgb(199 246 201 / 0.6);
}

.bg-state-success\/65 {

    background-color: rgb(199 246 201 / 0.65);
}

.bg-state-success\/70 {

    background-color: rgb(199 246 201 / 0.7);
}

.bg-state-success\/75 {

    background-color: rgb(199 246 201 / 0.75);
}

.bg-state-success\/80 {

    background-color: rgb(199 246 201 / 0.8);
}

.bg-state-success\/85 {

    background-color: rgb(199 246 201 / 0.85);
}

.bg-state-success\/90 {

    background-color: rgb(199 246 201 / 0.9);
}

.bg-state-success\/95 {

    background-color: rgb(199 246 201 / 0.95);
}

.bg-state-warning {

    background-color: #F9C66BB3;
}

.bg-state-warning\/0 {

    background-color: rgb(249 198 107 / 0);
}

.bg-state-warning\/10 {

    background-color: rgb(249 198 107 / 0.1);
}

.bg-state-warning\/100 {

    background-color: rgb(249 198 107 / 1);
}

.bg-state-warning\/15 {

    background-color: rgb(249 198 107 / 0.15);
}

.bg-state-warning\/20 {

    background-color: rgb(249 198 107 / 0.2);
}

.bg-state-warning\/25 {

    background-color: rgb(249 198 107 / 0.25);
}

.bg-state-warning\/30 {

    background-color: rgb(249 198 107 / 0.3);
}

.bg-state-warning\/35 {

    background-color: rgb(249 198 107 / 0.35);
}

.bg-state-warning\/40 {

    background-color: rgb(249 198 107 / 0.4);
}

.bg-state-warning\/45 {

    background-color: rgb(249 198 107 / 0.45);
}

.bg-state-warning\/5 {

    background-color: rgb(249 198 107 / 0.05);
}

.bg-state-warning\/50 {

    background-color: rgb(249 198 107 / 0.5);
}

.bg-state-warning\/55 {

    background-color: rgb(249 198 107 / 0.55);
}

.bg-state-warning\/60 {

    background-color: rgb(249 198 107 / 0.6);
}

.bg-state-warning\/65 {

    background-color: rgb(249 198 107 / 0.65);
}

.bg-state-warning\/70 {

    background-color: rgb(249 198 107 / 0.7);
}

.bg-state-warning\/75 {

    background-color: rgb(249 198 107 / 0.75);
}

.bg-state-warning\/80 {

    background-color: rgb(249 198 107 / 0.8);
}

.bg-state-warning\/85 {

    background-color: rgb(249 198 107 / 0.85);
}

.bg-state-warning\/90 {

    background-color: rgb(249 198 107 / 0.9);
}

.bg-state-warning\/95 {

    background-color: rgb(249 198 107 / 0.95);
}

.bg-transparent {

    background-color: transparent;
}

.bg-transparent\/0 {

    background-color: rgb(0 0 0 / 0);
}

.bg-transparent\/10 {

    background-color: rgb(0 0 0 / 0.1);
}

.bg-transparent\/100 {

    background-color: rgb(0 0 0 / 1);
}

.bg-transparent\/15 {

    background-color: rgb(0 0 0 / 0.15);
}

.bg-transparent\/20 {

    background-color: rgb(0 0 0 / 0.2);
}

.bg-transparent\/25 {

    background-color: rgb(0 0 0 / 0.25);
}

.bg-transparent\/30 {

    background-color: rgb(0 0 0 / 0.3);
}

.bg-transparent\/35 {

    background-color: rgb(0 0 0 / 0.35);
}

.bg-transparent\/40 {

    background-color: rgb(0 0 0 / 0.4);
}

.bg-transparent\/45 {

    background-color: rgb(0 0 0 / 0.45);
}

.bg-transparent\/5 {

    background-color: rgb(0 0 0 / 0.05);
}

.bg-transparent\/50 {

    background-color: rgb(0 0 0 / 0.5);
}

.bg-transparent\/55 {

    background-color: rgb(0 0 0 / 0.55);
}

.bg-transparent\/60 {

    background-color: rgb(0 0 0 / 0.6);
}

.bg-transparent\/65 {

    background-color: rgb(0 0 0 / 0.65);
}

.bg-transparent\/70 {

    background-color: rgb(0 0 0 / 0.7);
}

.bg-transparent\/75 {

    background-color: rgb(0 0 0 / 0.75);
}

.bg-transparent\/80 {

    background-color: rgb(0 0 0 / 0.8);
}

.bg-transparent\/85 {

    background-color: rgb(0 0 0 / 0.85);
}

.bg-transparent\/90 {

    background-color: rgb(0 0 0 / 0.9);
}

.bg-transparent\/95 {

    background-color: rgb(0 0 0 / 0.95);
}

.bg-white {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/0 {

    background-color: rgb(255 255 255 / 0);
}

.bg-white\/10 {

    background-color: rgb(255 255 255 / 0.1);
}

.bg-white\/100 {

    background-color: rgb(255 255 255 / 1);
}

.bg-white\/15 {

    background-color: rgb(255 255 255 / 0.15);
}

.bg-white\/20 {

    background-color: rgb(255 255 255 / 0.2);
}

.bg-white\/25 {

    background-color: rgb(255 255 255 / 0.25);
}

.bg-white\/30 {

    background-color: rgb(255 255 255 / 0.3);
}

.bg-white\/35 {

    background-color: rgb(255 255 255 / 0.35);
}

.bg-white\/40 {

    background-color: rgb(255 255 255 / 0.4);
}

.bg-white\/45 {

    background-color: rgb(255 255 255 / 0.45);
}

.bg-white\/5 {

    background-color: rgb(255 255 255 / 0.05);
}

.bg-white\/50 {

    background-color: rgb(255 255 255 / 0.5);
}

.bg-white\/55 {

    background-color: rgb(255 255 255 / 0.55);
}

.bg-white\/60 {

    background-color: rgb(255 255 255 / 0.6);
}

.bg-white\/65 {

    background-color: rgb(255 255 255 / 0.65);
}

.bg-white\/70 {

    background-color: rgb(255 255 255 / 0.7);
}

.bg-white\/75 {

    background-color: rgb(255 255 255 / 0.75);
}

.bg-white\/80 {

    background-color: rgb(255 255 255 / 0.8);
}

.bg-white\/85 {

    background-color: rgb(255 255 255 / 0.85);
}

.bg-white\/90 {

    background-color: rgb(255 255 255 / 0.9);
}

.bg-white\/95 {

    background-color: rgb(255 255 255 / 0.95);
}

.bg-yellow {

    --tw-bg-opacity: 1;

    background-color: rgb(249 198 107 / var(--tw-bg-opacity));
}

.bg-yellow-30 {

    --tw-bg-opacity: 1;

    background-color: rgb(255 231 188 / var(--tw-bg-opacity));
}

.bg-yellow-30\/0 {

    background-color: rgb(255 231 188 / 0);
}

.bg-yellow-30\/10 {

    background-color: rgb(255 231 188 / 0.1);
}

.bg-yellow-30\/100 {

    background-color: rgb(255 231 188 / 1);
}

.bg-yellow-30\/15 {

    background-color: rgb(255 231 188 / 0.15);
}

.bg-yellow-30\/20 {

    background-color: rgb(255 231 188 / 0.2);
}

.bg-yellow-30\/25 {

    background-color: rgb(255 231 188 / 0.25);
}

.bg-yellow-30\/30 {

    background-color: rgb(255 231 188 / 0.3);
}

.bg-yellow-30\/35 {

    background-color: rgb(255 231 188 / 0.35);
}

.bg-yellow-30\/40 {

    background-color: rgb(255 231 188 / 0.4);
}

.bg-yellow-30\/45 {

    background-color: rgb(255 231 188 / 0.45);
}

.bg-yellow-30\/5 {

    background-color: rgb(255 231 188 / 0.05);
}

.bg-yellow-30\/50 {

    background-color: rgb(255 231 188 / 0.5);
}

.bg-yellow-30\/55 {

    background-color: rgb(255 231 188 / 0.55);
}

.bg-yellow-30\/60 {

    background-color: rgb(255 231 188 / 0.6);
}

.bg-yellow-30\/65 {

    background-color: rgb(255 231 188 / 0.65);
}

.bg-yellow-30\/70 {

    background-color: rgb(255 231 188 / 0.7);
}

.bg-yellow-30\/75 {

    background-color: rgb(255 231 188 / 0.75);
}

.bg-yellow-30\/80 {

    background-color: rgb(255 231 188 / 0.8);
}

.bg-yellow-30\/85 {

    background-color: rgb(255 231 188 / 0.85);
}

.bg-yellow-30\/90 {

    background-color: rgb(255 231 188 / 0.9);
}

.bg-yellow-30\/95 {

    background-color: rgb(255 231 188 / 0.95);
}

.bg-yellow-50 {

    --tw-bg-opacity: 1;

    background-color: rgb(255 231 188 / var(--tw-bg-opacity));
}

.bg-yellow-50\/0 {

    background-color: rgb(255 231 188 / 0);
}

.bg-yellow-50\/10 {

    background-color: rgb(255 231 188 / 0.1);
}

.bg-yellow-50\/100 {

    background-color: rgb(255 231 188 / 1);
}

.bg-yellow-50\/15 {

    background-color: rgb(255 231 188 / 0.15);
}

.bg-yellow-50\/20 {

    background-color: rgb(255 231 188 / 0.2);
}

.bg-yellow-50\/25 {

    background-color: rgb(255 231 188 / 0.25);
}

.bg-yellow-50\/30 {

    background-color: rgb(255 231 188 / 0.3);
}

.bg-yellow-50\/35 {

    background-color: rgb(255 231 188 / 0.35);
}

.bg-yellow-50\/40 {

    background-color: rgb(255 231 188 / 0.4);
}

.bg-yellow-50\/45 {

    background-color: rgb(255 231 188 / 0.45);
}

.bg-yellow-50\/5 {

    background-color: rgb(255 231 188 / 0.05);
}

.bg-yellow-50\/50 {

    background-color: rgb(255 231 188 / 0.5);
}

.bg-yellow-50\/55 {

    background-color: rgb(255 231 188 / 0.55);
}

.bg-yellow-50\/60 {

    background-color: rgb(255 231 188 / 0.6);
}

.bg-yellow-50\/65 {

    background-color: rgb(255 231 188 / 0.65);
}

.bg-yellow-50\/70 {

    background-color: rgb(255 231 188 / 0.7);
}

.bg-yellow-50\/75 {

    background-color: rgb(255 231 188 / 0.75);
}

.bg-yellow-50\/80 {

    background-color: rgb(255 231 188 / 0.8);
}

.bg-yellow-50\/85 {

    background-color: rgb(255 231 188 / 0.85);
}

.bg-yellow-50\/90 {

    background-color: rgb(255 231 188 / 0.9);
}

.bg-yellow-50\/95 {

    background-color: rgb(255 231 188 / 0.95);
}

.bg-yellow-secondary {

    --tw-bg-opacity: 1;

    background-color: rgb(255 231 188 / var(--tw-bg-opacity));
}

.bg-yellow-secondary\/0 {

    background-color: rgb(255 231 188 / 0);
}

.bg-yellow-secondary\/10 {

    background-color: rgb(255 231 188 / 0.1);
}

.bg-yellow-secondary\/100 {

    background-color: rgb(255 231 188 / 1);
}

.bg-yellow-secondary\/15 {

    background-color: rgb(255 231 188 / 0.15);
}

.bg-yellow-secondary\/20 {

    background-color: rgb(255 231 188 / 0.2);
}

.bg-yellow-secondary\/25 {

    background-color: rgb(255 231 188 / 0.25);
}

.bg-yellow-secondary\/30 {

    background-color: rgb(255 231 188 / 0.3);
}

.bg-yellow-secondary\/35 {

    background-color: rgb(255 231 188 / 0.35);
}

.bg-yellow-secondary\/40 {

    background-color: rgb(255 231 188 / 0.4);
}

.bg-yellow-secondary\/45 {

    background-color: rgb(255 231 188 / 0.45);
}

.bg-yellow-secondary\/5 {

    background-color: rgb(255 231 188 / 0.05);
}

.bg-yellow-secondary\/50 {

    background-color: rgb(255 231 188 / 0.5);
}

.bg-yellow-secondary\/55 {

    background-color: rgb(255 231 188 / 0.55);
}

.bg-yellow-secondary\/60 {

    background-color: rgb(255 231 188 / 0.6);
}

.bg-yellow-secondary\/65 {

    background-color: rgb(255 231 188 / 0.65);
}

.bg-yellow-secondary\/70 {

    background-color: rgb(255 231 188 / 0.7);
}

.bg-yellow-secondary\/75 {

    background-color: rgb(255 231 188 / 0.75);
}

.bg-yellow-secondary\/80 {

    background-color: rgb(255 231 188 / 0.8);
}

.bg-yellow-secondary\/85 {

    background-color: rgb(255 231 188 / 0.85);
}

.bg-yellow-secondary\/90 {

    background-color: rgb(255 231 188 / 0.9);
}

.bg-yellow-secondary\/95 {

    background-color: rgb(255 231 188 / 0.95);
}

.bg-yellow\/0 {

    background-color: rgb(249 198 107 / 0);
}

.bg-yellow\/10 {

    background-color: rgb(249 198 107 / 0.1);
}

.bg-yellow\/100 {

    background-color: rgb(249 198 107 / 1);
}

.bg-yellow\/15 {

    background-color: rgb(249 198 107 / 0.15);
}

.bg-yellow\/20 {

    background-color: rgb(249 198 107 / 0.2);
}

.bg-yellow\/25 {

    background-color: rgb(249 198 107 / 0.25);
}

.bg-yellow\/30 {

    background-color: rgb(249 198 107 / 0.3);
}

.bg-yellow\/35 {

    background-color: rgb(249 198 107 / 0.35);
}

.bg-yellow\/40 {

    background-color: rgb(249 198 107 / 0.4);
}

.bg-yellow\/45 {

    background-color: rgb(249 198 107 / 0.45);
}

.bg-yellow\/5 {

    background-color: rgb(249 198 107 / 0.05);
}

.bg-yellow\/50 {

    background-color: rgb(249 198 107 / 0.5);
}

.bg-yellow\/55 {

    background-color: rgb(249 198 107 / 0.55);
}

.bg-yellow\/60 {

    background-color: rgb(249 198 107 / 0.6);
}

.bg-yellow\/65 {

    background-color: rgb(249 198 107 / 0.65);
}

.bg-yellow\/70 {

    background-color: rgb(249 198 107 / 0.7);
}

.bg-yellow\/75 {

    background-color: rgb(249 198 107 / 0.75);
}

.bg-yellow\/80 {

    background-color: rgb(249 198 107 / 0.8);
}

.bg-yellow\/85 {

    background-color: rgb(249 198 107 / 0.85);
}

.bg-yellow\/90 {

    background-color: rgb(249 198 107 / 0.9);
}

.bg-yellow\/95 {

    background-color: rgb(249 198 107 / 0.95);
}

.bg-opacity-0 {

    --tw-bg-opacity: 0;
}

.bg-opacity-10 {

    --tw-bg-opacity: 0.1;
}

.bg-opacity-100 {

    --tw-bg-opacity: 1;
}

.bg-opacity-15 {

    --tw-bg-opacity: 0.15;
}

.bg-opacity-20 {

    --tw-bg-opacity: 0.2;
}

.bg-opacity-25 {

    --tw-bg-opacity: 0.25;
}

.bg-opacity-30 {

    --tw-bg-opacity: 0.3;
}

.bg-opacity-35 {

    --tw-bg-opacity: 0.35;
}

.bg-opacity-40 {

    --tw-bg-opacity: 0.4;
}

.bg-opacity-45 {

    --tw-bg-opacity: 0.45;
}

.bg-opacity-5 {

    --tw-bg-opacity: 0.05;
}

.bg-opacity-50 {

    --tw-bg-opacity: 0.5;
}

.bg-opacity-55 {

    --tw-bg-opacity: 0.55;
}

.bg-opacity-60 {

    --tw-bg-opacity: 0.6;
}

.bg-opacity-65 {

    --tw-bg-opacity: 0.65;
}

.bg-opacity-70 {

    --tw-bg-opacity: 0.7;
}

.bg-opacity-75 {

    --tw-bg-opacity: 0.75;
}

.bg-opacity-80 {

    --tw-bg-opacity: 0.8;
}

.bg-opacity-85 {

    --tw-bg-opacity: 0.85;
}

.bg-opacity-90 {

    --tw-bg-opacity: 0.9;
}

.bg-opacity-95 {

    --tw-bg-opacity: 0.95;
}

.bg-gradient-to-b {

    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-bl {

    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}

.bg-gradient-to-br {

    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.bg-gradient-to-l {

    background-image: linear-gradient(to left, var(--tw-gradient-stops));
}

.bg-gradient-to-r {

    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {

    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-gradient-to-tl {

    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}

.bg-gradient-to-tr {

    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-none {

    background-image: none;
}

.bg-auto {

    background-size: auto;
}

.bg-contain {

    background-size: contain;
}

.bg-cover {

    background-size: cover;
}

.bg-fixed {

    background-attachment: fixed;
}

.bg-local {

    background-attachment: local;
}

.bg-scroll {

    background-attachment: scroll;
}

.bg-clip-border {

    background-clip: border-box;
}

.bg-clip-padding {

    background-clip: padding-box;
}

.bg-clip-content {

    background-clip: content-box;
}

.bg-clip-text {

    -webkit-background-clip: text;

            background-clip: text;
}

.bg-bottom {

    background-position: bottom;
}

.bg-center {

    background-position: center;
}

.bg-left {

    background-position: left;
}

.bg-left-bottom {

    background-position: left bottom;
}

.bg-left-top {

    background-position: left top;
}

.bg-right {

    background-position: right;
}

.bg-right-bottom {

    background-position: right bottom;
}

.bg-right-top {

    background-position: right top;
}

.bg-top {

    background-position: top;
}

.bg-repeat {

    background-repeat: repeat;
}

.bg-no-repeat {

    background-repeat: no-repeat;
}

.bg-repeat-x {

    background-repeat: repeat-x;
}

.bg-repeat-y {

    background-repeat: repeat-y;
}

.bg-repeat-round {

    background-repeat: round;
}

.bg-repeat-space {

    background-repeat: space;
}

.bg-origin-border {

    background-origin: border-box;
}

.bg-origin-padding {

    background-origin: padding-box;
}

.bg-origin-content {

    background-origin: content-box;
}

.object-contain {

    object-fit: contain;
}

.object-cover {

    object-fit: cover;
}

.p-0 {

    padding: 0px;
}

.p-1 {

    padding: 5px;
}

.p-2 {

    padding: 10px;
}

.p-3 {

    padding: 15px;
}

.p-5 {

    padding: 50px;
}

.px-1 {

    padding-left: 5px;

    padding-right: 5px;
}

.px-2 {

    padding-left: 10px;

    padding-right: 10px;
}

.px-3 {

    padding-left: 15px;

    padding-right: 15px;
}

.px-4 {

    padding-left: 30px;

    padding-right: 30px;
}

.px-\[16px\] {

    padding-left: 16px;

    padding-right: 16px;
}

.py-1 {

    padding-top: 5px;

    padding-bottom: 5px;
}

.py-2 {

    padding-top: 10px;

    padding-bottom: 10px;
}

.py-3 {

    padding-top: 15px;

    padding-bottom: 15px;
}

.py-\[24px\] {

    padding-top: 24px;

    padding-bottom: 24px;
}

.pb-0 {

    padding-bottom: 0px;
}

.pb-2 {

    padding-bottom: 10px;
}

.pb-4 {

    padding-bottom: 30px;
}

.pb-5 {

    padding-bottom: 50px;
}

.pb-6 {

    padding-bottom: 75px;
}

.pb-7 {

    padding-bottom: 100px;
}

.pl-0 {

    padding-left: 0px;
}

.pl-3 {

    padding-left: 15px;
}

.pl-5 {

    padding-left: 50px;
}

.pl-6 {

    padding-left: 75px;
}

.pt-2 {

    padding-top: 10px;
}

.pt-5 {

    padding-top: 50px;
}

.pt-7 {

    padding-top: 100px;
}

.pt-\[60px\] {

    padding-top: 60px;
}

.text-left {

    text-align: left;
}

.text-center {

    text-align: center;
}

.text-right {

    text-align: right;
}

.text-justify {

    text-align: justify;
}

.text-start {

    text-align: start;
}

.text-end {

    text-align: end;
}

.align-middle {

    vertical-align: middle;
}

.font-bold {

    font-weight: 700;
}

.font-light {

    font-weight: 300;
}

.font-semibold {

    font-weight: 600;
}

.uppercase {

    text-transform: uppercase;
}

.lowercase {

    text-transform: lowercase;
}

.leading-none {

    line-height: 1;
}

.text-beige {

    --tw-text-opacity: 1;

    color: rgb(248 240 221 / var(--tw-text-opacity));
}

.text-beige-dark {

    --tw-text-opacity: 1;

    color: rgb(208 191 174 / var(--tw-text-opacity));
}

.text-beige-dark\/0 {

    color: rgb(208 191 174 / 0);
}

.text-beige-dark\/10 {

    color: rgb(208 191 174 / 0.1);
}

.text-beige-dark\/100 {

    color: rgb(208 191 174 / 1);
}

.text-beige-dark\/15 {

    color: rgb(208 191 174 / 0.15);
}

.text-beige-dark\/20 {

    color: rgb(208 191 174 / 0.2);
}

.text-beige-dark\/25 {

    color: rgb(208 191 174 / 0.25);
}

.text-beige-dark\/30 {

    color: rgb(208 191 174 / 0.3);
}

.text-beige-dark\/35 {

    color: rgb(208 191 174 / 0.35);
}

.text-beige-dark\/40 {

    color: rgb(208 191 174 / 0.4);
}

.text-beige-dark\/45 {

    color: rgb(208 191 174 / 0.45);
}

.text-beige-dark\/5 {

    color: rgb(208 191 174 / 0.05);
}

.text-beige-dark\/50 {

    color: rgb(208 191 174 / 0.5);
}

.text-beige-dark\/55 {

    color: rgb(208 191 174 / 0.55);
}

.text-beige-dark\/60 {

    color: rgb(208 191 174 / 0.6);
}

.text-beige-dark\/65 {

    color: rgb(208 191 174 / 0.65);
}

.text-beige-dark\/70 {

    color: rgb(208 191 174 / 0.7);
}

.text-beige-dark\/75 {

    color: rgb(208 191 174 / 0.75);
}

.text-beige-dark\/80 {

    color: rgb(208 191 174 / 0.8);
}

.text-beige-dark\/85 {

    color: rgb(208 191 174 / 0.85);
}

.text-beige-dark\/90 {

    color: rgb(208 191 174 / 0.9);
}

.text-beige-dark\/95 {

    color: rgb(208 191 174 / 0.95);
}

.text-beige\/0 {

    color: rgb(248 240 221 / 0);
}

.text-beige\/10 {

    color: rgb(248 240 221 / 0.1);
}

.text-beige\/100 {

    color: rgb(248 240 221 / 1);
}

.text-beige\/15 {

    color: rgb(248 240 221 / 0.15);
}

.text-beige\/20 {

    color: rgb(248 240 221 / 0.2);
}

.text-beige\/25 {

    color: rgb(248 240 221 / 0.25);
}

.text-beige\/30 {

    color: rgb(248 240 221 / 0.3);
}

.text-beige\/35 {

    color: rgb(248 240 221 / 0.35);
}

.text-beige\/40 {

    color: rgb(248 240 221 / 0.4);
}

.text-beige\/45 {

    color: rgb(248 240 221 / 0.45);
}

.text-beige\/5 {

    color: rgb(248 240 221 / 0.05);
}

.text-beige\/50 {

    color: rgb(248 240 221 / 0.5);
}

.text-beige\/55 {

    color: rgb(248 240 221 / 0.55);
}

.text-beige\/60 {

    color: rgb(248 240 221 / 0.6);
}

.text-beige\/65 {

    color: rgb(248 240 221 / 0.65);
}

.text-beige\/70 {

    color: rgb(248 240 221 / 0.7);
}

.text-beige\/75 {

    color: rgb(248 240 221 / 0.75);
}

.text-beige\/80 {

    color: rgb(248 240 221 / 0.8);
}

.text-beige\/85 {

    color: rgb(248 240 221 / 0.85);
}

.text-beige\/90 {

    color: rgb(248 240 221 / 0.9);
}

.text-beige\/95 {

    color: rgb(248 240 221 / 0.95);
}

.text-black {

    --tw-text-opacity: 1;

    color: rgb(44 44 44 / var(--tw-text-opacity));
}

.text-black-backdrop {

    color: #2c2c2c99;
}

.text-black-backdrop\/0 {

    color: rgb(44 44 44 / 0);
}

.text-black-backdrop\/10 {

    color: rgb(44 44 44 / 0.1);
}

.text-black-backdrop\/100 {

    color: rgb(44 44 44 / 1);
}

.text-black-backdrop\/15 {

    color: rgb(44 44 44 / 0.15);
}

.text-black-backdrop\/20 {

    color: rgb(44 44 44 / 0.2);
}

.text-black-backdrop\/25 {

    color: rgb(44 44 44 / 0.25);
}

.text-black-backdrop\/30 {

    color: rgb(44 44 44 / 0.3);
}

.text-black-backdrop\/35 {

    color: rgb(44 44 44 / 0.35);
}

.text-black-backdrop\/40 {

    color: rgb(44 44 44 / 0.4);
}

.text-black-backdrop\/45 {

    color: rgb(44 44 44 / 0.45);
}

.text-black-backdrop\/5 {

    color: rgb(44 44 44 / 0.05);
}

.text-black-backdrop\/50 {

    color: rgb(44 44 44 / 0.5);
}

.text-black-backdrop\/55 {

    color: rgb(44 44 44 / 0.55);
}

.text-black-backdrop\/60 {

    color: rgb(44 44 44 / 0.6);
}

.text-black-backdrop\/65 {

    color: rgb(44 44 44 / 0.65);
}

.text-black-backdrop\/70 {

    color: rgb(44 44 44 / 0.7);
}

.text-black-backdrop\/75 {

    color: rgb(44 44 44 / 0.75);
}

.text-black-backdrop\/80 {

    color: rgb(44 44 44 / 0.8);
}

.text-black-backdrop\/85 {

    color: rgb(44 44 44 / 0.85);
}

.text-black-backdrop\/90 {

    color: rgb(44 44 44 / 0.9);
}

.text-black-backdrop\/95 {

    color: rgb(44 44 44 / 0.95);
}

.text-black\/0 {

    color: rgb(44 44 44 / 0);
}

.text-black\/10 {

    color: rgb(44 44 44 / 0.1);
}

.text-black\/100 {

    color: rgb(44 44 44 / 1);
}

.text-black\/15 {

    color: rgb(44 44 44 / 0.15);
}

.text-black\/20 {

    color: rgb(44 44 44 / 0.2);
}

.text-black\/25 {

    color: rgb(44 44 44 / 0.25);
}

.text-black\/30 {

    color: rgb(44 44 44 / 0.3);
}

.text-black\/35 {

    color: rgb(44 44 44 / 0.35);
}

.text-black\/40 {

    color: rgb(44 44 44 / 0.4);
}

.text-black\/45 {

    color: rgb(44 44 44 / 0.45);
}

.text-black\/5 {

    color: rgb(44 44 44 / 0.05);
}

.text-black\/50 {

    color: rgb(44 44 44 / 0.5);
}

.text-black\/55 {

    color: rgb(44 44 44 / 0.55);
}

.text-black\/60 {

    color: rgb(44 44 44 / 0.6);
}

.text-black\/65 {

    color: rgb(44 44 44 / 0.65);
}

.text-black\/70 {

    color: rgb(44 44 44 / 0.7);
}

.text-black\/75 {

    color: rgb(44 44 44 / 0.75);
}

.text-black\/80 {

    color: rgb(44 44 44 / 0.8);
}

.text-black\/85 {

    color: rgb(44 44 44 / 0.85);
}

.text-black\/90 {

    color: rgb(44 44 44 / 0.9);
}

.text-black\/95 {

    color: rgb(44 44 44 / 0.95);
}

.text-blue {

    --tw-text-opacity: 1;

    color: rgb(111 233 255 / var(--tw-text-opacity));
}

.text-blue-60 {

    --tw-text-opacity: 1;

    color: rgb(209 249 255 / var(--tw-text-opacity));
}

.text-blue-60\/0 {

    color: rgb(209 249 255 / 0);
}

.text-blue-60\/10 {

    color: rgb(209 249 255 / 0.1);
}

.text-blue-60\/100 {

    color: rgb(209 249 255 / 1);
}

.text-blue-60\/15 {

    color: rgb(209 249 255 / 0.15);
}

.text-blue-60\/20 {

    color: rgb(209 249 255 / 0.2);
}

.text-blue-60\/25 {

    color: rgb(209 249 255 / 0.25);
}

.text-blue-60\/30 {

    color: rgb(209 249 255 / 0.3);
}

.text-blue-60\/35 {

    color: rgb(209 249 255 / 0.35);
}

.text-blue-60\/40 {

    color: rgb(209 249 255 / 0.4);
}

.text-blue-60\/45 {

    color: rgb(209 249 255 / 0.45);
}

.text-blue-60\/5 {

    color: rgb(209 249 255 / 0.05);
}

.text-blue-60\/50 {

    color: rgb(209 249 255 / 0.5);
}

.text-blue-60\/55 {

    color: rgb(209 249 255 / 0.55);
}

.text-blue-60\/60 {

    color: rgb(209 249 255 / 0.6);
}

.text-blue-60\/65 {

    color: rgb(209 249 255 / 0.65);
}

.text-blue-60\/70 {

    color: rgb(209 249 255 / 0.7);
}

.text-blue-60\/75 {

    color: rgb(209 249 255 / 0.75);
}

.text-blue-60\/80 {

    color: rgb(209 249 255 / 0.8);
}

.text-blue-60\/85 {

    color: rgb(209 249 255 / 0.85);
}

.text-blue-60\/90 {

    color: rgb(209 249 255 / 0.9);
}

.text-blue-60\/95 {

    color: rgb(209 249 255 / 0.95);
}

.text-blue-dark {

    --tw-text-opacity: 1;

    color: rgb(42 40 89 / var(--tw-text-opacity));
}

.text-blue-dark\/0 {

    color: rgb(42 40 89 / 0);
}

.text-blue-dark\/10 {

    color: rgb(42 40 89 / 0.1);
}

.text-blue-dark\/100 {

    color: rgb(42 40 89 / 1);
}

.text-blue-dark\/15 {

    color: rgb(42 40 89 / 0.15);
}

.text-blue-dark\/20 {

    color: rgb(42 40 89 / 0.2);
}

.text-blue-dark\/25 {

    color: rgb(42 40 89 / 0.25);
}

.text-blue-dark\/30 {

    color: rgb(42 40 89 / 0.3);
}

.text-blue-dark\/35 {

    color: rgb(42 40 89 / 0.35);
}

.text-blue-dark\/40 {

    color: rgb(42 40 89 / 0.4);
}

.text-blue-dark\/45 {

    color: rgb(42 40 89 / 0.45);
}

.text-blue-dark\/5 {

    color: rgb(42 40 89 / 0.05);
}

.text-blue-dark\/50 {

    color: rgb(42 40 89 / 0.5);
}

.text-blue-dark\/55 {

    color: rgb(42 40 89 / 0.55);
}

.text-blue-dark\/60 {

    color: rgb(42 40 89 / 0.6);
}

.text-blue-dark\/65 {

    color: rgb(42 40 89 / 0.65);
}

.text-blue-dark\/70 {

    color: rgb(42 40 89 / 0.7);
}

.text-blue-dark\/75 {

    color: rgb(42 40 89 / 0.75);
}

.text-blue-dark\/80 {

    color: rgb(42 40 89 / 0.8);
}

.text-blue-dark\/85 {

    color: rgb(42 40 89 / 0.85);
}

.text-blue-dark\/90 {

    color: rgb(42 40 89 / 0.9);
}

.text-blue-dark\/95 {

    color: rgb(42 40 89 / 0.95);
}

.text-blue-focus {

    --tw-text-opacity: 1;

    color: rgb(31 66 170 / var(--tw-text-opacity));
}

.text-blue-focus\/0 {

    color: rgb(31 66 170 / 0);
}

.text-blue-focus\/10 {

    color: rgb(31 66 170 / 0.1);
}

.text-blue-focus\/100 {

    color: rgb(31 66 170 / 1);
}

.text-blue-focus\/15 {

    color: rgb(31 66 170 / 0.15);
}

.text-blue-focus\/20 {

    color: rgb(31 66 170 / 0.2);
}

.text-blue-focus\/25 {

    color: rgb(31 66 170 / 0.25);
}

.text-blue-focus\/30 {

    color: rgb(31 66 170 / 0.3);
}

.text-blue-focus\/35 {

    color: rgb(31 66 170 / 0.35);
}

.text-blue-focus\/40 {

    color: rgb(31 66 170 / 0.4);
}

.text-blue-focus\/45 {

    color: rgb(31 66 170 / 0.45);
}

.text-blue-focus\/5 {

    color: rgb(31 66 170 / 0.05);
}

.text-blue-focus\/50 {

    color: rgb(31 66 170 / 0.5);
}

.text-blue-focus\/55 {

    color: rgb(31 66 170 / 0.55);
}

.text-blue-focus\/60 {

    color: rgb(31 66 170 / 0.6);
}

.text-blue-focus\/65 {

    color: rgb(31 66 170 / 0.65);
}

.text-blue-focus\/70 {

    color: rgb(31 66 170 / 0.7);
}

.text-blue-focus\/75 {

    color: rgb(31 66 170 / 0.75);
}

.text-blue-focus\/80 {

    color: rgb(31 66 170 / 0.8);
}

.text-blue-focus\/85 {

    color: rgb(31 66 170 / 0.85);
}

.text-blue-focus\/90 {

    color: rgb(31 66 170 / 0.9);
}

.text-blue-focus\/95 {

    color: rgb(31 66 170 / 0.95);
}

.text-blue-hover {

    --tw-text-opacity: 1;

    color: rgb(31 66 170 / var(--tw-text-opacity));
}

.text-blue-hover\/0 {

    color: rgb(31 66 170 / 0);
}

.text-blue-hover\/10 {

    color: rgb(31 66 170 / 0.1);
}

.text-blue-hover\/100 {

    color: rgb(31 66 170 / 1);
}

.text-blue-hover\/15 {

    color: rgb(31 66 170 / 0.15);
}

.text-blue-hover\/20 {

    color: rgb(31 66 170 / 0.2);
}

.text-blue-hover\/25 {

    color: rgb(31 66 170 / 0.25);
}

.text-blue-hover\/30 {

    color: rgb(31 66 170 / 0.3);
}

.text-blue-hover\/35 {

    color: rgb(31 66 170 / 0.35);
}

.text-blue-hover\/40 {

    color: rgb(31 66 170 / 0.4);
}

.text-blue-hover\/45 {

    color: rgb(31 66 170 / 0.45);
}

.text-blue-hover\/5 {

    color: rgb(31 66 170 / 0.05);
}

.text-blue-hover\/50 {

    color: rgb(31 66 170 / 0.5);
}

.text-blue-hover\/55 {

    color: rgb(31 66 170 / 0.55);
}

.text-blue-hover\/60 {

    color: rgb(31 66 170 / 0.6);
}

.text-blue-hover\/65 {

    color: rgb(31 66 170 / 0.65);
}

.text-blue-hover\/70 {

    color: rgb(31 66 170 / 0.7);
}

.text-blue-hover\/75 {

    color: rgb(31 66 170 / 0.75);
}

.text-blue-hover\/80 {

    color: rgb(31 66 170 / 0.8);
}

.text-blue-hover\/85 {

    color: rgb(31 66 170 / 0.85);
}

.text-blue-hover\/90 {

    color: rgb(31 66 170 / 0.9);
}

.text-blue-hover\/95 {

    color: rgb(31 66 170 / 0.95);
}

.text-blue-light {

    --tw-text-opacity: 1;

    color: rgb(179 245 255 / var(--tw-text-opacity));
}

.text-blue-light\/0 {

    color: rgb(179 245 255 / 0);
}

.text-blue-light\/10 {

    color: rgb(179 245 255 / 0.1);
}

.text-blue-light\/100 {

    color: rgb(179 245 255 / 1);
}

.text-blue-light\/15 {

    color: rgb(179 245 255 / 0.15);
}

.text-blue-light\/20 {

    color: rgb(179 245 255 / 0.2);
}

.text-blue-light\/25 {

    color: rgb(179 245 255 / 0.25);
}

.text-blue-light\/30 {

    color: rgb(179 245 255 / 0.3);
}

.text-blue-light\/35 {

    color: rgb(179 245 255 / 0.35);
}

.text-blue-light\/40 {

    color: rgb(179 245 255 / 0.4);
}

.text-blue-light\/45 {

    color: rgb(179 245 255 / 0.45);
}

.text-blue-light\/5 {

    color: rgb(179 245 255 / 0.05);
}

.text-blue-light\/50 {

    color: rgb(179 245 255 / 0.5);
}

.text-blue-light\/55 {

    color: rgb(179 245 255 / 0.55);
}

.text-blue-light\/60 {

    color: rgb(179 245 255 / 0.6);
}

.text-blue-light\/65 {

    color: rgb(179 245 255 / 0.65);
}

.text-blue-light\/70 {

    color: rgb(179 245 255 / 0.7);
}

.text-blue-light\/75 {

    color: rgb(179 245 255 / 0.75);
}

.text-blue-light\/80 {

    color: rgb(179 245 255 / 0.8);
}

.text-blue-light\/85 {

    color: rgb(179 245 255 / 0.85);
}

.text-blue-light\/90 {

    color: rgb(179 245 255 / 0.9);
}

.text-blue-light\/95 {

    color: rgb(179 245 255 / 0.95);
}

.text-blue\/0 {

    color: rgb(111 233 255 / 0);
}

.text-blue\/10 {

    color: rgb(111 233 255 / 0.1);
}

.text-blue\/100 {

    color: rgb(111 233 255 / 1);
}

.text-blue\/15 {

    color: rgb(111 233 255 / 0.15);
}

.text-blue\/20 {

    color: rgb(111 233 255 / 0.2);
}

.text-blue\/25 {

    color: rgb(111 233 255 / 0.25);
}

.text-blue\/30 {

    color: rgb(111 233 255 / 0.3);
}

.text-blue\/35 {

    color: rgb(111 233 255 / 0.35);
}

.text-blue\/40 {

    color: rgb(111 233 255 / 0.4);
}

.text-blue\/45 {

    color: rgb(111 233 255 / 0.45);
}

.text-blue\/5 {

    color: rgb(111 233 255 / 0.05);
}

.text-blue\/50 {

    color: rgb(111 233 255 / 0.5);
}

.text-blue\/55 {

    color: rgb(111 233 255 / 0.55);
}

.text-blue\/60 {

    color: rgb(111 233 255 / 0.6);
}

.text-blue\/65 {

    color: rgb(111 233 255 / 0.65);
}

.text-blue\/70 {

    color: rgb(111 233 255 / 0.7);
}

.text-blue\/75 {

    color: rgb(111 233 255 / 0.75);
}

.text-blue\/80 {

    color: rgb(111 233 255 / 0.8);
}

.text-blue\/85 {

    color: rgb(111 233 255 / 0.85);
}

.text-blue\/90 {

    color: rgb(111 233 255 / 0.9);
}

.text-blue\/95 {

    color: rgb(111 233 255 / 0.95);
}

.text-green {

    --tw-text-opacity: 1;

    color: rgb(67 248 182 / var(--tw-text-opacity));
}

.text-green-30 {

    --tw-text-opacity: 1;

    color: rgb(199 253 233 / var(--tw-text-opacity));
}

.text-green-30\/0 {

    color: rgb(199 253 233 / 0);
}

.text-green-30\/10 {

    color: rgb(199 253 233 / 0.1);
}

.text-green-30\/100 {

    color: rgb(199 253 233 / 1);
}

.text-green-30\/15 {

    color: rgb(199 253 233 / 0.15);
}

.text-green-30\/20 {

    color: rgb(199 253 233 / 0.2);
}

.text-green-30\/25 {

    color: rgb(199 253 233 / 0.25);
}

.text-green-30\/30 {

    color: rgb(199 253 233 / 0.3);
}

.text-green-30\/35 {

    color: rgb(199 253 233 / 0.35);
}

.text-green-30\/40 {

    color: rgb(199 253 233 / 0.4);
}

.text-green-30\/45 {

    color: rgb(199 253 233 / 0.45);
}

.text-green-30\/5 {

    color: rgb(199 253 233 / 0.05);
}

.text-green-30\/50 {

    color: rgb(199 253 233 / 0.5);
}

.text-green-30\/55 {

    color: rgb(199 253 233 / 0.55);
}

.text-green-30\/60 {

    color: rgb(199 253 233 / 0.6);
}

.text-green-30\/65 {

    color: rgb(199 253 233 / 0.65);
}

.text-green-30\/70 {

    color: rgb(199 253 233 / 0.7);
}

.text-green-30\/75 {

    color: rgb(199 253 233 / 0.75);
}

.text-green-30\/80 {

    color: rgb(199 253 233 / 0.8);
}

.text-green-30\/85 {

    color: rgb(199 253 233 / 0.85);
}

.text-green-30\/90 {

    color: rgb(199 253 233 / 0.9);
}

.text-green-30\/95 {

    color: rgb(199 253 233 / 0.95);
}

.text-green-dark {

    --tw-text-opacity: 1;

    color: rgb(3 75 69 / var(--tw-text-opacity));
}

.text-green-dark\/0 {

    color: rgb(3 75 69 / 0);
}

.text-green-dark\/10 {

    color: rgb(3 75 69 / 0.1);
}

.text-green-dark\/100 {

    color: rgb(3 75 69 / 1);
}

.text-green-dark\/15 {

    color: rgb(3 75 69 / 0.15);
}

.text-green-dark\/20 {

    color: rgb(3 75 69 / 0.2);
}

.text-green-dark\/25 {

    color: rgb(3 75 69 / 0.25);
}

.text-green-dark\/30 {

    color: rgb(3 75 69 / 0.3);
}

.text-green-dark\/35 {

    color: rgb(3 75 69 / 0.35);
}

.text-green-dark\/40 {

    color: rgb(3 75 69 / 0.4);
}

.text-green-dark\/45 {

    color: rgb(3 75 69 / 0.45);
}

.text-green-dark\/5 {

    color: rgb(3 75 69 / 0.05);
}

.text-green-dark\/50 {

    color: rgb(3 75 69 / 0.5);
}

.text-green-dark\/55 {

    color: rgb(3 75 69 / 0.55);
}

.text-green-dark\/60 {

    color: rgb(3 75 69 / 0.6);
}

.text-green-dark\/65 {

    color: rgb(3 75 69 / 0.65);
}

.text-green-dark\/70 {

    color: rgb(3 75 69 / 0.7);
}

.text-green-dark\/75 {

    color: rgb(3 75 69 / 0.75);
}

.text-green-dark\/80 {

    color: rgb(3 75 69 / 0.8);
}

.text-green-dark\/85 {

    color: rgb(3 75 69 / 0.85);
}

.text-green-dark\/90 {

    color: rgb(3 75 69 / 0.9);
}

.text-green-dark\/95 {

    color: rgb(3 75 69 / 0.95);
}

.text-green-focus {

    --tw-text-opacity: 1;

    color: rgb(31 66 170 / var(--tw-text-opacity));
}

.text-green-focus\/0 {

    color: rgb(31 66 170 / 0);
}

.text-green-focus\/10 {

    color: rgb(31 66 170 / 0.1);
}

.text-green-focus\/100 {

    color: rgb(31 66 170 / 1);
}

.text-green-focus\/15 {

    color: rgb(31 66 170 / 0.15);
}

.text-green-focus\/20 {

    color: rgb(31 66 170 / 0.2);
}

.text-green-focus\/25 {

    color: rgb(31 66 170 / 0.25);
}

.text-green-focus\/30 {

    color: rgb(31 66 170 / 0.3);
}

.text-green-focus\/35 {

    color: rgb(31 66 170 / 0.35);
}

.text-green-focus\/40 {

    color: rgb(31 66 170 / 0.4);
}

.text-green-focus\/45 {

    color: rgb(31 66 170 / 0.45);
}

.text-green-focus\/5 {

    color: rgb(31 66 170 / 0.05);
}

.text-green-focus\/50 {

    color: rgb(31 66 170 / 0.5);
}

.text-green-focus\/55 {

    color: rgb(31 66 170 / 0.55);
}

.text-green-focus\/60 {

    color: rgb(31 66 170 / 0.6);
}

.text-green-focus\/65 {

    color: rgb(31 66 170 / 0.65);
}

.text-green-focus\/70 {

    color: rgb(31 66 170 / 0.7);
}

.text-green-focus\/75 {

    color: rgb(31 66 170 / 0.75);
}

.text-green-focus\/80 {

    color: rgb(31 66 170 / 0.8);
}

.text-green-focus\/85 {

    color: rgb(31 66 170 / 0.85);
}

.text-green-focus\/90 {

    color: rgb(31 66 170 / 0.9);
}

.text-green-focus\/95 {

    color: rgb(31 66 170 / 0.95);
}

.text-green-hover {

    --tw-text-opacity: 1;

    color: rgb(31 66 170 / var(--tw-text-opacity));
}

.text-green-hover\/0 {

    color: rgb(31 66 170 / 0);
}

.text-green-hover\/10 {

    color: rgb(31 66 170 / 0.1);
}

.text-green-hover\/100 {

    color: rgb(31 66 170 / 1);
}

.text-green-hover\/15 {

    color: rgb(31 66 170 / 0.15);
}

.text-green-hover\/20 {

    color: rgb(31 66 170 / 0.2);
}

.text-green-hover\/25 {

    color: rgb(31 66 170 / 0.25);
}

.text-green-hover\/30 {

    color: rgb(31 66 170 / 0.3);
}

.text-green-hover\/35 {

    color: rgb(31 66 170 / 0.35);
}

.text-green-hover\/40 {

    color: rgb(31 66 170 / 0.4);
}

.text-green-hover\/45 {

    color: rgb(31 66 170 / 0.45);
}

.text-green-hover\/5 {

    color: rgb(31 66 170 / 0.05);
}

.text-green-hover\/50 {

    color: rgb(31 66 170 / 0.5);
}

.text-green-hover\/55 {

    color: rgb(31 66 170 / 0.55);
}

.text-green-hover\/60 {

    color: rgb(31 66 170 / 0.6);
}

.text-green-hover\/65 {

    color: rgb(31 66 170 / 0.65);
}

.text-green-hover\/70 {

    color: rgb(31 66 170 / 0.7);
}

.text-green-hover\/75 {

    color: rgb(31 66 170 / 0.75);
}

.text-green-hover\/80 {

    color: rgb(31 66 170 / 0.8);
}

.text-green-hover\/85 {

    color: rgb(31 66 170 / 0.85);
}

.text-green-hover\/90 {

    color: rgb(31 66 170 / 0.9);
}

.text-green-hover\/95 {

    color: rgb(31 66 170 / 0.95);
}

.text-green-light {

    --tw-text-opacity: 1;

    color: rgb(199 246 201 / var(--tw-text-opacity));
}

.text-green-light\/0 {

    color: rgb(199 246 201 / 0);
}

.text-green-light\/10 {

    color: rgb(199 246 201 / 0.1);
}

.text-green-light\/100 {

    color: rgb(199 246 201 / 1);
}

.text-green-light\/15 {

    color: rgb(199 246 201 / 0.15);
}

.text-green-light\/20 {

    color: rgb(199 246 201 / 0.2);
}

.text-green-light\/25 {

    color: rgb(199 246 201 / 0.25);
}

.text-green-light\/30 {

    color: rgb(199 246 201 / 0.3);
}

.text-green-light\/35 {

    color: rgb(199 246 201 / 0.35);
}

.text-green-light\/40 {

    color: rgb(199 246 201 / 0.4);
}

.text-green-light\/45 {

    color: rgb(199 246 201 / 0.45);
}

.text-green-light\/5 {

    color: rgb(199 246 201 / 0.05);
}

.text-green-light\/50 {

    color: rgb(199 246 201 / 0.5);
}

.text-green-light\/55 {

    color: rgb(199 246 201 / 0.55);
}

.text-green-light\/60 {

    color: rgb(199 246 201 / 0.6);
}

.text-green-light\/65 {

    color: rgb(199 246 201 / 0.65);
}

.text-green-light\/70 {

    color: rgb(199 246 201 / 0.7);
}

.text-green-light\/75 {

    color: rgb(199 246 201 / 0.75);
}

.text-green-light\/80 {

    color: rgb(199 246 201 / 0.8);
}

.text-green-light\/85 {

    color: rgb(199 246 201 / 0.85);
}

.text-green-light\/90 {

    color: rgb(199 246 201 / 0.9);
}

.text-green-light\/95 {

    color: rgb(199 246 201 / 0.95);
}

.text-green\/0 {

    color: rgb(67 248 182 / 0);
}

.text-green\/10 {

    color: rgb(67 248 182 / 0.1);
}

.text-green\/100 {

    color: rgb(67 248 182 / 1);
}

.text-green\/15 {

    color: rgb(67 248 182 / 0.15);
}

.text-green\/20 {

    color: rgb(67 248 182 / 0.2);
}

.text-green\/25 {

    color: rgb(67 248 182 / 0.25);
}

.text-green\/30 {

    color: rgb(67 248 182 / 0.3);
}

.text-green\/35 {

    color: rgb(67 248 182 / 0.35);
}

.text-green\/40 {

    color: rgb(67 248 182 / 0.4);
}

.text-green\/45 {

    color: rgb(67 248 182 / 0.45);
}

.text-green\/5 {

    color: rgb(67 248 182 / 0.05);
}

.text-green\/50 {

    color: rgb(67 248 182 / 0.5);
}

.text-green\/55 {

    color: rgb(67 248 182 / 0.55);
}

.text-green\/60 {

    color: rgb(67 248 182 / 0.6);
}

.text-green\/65 {

    color: rgb(67 248 182 / 0.65);
}

.text-green\/70 {

    color: rgb(67 248 182 / 0.7);
}

.text-green\/75 {

    color: rgb(67 248 182 / 0.75);
}

.text-green\/80 {

    color: rgb(67 248 182 / 0.8);
}

.text-green\/85 {

    color: rgb(67 248 182 / 0.85);
}

.text-green\/90 {

    color: rgb(67 248 182 / 0.9);
}

.text-green\/95 {

    color: rgb(67 248 182 / 0.95);
}

.text-grey {

    --tw-text-opacity: 1;

    color: rgb(242 242 242 / var(--tw-text-opacity));
}

.text-grey-10 {

    --tw-text-opacity: 1;

    color: rgb(230 230 230 / var(--tw-text-opacity));
}

.text-grey-10\/0 {

    color: rgb(230 230 230 / 0);
}

.text-grey-10\/10 {

    color: rgb(230 230 230 / 0.1);
}

.text-grey-10\/100 {

    color: rgb(230 230 230 / 1);
}

.text-grey-10\/15 {

    color: rgb(230 230 230 / 0.15);
}

.text-grey-10\/20 {

    color: rgb(230 230 230 / 0.2);
}

.text-grey-10\/25 {

    color: rgb(230 230 230 / 0.25);
}

.text-grey-10\/30 {

    color: rgb(230 230 230 / 0.3);
}

.text-grey-10\/35 {

    color: rgb(230 230 230 / 0.35);
}

.text-grey-10\/40 {

    color: rgb(230 230 230 / 0.4);
}

.text-grey-10\/45 {

    color: rgb(230 230 230 / 0.45);
}

.text-grey-10\/5 {

    color: rgb(230 230 230 / 0.05);
}

.text-grey-10\/50 {

    color: rgb(230 230 230 / 0.5);
}

.text-grey-10\/55 {

    color: rgb(230 230 230 / 0.55);
}

.text-grey-10\/60 {

    color: rgb(230 230 230 / 0.6);
}

.text-grey-10\/65 {

    color: rgb(230 230 230 / 0.65);
}

.text-grey-10\/70 {

    color: rgb(230 230 230 / 0.7);
}

.text-grey-10\/75 {

    color: rgb(230 230 230 / 0.75);
}

.text-grey-10\/80 {

    color: rgb(230 230 230 / 0.8);
}

.text-grey-10\/85 {

    color: rgb(230 230 230 / 0.85);
}

.text-grey-10\/90 {

    color: rgb(230 230 230 / 0.9);
}

.text-grey-10\/95 {

    color: rgb(230 230 230 / 0.95);
}

.text-grey-20 {

    --tw-text-opacity: 1;

    color: rgb(204 204 204 / var(--tw-text-opacity));
}

.text-grey-20\/0 {

    color: rgb(204 204 204 / 0);
}

.text-grey-20\/10 {

    color: rgb(204 204 204 / 0.1);
}

.text-grey-20\/100 {

    color: rgb(204 204 204 / 1);
}

.text-grey-20\/15 {

    color: rgb(204 204 204 / 0.15);
}

.text-grey-20\/20 {

    color: rgb(204 204 204 / 0.2);
}

.text-grey-20\/25 {

    color: rgb(204 204 204 / 0.25);
}

.text-grey-20\/30 {

    color: rgb(204 204 204 / 0.3);
}

.text-grey-20\/35 {

    color: rgb(204 204 204 / 0.35);
}

.text-grey-20\/40 {

    color: rgb(204 204 204 / 0.4);
}

.text-grey-20\/45 {

    color: rgb(204 204 204 / 0.45);
}

.text-grey-20\/5 {

    color: rgb(204 204 204 / 0.05);
}

.text-grey-20\/50 {

    color: rgb(204 204 204 / 0.5);
}

.text-grey-20\/55 {

    color: rgb(204 204 204 / 0.55);
}

.text-grey-20\/60 {

    color: rgb(204 204 204 / 0.6);
}

.text-grey-20\/65 {

    color: rgb(204 204 204 / 0.65);
}

.text-grey-20\/70 {

    color: rgb(204 204 204 / 0.7);
}

.text-grey-20\/75 {

    color: rgb(204 204 204 / 0.75);
}

.text-grey-20\/80 {

    color: rgb(204 204 204 / 0.8);
}

.text-grey-20\/85 {

    color: rgb(204 204 204 / 0.85);
}

.text-grey-20\/90 {

    color: rgb(204 204 204 / 0.9);
}

.text-grey-20\/95 {

    color: rgb(204 204 204 / 0.95);
}

.text-grey-30 {

    --tw-text-opacity: 1;

    color: rgb(179 179 179 / var(--tw-text-opacity));
}

.text-grey-30\/0 {

    color: rgb(179 179 179 / 0);
}

.text-grey-30\/10 {

    color: rgb(179 179 179 / 0.1);
}

.text-grey-30\/100 {

    color: rgb(179 179 179 / 1);
}

.text-grey-30\/15 {

    color: rgb(179 179 179 / 0.15);
}

.text-grey-30\/20 {

    color: rgb(179 179 179 / 0.2);
}

.text-grey-30\/25 {

    color: rgb(179 179 179 / 0.25);
}

.text-grey-30\/30 {

    color: rgb(179 179 179 / 0.3);
}

.text-grey-30\/35 {

    color: rgb(179 179 179 / 0.35);
}

.text-grey-30\/40 {

    color: rgb(179 179 179 / 0.4);
}

.text-grey-30\/45 {

    color: rgb(179 179 179 / 0.45);
}

.text-grey-30\/5 {

    color: rgb(179 179 179 / 0.05);
}

.text-grey-30\/50 {

    color: rgb(179 179 179 / 0.5);
}

.text-grey-30\/55 {

    color: rgb(179 179 179 / 0.55);
}

.text-grey-30\/60 {

    color: rgb(179 179 179 / 0.6);
}

.text-grey-30\/65 {

    color: rgb(179 179 179 / 0.65);
}

.text-grey-30\/70 {

    color: rgb(179 179 179 / 0.7);
}

.text-grey-30\/75 {

    color: rgb(179 179 179 / 0.75);
}

.text-grey-30\/80 {

    color: rgb(179 179 179 / 0.8);
}

.text-grey-30\/85 {

    color: rgb(179 179 179 / 0.85);
}

.text-grey-30\/90 {

    color: rgb(179 179 179 / 0.9);
}

.text-grey-30\/95 {

    color: rgb(179 179 179 / 0.95);
}

.text-grey-40 {

    --tw-text-opacity: 1;

    color: rgb(153 153 153 / var(--tw-text-opacity));
}

.text-grey-40\/0 {

    color: rgb(153 153 153 / 0);
}

.text-grey-40\/10 {

    color: rgb(153 153 153 / 0.1);
}

.text-grey-40\/100 {

    color: rgb(153 153 153 / 1);
}

.text-grey-40\/15 {

    color: rgb(153 153 153 / 0.15);
}

.text-grey-40\/20 {

    color: rgb(153 153 153 / 0.2);
}

.text-grey-40\/25 {

    color: rgb(153 153 153 / 0.25);
}

.text-grey-40\/30 {

    color: rgb(153 153 153 / 0.3);
}

.text-grey-40\/35 {

    color: rgb(153 153 153 / 0.35);
}

.text-grey-40\/40 {

    color: rgb(153 153 153 / 0.4);
}

.text-grey-40\/45 {

    color: rgb(153 153 153 / 0.45);
}

.text-grey-40\/5 {

    color: rgb(153 153 153 / 0.05);
}

.text-grey-40\/50 {

    color: rgb(153 153 153 / 0.5);
}

.text-grey-40\/55 {

    color: rgb(153 153 153 / 0.55);
}

.text-grey-40\/60 {

    color: rgb(153 153 153 / 0.6);
}

.text-grey-40\/65 {

    color: rgb(153 153 153 / 0.65);
}

.text-grey-40\/70 {

    color: rgb(153 153 153 / 0.7);
}

.text-grey-40\/75 {

    color: rgb(153 153 153 / 0.75);
}

.text-grey-40\/80 {

    color: rgb(153 153 153 / 0.8);
}

.text-grey-40\/85 {

    color: rgb(153 153 153 / 0.85);
}

.text-grey-40\/90 {

    color: rgb(153 153 153 / 0.9);
}

.text-grey-40\/95 {

    color: rgb(153 153 153 / 0.95);
}

.text-grey-50 {

    --tw-text-opacity: 1;

    color: rgb(128 128 128 / var(--tw-text-opacity));
}

.text-grey-50\/0 {

    color: rgb(128 128 128 / 0);
}

.text-grey-50\/10 {

    color: rgb(128 128 128 / 0.1);
}

.text-grey-50\/100 {

    color: rgb(128 128 128 / 1);
}

.text-grey-50\/15 {

    color: rgb(128 128 128 / 0.15);
}

.text-grey-50\/20 {

    color: rgb(128 128 128 / 0.2);
}

.text-grey-50\/25 {

    color: rgb(128 128 128 / 0.25);
}

.text-grey-50\/30 {

    color: rgb(128 128 128 / 0.3);
}

.text-grey-50\/35 {

    color: rgb(128 128 128 / 0.35);
}

.text-grey-50\/40 {

    color: rgb(128 128 128 / 0.4);
}

.text-grey-50\/45 {

    color: rgb(128 128 128 / 0.45);
}

.text-grey-50\/5 {

    color: rgb(128 128 128 / 0.05);
}

.text-grey-50\/50 {

    color: rgb(128 128 128 / 0.5);
}

.text-grey-50\/55 {

    color: rgb(128 128 128 / 0.55);
}

.text-grey-50\/60 {

    color: rgb(128 128 128 / 0.6);
}

.text-grey-50\/65 {

    color: rgb(128 128 128 / 0.65);
}

.text-grey-50\/70 {

    color: rgb(128 128 128 / 0.7);
}

.text-grey-50\/75 {

    color: rgb(128 128 128 / 0.75);
}

.text-grey-50\/80 {

    color: rgb(128 128 128 / 0.8);
}

.text-grey-50\/85 {

    color: rgb(128 128 128 / 0.85);
}

.text-grey-50\/90 {

    color: rgb(128 128 128 / 0.9);
}

.text-grey-50\/95 {

    color: rgb(128 128 128 / 0.95);
}

.text-grey-60 {

    --tw-text-opacity: 1;

    color: rgb(102 102 102 / var(--tw-text-opacity));
}

.text-grey-60\/0 {

    color: rgb(102 102 102 / 0);
}

.text-grey-60\/10 {

    color: rgb(102 102 102 / 0.1);
}

.text-grey-60\/100 {

    color: rgb(102 102 102 / 1);
}

.text-grey-60\/15 {

    color: rgb(102 102 102 / 0.15);
}

.text-grey-60\/20 {

    color: rgb(102 102 102 / 0.2);
}

.text-grey-60\/25 {

    color: rgb(102 102 102 / 0.25);
}

.text-grey-60\/30 {

    color: rgb(102 102 102 / 0.3);
}

.text-grey-60\/35 {

    color: rgb(102 102 102 / 0.35);
}

.text-grey-60\/40 {

    color: rgb(102 102 102 / 0.4);
}

.text-grey-60\/45 {

    color: rgb(102 102 102 / 0.45);
}

.text-grey-60\/5 {

    color: rgb(102 102 102 / 0.05);
}

.text-grey-60\/50 {

    color: rgb(102 102 102 / 0.5);
}

.text-grey-60\/55 {

    color: rgb(102 102 102 / 0.55);
}

.text-grey-60\/60 {

    color: rgb(102 102 102 / 0.6);
}

.text-grey-60\/65 {

    color: rgb(102 102 102 / 0.65);
}

.text-grey-60\/70 {

    color: rgb(102 102 102 / 0.7);
}

.text-grey-60\/75 {

    color: rgb(102 102 102 / 0.75);
}

.text-grey-60\/80 {

    color: rgb(102 102 102 / 0.8);
}

.text-grey-60\/85 {

    color: rgb(102 102 102 / 0.85);
}

.text-grey-60\/90 {

    color: rgb(102 102 102 / 0.9);
}

.text-grey-60\/95 {

    color: rgb(102 102 102 / 0.95);
}

.text-grey-70 {

    --tw-text-opacity: 1;

    color: rgb(77 77 77 / var(--tw-text-opacity));
}

.text-grey-70\/0 {

    color: rgb(77 77 77 / 0);
}

.text-grey-70\/10 {

    color: rgb(77 77 77 / 0.1);
}

.text-grey-70\/100 {

    color: rgb(77 77 77 / 1);
}

.text-grey-70\/15 {

    color: rgb(77 77 77 / 0.15);
}

.text-grey-70\/20 {

    color: rgb(77 77 77 / 0.2);
}

.text-grey-70\/25 {

    color: rgb(77 77 77 / 0.25);
}

.text-grey-70\/30 {

    color: rgb(77 77 77 / 0.3);
}

.text-grey-70\/35 {

    color: rgb(77 77 77 / 0.35);
}

.text-grey-70\/40 {

    color: rgb(77 77 77 / 0.4);
}

.text-grey-70\/45 {

    color: rgb(77 77 77 / 0.45);
}

.text-grey-70\/5 {

    color: rgb(77 77 77 / 0.05);
}

.text-grey-70\/50 {

    color: rgb(77 77 77 / 0.5);
}

.text-grey-70\/55 {

    color: rgb(77 77 77 / 0.55);
}

.text-grey-70\/60 {

    color: rgb(77 77 77 / 0.6);
}

.text-grey-70\/65 {

    color: rgb(77 77 77 / 0.65);
}

.text-grey-70\/70 {

    color: rgb(77 77 77 / 0.7);
}

.text-grey-70\/75 {

    color: rgb(77 77 77 / 0.75);
}

.text-grey-70\/80 {

    color: rgb(77 77 77 / 0.8);
}

.text-grey-70\/85 {

    color: rgb(77 77 77 / 0.85);
}

.text-grey-70\/90 {

    color: rgb(77 77 77 / 0.9);
}

.text-grey-70\/95 {

    color: rgb(77 77 77 / 0.95);
}

.text-grey-80 {

    --tw-text-opacity: 1;

    color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-grey-80\/0 {

    color: rgb(51 51 51 / 0);
}

.text-grey-80\/10 {

    color: rgb(51 51 51 / 0.1);
}

.text-grey-80\/100 {

    color: rgb(51 51 51 / 1);
}

.text-grey-80\/15 {

    color: rgb(51 51 51 / 0.15);
}

.text-grey-80\/20 {

    color: rgb(51 51 51 / 0.2);
}

.text-grey-80\/25 {

    color: rgb(51 51 51 / 0.25);
}

.text-grey-80\/30 {

    color: rgb(51 51 51 / 0.3);
}

.text-grey-80\/35 {

    color: rgb(51 51 51 / 0.35);
}

.text-grey-80\/40 {

    color: rgb(51 51 51 / 0.4);
}

.text-grey-80\/45 {

    color: rgb(51 51 51 / 0.45);
}

.text-grey-80\/5 {

    color: rgb(51 51 51 / 0.05);
}

.text-grey-80\/50 {

    color: rgb(51 51 51 / 0.5);
}

.text-grey-80\/55 {

    color: rgb(51 51 51 / 0.55);
}

.text-grey-80\/60 {

    color: rgb(51 51 51 / 0.6);
}

.text-grey-80\/65 {

    color: rgb(51 51 51 / 0.65);
}

.text-grey-80\/70 {

    color: rgb(51 51 51 / 0.7);
}

.text-grey-80\/75 {

    color: rgb(51 51 51 / 0.75);
}

.text-grey-80\/80 {

    color: rgb(51 51 51 / 0.8);
}

.text-grey-80\/85 {

    color: rgb(51 51 51 / 0.85);
}

.text-grey-80\/90 {

    color: rgb(51 51 51 / 0.9);
}

.text-grey-80\/95 {

    color: rgb(51 51 51 / 0.95);
}

.text-grey-90 {

    --tw-text-opacity: 1;

    color: rgb(26 26 26 / var(--tw-text-opacity));
}

.text-grey-90\/0 {

    color: rgb(26 26 26 / 0);
}

.text-grey-90\/10 {

    color: rgb(26 26 26 / 0.1);
}

.text-grey-90\/100 {

    color: rgb(26 26 26 / 1);
}

.text-grey-90\/15 {

    color: rgb(26 26 26 / 0.15);
}

.text-grey-90\/20 {

    color: rgb(26 26 26 / 0.2);
}

.text-grey-90\/25 {

    color: rgb(26 26 26 / 0.25);
}

.text-grey-90\/30 {

    color: rgb(26 26 26 / 0.3);
}

.text-grey-90\/35 {

    color: rgb(26 26 26 / 0.35);
}

.text-grey-90\/40 {

    color: rgb(26 26 26 / 0.4);
}

.text-grey-90\/45 {

    color: rgb(26 26 26 / 0.45);
}

.text-grey-90\/5 {

    color: rgb(26 26 26 / 0.05);
}

.text-grey-90\/50 {

    color: rgb(26 26 26 / 0.5);
}

.text-grey-90\/55 {

    color: rgb(26 26 26 / 0.55);
}

.text-grey-90\/60 {

    color: rgb(26 26 26 / 0.6);
}

.text-grey-90\/65 {

    color: rgb(26 26 26 / 0.65);
}

.text-grey-90\/70 {

    color: rgb(26 26 26 / 0.7);
}

.text-grey-90\/75 {

    color: rgb(26 26 26 / 0.75);
}

.text-grey-90\/80 {

    color: rgb(26 26 26 / 0.8);
}

.text-grey-90\/85 {

    color: rgb(26 26 26 / 0.85);
}

.text-grey-90\/90 {

    color: rgb(26 26 26 / 0.9);
}

.text-grey-90\/95 {

    color: rgb(26 26 26 / 0.95);
}

.text-grey-dark {

    --tw-text-opacity: 1;

    color: rgb(225 225 225 / var(--tw-text-opacity));
}

.text-grey-dark\/0 {

    color: rgb(225 225 225 / 0);
}

.text-grey-dark\/10 {

    color: rgb(225 225 225 / 0.1);
}

.text-grey-dark\/100 {

    color: rgb(225 225 225 / 1);
}

.text-grey-dark\/15 {

    color: rgb(225 225 225 / 0.15);
}

.text-grey-dark\/20 {

    color: rgb(225 225 225 / 0.2);
}

.text-grey-dark\/25 {

    color: rgb(225 225 225 / 0.25);
}

.text-grey-dark\/30 {

    color: rgb(225 225 225 / 0.3);
}

.text-grey-dark\/35 {

    color: rgb(225 225 225 / 0.35);
}

.text-grey-dark\/40 {

    color: rgb(225 225 225 / 0.4);
}

.text-grey-dark\/45 {

    color: rgb(225 225 225 / 0.45);
}

.text-grey-dark\/5 {

    color: rgb(225 225 225 / 0.05);
}

.text-grey-dark\/50 {

    color: rgb(225 225 225 / 0.5);
}

.text-grey-dark\/55 {

    color: rgb(225 225 225 / 0.55);
}

.text-grey-dark\/60 {

    color: rgb(225 225 225 / 0.6);
}

.text-grey-dark\/65 {

    color: rgb(225 225 225 / 0.65);
}

.text-grey-dark\/70 {

    color: rgb(225 225 225 / 0.7);
}

.text-grey-dark\/75 {

    color: rgb(225 225 225 / 0.75);
}

.text-grey-dark\/80 {

    color: rgb(225 225 225 / 0.8);
}

.text-grey-dark\/85 {

    color: rgb(225 225 225 / 0.85);
}

.text-grey-dark\/90 {

    color: rgb(225 225 225 / 0.9);
}

.text-grey-dark\/95 {

    color: rgb(225 225 225 / 0.95);
}

.text-grey-light {

    --tw-text-opacity: 1;

    color: rgb(249 249 249 / var(--tw-text-opacity));
}

.text-grey-light\/0 {

    color: rgb(249 249 249 / 0);
}

.text-grey-light\/10 {

    color: rgb(249 249 249 / 0.1);
}

.text-grey-light\/100 {

    color: rgb(249 249 249 / 1);
}

.text-grey-light\/15 {

    color: rgb(249 249 249 / 0.15);
}

.text-grey-light\/20 {

    color: rgb(249 249 249 / 0.2);
}

.text-grey-light\/25 {

    color: rgb(249 249 249 / 0.25);
}

.text-grey-light\/30 {

    color: rgb(249 249 249 / 0.3);
}

.text-grey-light\/35 {

    color: rgb(249 249 249 / 0.35);
}

.text-grey-light\/40 {

    color: rgb(249 249 249 / 0.4);
}

.text-grey-light\/45 {

    color: rgb(249 249 249 / 0.45);
}

.text-grey-light\/5 {

    color: rgb(249 249 249 / 0.05);
}

.text-grey-light\/50 {

    color: rgb(249 249 249 / 0.5);
}

.text-grey-light\/55 {

    color: rgb(249 249 249 / 0.55);
}

.text-grey-light\/60 {

    color: rgb(249 249 249 / 0.6);
}

.text-grey-light\/65 {

    color: rgb(249 249 249 / 0.65);
}

.text-grey-light\/70 {

    color: rgb(249 249 249 / 0.7);
}

.text-grey-light\/75 {

    color: rgb(249 249 249 / 0.75);
}

.text-grey-light\/80 {

    color: rgb(249 249 249 / 0.8);
}

.text-grey-light\/85 {

    color: rgb(249 249 249 / 0.85);
}

.text-grey-light\/90 {

    color: rgb(249 249 249 / 0.9);
}

.text-grey-light\/95 {

    color: rgb(249 249 249 / 0.95);
}

.text-grey\/0 {

    color: rgb(242 242 242 / 0);
}

.text-grey\/10 {

    color: rgb(242 242 242 / 0.1);
}

.text-grey\/100 {

    color: rgb(242 242 242 / 1);
}

.text-grey\/15 {

    color: rgb(242 242 242 / 0.15);
}

.text-grey\/20 {

    color: rgb(242 242 242 / 0.2);
}

.text-grey\/25 {

    color: rgb(242 242 242 / 0.25);
}

.text-grey\/30 {

    color: rgb(242 242 242 / 0.3);
}

.text-grey\/35 {

    color: rgb(242 242 242 / 0.35);
}

.text-grey\/40 {

    color: rgb(242 242 242 / 0.4);
}

.text-grey\/45 {

    color: rgb(242 242 242 / 0.45);
}

.text-grey\/5 {

    color: rgb(242 242 242 / 0.05);
}

.text-grey\/50 {

    color: rgb(242 242 242 / 0.5);
}

.text-grey\/55 {

    color: rgb(242 242 242 / 0.55);
}

.text-grey\/60 {

    color: rgb(242 242 242 / 0.6);
}

.text-grey\/65 {

    color: rgb(242 242 242 / 0.65);
}

.text-grey\/70 {

    color: rgb(242 242 242 / 0.7);
}

.text-grey\/75 {

    color: rgb(242 242 242 / 0.75);
}

.text-grey\/80 {

    color: rgb(242 242 242 / 0.8);
}

.text-grey\/85 {

    color: rgb(242 242 242 / 0.85);
}

.text-grey\/90 {

    color: rgb(242 242 242 / 0.9);
}

.text-grey\/95 {

    color: rgb(242 242 242 / 0.95);
}

.text-purple-visited {

    --tw-text-opacity: 1;

    color: rgb(141 80 177 / var(--tw-text-opacity));
}

.text-purple-visited-light {

    --tw-text-opacity: 1;

    color: rgb(224 173 255 / var(--tw-text-opacity));
}

.text-purple-visited-light\/0 {

    color: rgb(224 173 255 / 0);
}

.text-purple-visited-light\/10 {

    color: rgb(224 173 255 / 0.1);
}

.text-purple-visited-light\/100 {

    color: rgb(224 173 255 / 1);
}

.text-purple-visited-light\/15 {

    color: rgb(224 173 255 / 0.15);
}

.text-purple-visited-light\/20 {

    color: rgb(224 173 255 / 0.2);
}

.text-purple-visited-light\/25 {

    color: rgb(224 173 255 / 0.25);
}

.text-purple-visited-light\/30 {

    color: rgb(224 173 255 / 0.3);
}

.text-purple-visited-light\/35 {

    color: rgb(224 173 255 / 0.35);
}

.text-purple-visited-light\/40 {

    color: rgb(224 173 255 / 0.4);
}

.text-purple-visited-light\/45 {

    color: rgb(224 173 255 / 0.45);
}

.text-purple-visited-light\/5 {

    color: rgb(224 173 255 / 0.05);
}

.text-purple-visited-light\/50 {

    color: rgb(224 173 255 / 0.5);
}

.text-purple-visited-light\/55 {

    color: rgb(224 173 255 / 0.55);
}

.text-purple-visited-light\/60 {

    color: rgb(224 173 255 / 0.6);
}

.text-purple-visited-light\/65 {

    color: rgb(224 173 255 / 0.65);
}

.text-purple-visited-light\/70 {

    color: rgb(224 173 255 / 0.7);
}

.text-purple-visited-light\/75 {

    color: rgb(224 173 255 / 0.75);
}

.text-purple-visited-light\/80 {

    color: rgb(224 173 255 / 0.8);
}

.text-purple-visited-light\/85 {

    color: rgb(224 173 255 / 0.85);
}

.text-purple-visited-light\/90 {

    color: rgb(224 173 255 / 0.9);
}

.text-purple-visited-light\/95 {

    color: rgb(224 173 255 / 0.95);
}

.text-purple-visited\/0 {

    color: rgb(141 80 177 / 0);
}

.text-purple-visited\/10 {

    color: rgb(141 80 177 / 0.1);
}

.text-purple-visited\/100 {

    color: rgb(141 80 177 / 1);
}

.text-purple-visited\/15 {

    color: rgb(141 80 177 / 0.15);
}

.text-purple-visited\/20 {

    color: rgb(141 80 177 / 0.2);
}

.text-purple-visited\/25 {

    color: rgb(141 80 177 / 0.25);
}

.text-purple-visited\/30 {

    color: rgb(141 80 177 / 0.3);
}

.text-purple-visited\/35 {

    color: rgb(141 80 177 / 0.35);
}

.text-purple-visited\/40 {

    color: rgb(141 80 177 / 0.4);
}

.text-purple-visited\/45 {

    color: rgb(141 80 177 / 0.45);
}

.text-purple-visited\/5 {

    color: rgb(141 80 177 / 0.05);
}

.text-purple-visited\/50 {

    color: rgb(141 80 177 / 0.5);
}

.text-purple-visited\/55 {

    color: rgb(141 80 177 / 0.55);
}

.text-purple-visited\/60 {

    color: rgb(141 80 177 / 0.6);
}

.text-purple-visited\/65 {

    color: rgb(141 80 177 / 0.65);
}

.text-purple-visited\/70 {

    color: rgb(141 80 177 / 0.7);
}

.text-purple-visited\/75 {

    color: rgb(141 80 177 / 0.75);
}

.text-purple-visited\/80 {

    color: rgb(141 80 177 / 0.8);
}

.text-purple-visited\/85 {

    color: rgb(141 80 177 / 0.85);
}

.text-purple-visited\/90 {

    color: rgb(141 80 177 / 0.9);
}

.text-purple-visited\/95 {

    color: rgb(141 80 177 / 0.95);
}

.text-red {

    --tw-text-opacity: 1;

    color: rgb(255 130 116 / var(--tw-text-opacity));
}

.text-red-30 {

    --tw-text-opacity: 1;

    color: rgb(255 223 220 / var(--tw-text-opacity));
}

.text-red-30\/0 {

    color: rgb(255 223 220 / 0);
}

.text-red-30\/10 {

    color: rgb(255 223 220 / 0.1);
}

.text-red-30\/100 {

    color: rgb(255 223 220 / 1);
}

.text-red-30\/15 {

    color: rgb(255 223 220 / 0.15);
}

.text-red-30\/20 {

    color: rgb(255 223 220 / 0.2);
}

.text-red-30\/25 {

    color: rgb(255 223 220 / 0.25);
}

.text-red-30\/30 {

    color: rgb(255 223 220 / 0.3);
}

.text-red-30\/35 {

    color: rgb(255 223 220 / 0.35);
}

.text-red-30\/40 {

    color: rgb(255 223 220 / 0.4);
}

.text-red-30\/45 {

    color: rgb(255 223 220 / 0.45);
}

.text-red-30\/5 {

    color: rgb(255 223 220 / 0.05);
}

.text-red-30\/50 {

    color: rgb(255 223 220 / 0.5);
}

.text-red-30\/55 {

    color: rgb(255 223 220 / 0.55);
}

.text-red-30\/60 {

    color: rgb(255 223 220 / 0.6);
}

.text-red-30\/65 {

    color: rgb(255 223 220 / 0.65);
}

.text-red-30\/70 {

    color: rgb(255 223 220 / 0.7);
}

.text-red-30\/75 {

    color: rgb(255 223 220 / 0.75);
}

.text-red-30\/80 {

    color: rgb(255 223 220 / 0.8);
}

.text-red-30\/85 {

    color: rgb(255 223 220 / 0.85);
}

.text-red-30\/90 {

    color: rgb(255 223 220 / 0.9);
}

.text-red-30\/95 {

    color: rgb(255 223 220 / 0.95);
}

.text-red\/0 {

    color: rgb(255 130 116 / 0);
}

.text-red\/10 {

    color: rgb(255 130 116 / 0.1);
}

.text-red\/100 {

    color: rgb(255 130 116 / 1);
}

.text-red\/15 {

    color: rgb(255 130 116 / 0.15);
}

.text-red\/20 {

    color: rgb(255 130 116 / 0.2);
}

.text-red\/25 {

    color: rgb(255 130 116 / 0.25);
}

.text-red\/30 {

    color: rgb(255 130 116 / 0.3);
}

.text-red\/35 {

    color: rgb(255 130 116 / 0.35);
}

.text-red\/40 {

    color: rgb(255 130 116 / 0.4);
}

.text-red\/45 {

    color: rgb(255 130 116 / 0.45);
}

.text-red\/5 {

    color: rgb(255 130 116 / 0.05);
}

.text-red\/50 {

    color: rgb(255 130 116 / 0.5);
}

.text-red\/55 {

    color: rgb(255 130 116 / 0.55);
}

.text-red\/60 {

    color: rgb(255 130 116 / 0.6);
}

.text-red\/65 {

    color: rgb(255 130 116 / 0.65);
}

.text-red\/70 {

    color: rgb(255 130 116 / 0.7);
}

.text-red\/75 {

    color: rgb(255 130 116 / 0.75);
}

.text-red\/80 {

    color: rgb(255 130 116 / 0.8);
}

.text-red\/85 {

    color: rgb(255 130 116 / 0.85);
}

.text-red\/90 {

    color: rgb(255 130 116 / 0.9);
}

.text-red\/95 {

    color: rgb(255 130 116 / 0.95);
}

.text-state-danger {

    color: #FF8274B3;
}

.text-state-danger\/0 {

    color: rgb(255 130 116 / 0);
}

.text-state-danger\/10 {

    color: rgb(255 130 116 / 0.1);
}

.text-state-danger\/100 {

    color: rgb(255 130 116 / 1);
}

.text-state-danger\/15 {

    color: rgb(255 130 116 / 0.15);
}

.text-state-danger\/20 {

    color: rgb(255 130 116 / 0.2);
}

.text-state-danger\/25 {

    color: rgb(255 130 116 / 0.25);
}

.text-state-danger\/30 {

    color: rgb(255 130 116 / 0.3);
}

.text-state-danger\/35 {

    color: rgb(255 130 116 / 0.35);
}

.text-state-danger\/40 {

    color: rgb(255 130 116 / 0.4);
}

.text-state-danger\/45 {

    color: rgb(255 130 116 / 0.45);
}

.text-state-danger\/5 {

    color: rgb(255 130 116 / 0.05);
}

.text-state-danger\/50 {

    color: rgb(255 130 116 / 0.5);
}

.text-state-danger\/55 {

    color: rgb(255 130 116 / 0.55);
}

.text-state-danger\/60 {

    color: rgb(255 130 116 / 0.6);
}

.text-state-danger\/65 {

    color: rgb(255 130 116 / 0.65);
}

.text-state-danger\/70 {

    color: rgb(255 130 116 / 0.7);
}

.text-state-danger\/75 {

    color: rgb(255 130 116 / 0.75);
}

.text-state-danger\/80 {

    color: rgb(255 130 116 / 0.8);
}

.text-state-danger\/85 {

    color: rgb(255 130 116 / 0.85);
}

.text-state-danger\/90 {

    color: rgb(255 130 116 / 0.9);
}

.text-state-danger\/95 {

    color: rgb(255 130 116 / 0.95);
}

.text-state-success {

    color: #C7F6C9B3;
}

.text-state-success\/0 {

    color: rgb(199 246 201 / 0);
}

.text-state-success\/10 {

    color: rgb(199 246 201 / 0.1);
}

.text-state-success\/100 {

    color: rgb(199 246 201 / 1);
}

.text-state-success\/15 {

    color: rgb(199 246 201 / 0.15);
}

.text-state-success\/20 {

    color: rgb(199 246 201 / 0.2);
}

.text-state-success\/25 {

    color: rgb(199 246 201 / 0.25);
}

.text-state-success\/30 {

    color: rgb(199 246 201 / 0.3);
}

.text-state-success\/35 {

    color: rgb(199 246 201 / 0.35);
}

.text-state-success\/40 {

    color: rgb(199 246 201 / 0.4);
}

.text-state-success\/45 {

    color: rgb(199 246 201 / 0.45);
}

.text-state-success\/5 {

    color: rgb(199 246 201 / 0.05);
}

.text-state-success\/50 {

    color: rgb(199 246 201 / 0.5);
}

.text-state-success\/55 {

    color: rgb(199 246 201 / 0.55);
}

.text-state-success\/60 {

    color: rgb(199 246 201 / 0.6);
}

.text-state-success\/65 {

    color: rgb(199 246 201 / 0.65);
}

.text-state-success\/70 {

    color: rgb(199 246 201 / 0.7);
}

.text-state-success\/75 {

    color: rgb(199 246 201 / 0.75);
}

.text-state-success\/80 {

    color: rgb(199 246 201 / 0.8);
}

.text-state-success\/85 {

    color: rgb(199 246 201 / 0.85);
}

.text-state-success\/90 {

    color: rgb(199 246 201 / 0.9);
}

.text-state-success\/95 {

    color: rgb(199 246 201 / 0.95);
}

.text-state-warning {

    color: #F9C66BB3;
}

.text-state-warning\/0 {

    color: rgb(249 198 107 / 0);
}

.text-state-warning\/10 {

    color: rgb(249 198 107 / 0.1);
}

.text-state-warning\/100 {

    color: rgb(249 198 107 / 1);
}

.text-state-warning\/15 {

    color: rgb(249 198 107 / 0.15);
}

.text-state-warning\/20 {

    color: rgb(249 198 107 / 0.2);
}

.text-state-warning\/25 {

    color: rgb(249 198 107 / 0.25);
}

.text-state-warning\/30 {

    color: rgb(249 198 107 / 0.3);
}

.text-state-warning\/35 {

    color: rgb(249 198 107 / 0.35);
}

.text-state-warning\/40 {

    color: rgb(249 198 107 / 0.4);
}

.text-state-warning\/45 {

    color: rgb(249 198 107 / 0.45);
}

.text-state-warning\/5 {

    color: rgb(249 198 107 / 0.05);
}

.text-state-warning\/50 {

    color: rgb(249 198 107 / 0.5);
}

.text-state-warning\/55 {

    color: rgb(249 198 107 / 0.55);
}

.text-state-warning\/60 {

    color: rgb(249 198 107 / 0.6);
}

.text-state-warning\/65 {

    color: rgb(249 198 107 / 0.65);
}

.text-state-warning\/70 {

    color: rgb(249 198 107 / 0.7);
}

.text-state-warning\/75 {

    color: rgb(249 198 107 / 0.75);
}

.text-state-warning\/80 {

    color: rgb(249 198 107 / 0.8);
}

.text-state-warning\/85 {

    color: rgb(249 198 107 / 0.85);
}

.text-state-warning\/90 {

    color: rgb(249 198 107 / 0.9);
}

.text-state-warning\/95 {

    color: rgb(249 198 107 / 0.95);
}

.text-transparent {

    color: transparent;
}

.text-transparent\/0 {

    color: rgb(0 0 0 / 0);
}

.text-transparent\/10 {

    color: rgb(0 0 0 / 0.1);
}

.text-transparent\/100 {

    color: rgb(0 0 0 / 1);
}

.text-transparent\/15 {

    color: rgb(0 0 0 / 0.15);
}

.text-transparent\/20 {

    color: rgb(0 0 0 / 0.2);
}

.text-transparent\/25 {

    color: rgb(0 0 0 / 0.25);
}

.text-transparent\/30 {

    color: rgb(0 0 0 / 0.3);
}

.text-transparent\/35 {

    color: rgb(0 0 0 / 0.35);
}

.text-transparent\/40 {

    color: rgb(0 0 0 / 0.4);
}

.text-transparent\/45 {

    color: rgb(0 0 0 / 0.45);
}

.text-transparent\/5 {

    color: rgb(0 0 0 / 0.05);
}

.text-transparent\/50 {

    color: rgb(0 0 0 / 0.5);
}

.text-transparent\/55 {

    color: rgb(0 0 0 / 0.55);
}

.text-transparent\/60 {

    color: rgb(0 0 0 / 0.6);
}

.text-transparent\/65 {

    color: rgb(0 0 0 / 0.65);
}

.text-transparent\/70 {

    color: rgb(0 0 0 / 0.7);
}

.text-transparent\/75 {

    color: rgb(0 0 0 / 0.75);
}

.text-transparent\/80 {

    color: rgb(0 0 0 / 0.8);
}

.text-transparent\/85 {

    color: rgb(0 0 0 / 0.85);
}

.text-transparent\/90 {

    color: rgb(0 0 0 / 0.9);
}

.text-transparent\/95 {

    color: rgb(0 0 0 / 0.95);
}

.text-white {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/0 {

    color: rgb(255 255 255 / 0);
}

.text-white\/10 {

    color: rgb(255 255 255 / 0.1);
}

.text-white\/100 {

    color: rgb(255 255 255 / 1);
}

.text-white\/15 {

    color: rgb(255 255 255 / 0.15);
}

.text-white\/20 {

    color: rgb(255 255 255 / 0.2);
}

.text-white\/25 {

    color: rgb(255 255 255 / 0.25);
}

.text-white\/30 {

    color: rgb(255 255 255 / 0.3);
}

.text-white\/35 {

    color: rgb(255 255 255 / 0.35);
}

.text-white\/40 {

    color: rgb(255 255 255 / 0.4);
}

.text-white\/45 {

    color: rgb(255 255 255 / 0.45);
}

.text-white\/5 {

    color: rgb(255 255 255 / 0.05);
}

.text-white\/50 {

    color: rgb(255 255 255 / 0.5);
}

.text-white\/55 {

    color: rgb(255 255 255 / 0.55);
}

.text-white\/60 {

    color: rgb(255 255 255 / 0.6);
}

.text-white\/65 {

    color: rgb(255 255 255 / 0.65);
}

.text-white\/70 {

    color: rgb(255 255 255 / 0.7);
}

.text-white\/75 {

    color: rgb(255 255 255 / 0.75);
}

.text-white\/80 {

    color: rgb(255 255 255 / 0.8);
}

.text-white\/85 {

    color: rgb(255 255 255 / 0.85);
}

.text-white\/90 {

    color: rgb(255 255 255 / 0.9);
}

.text-white\/95 {

    color: rgb(255 255 255 / 0.95);
}

.text-yellow {

    --tw-text-opacity: 1;

    color: rgb(249 198 107 / var(--tw-text-opacity));
}

.text-yellow-30 {

    --tw-text-opacity: 1;

    color: rgb(255 231 188 / var(--tw-text-opacity));
}

.text-yellow-30\/0 {

    color: rgb(255 231 188 / 0);
}

.text-yellow-30\/10 {

    color: rgb(255 231 188 / 0.1);
}

.text-yellow-30\/100 {

    color: rgb(255 231 188 / 1);
}

.text-yellow-30\/15 {

    color: rgb(255 231 188 / 0.15);
}

.text-yellow-30\/20 {

    color: rgb(255 231 188 / 0.2);
}

.text-yellow-30\/25 {

    color: rgb(255 231 188 / 0.25);
}

.text-yellow-30\/30 {

    color: rgb(255 231 188 / 0.3);
}

.text-yellow-30\/35 {

    color: rgb(255 231 188 / 0.35);
}

.text-yellow-30\/40 {

    color: rgb(255 231 188 / 0.4);
}

.text-yellow-30\/45 {

    color: rgb(255 231 188 / 0.45);
}

.text-yellow-30\/5 {

    color: rgb(255 231 188 / 0.05);
}

.text-yellow-30\/50 {

    color: rgb(255 231 188 / 0.5);
}

.text-yellow-30\/55 {

    color: rgb(255 231 188 / 0.55);
}

.text-yellow-30\/60 {

    color: rgb(255 231 188 / 0.6);
}

.text-yellow-30\/65 {

    color: rgb(255 231 188 / 0.65);
}

.text-yellow-30\/70 {

    color: rgb(255 231 188 / 0.7);
}

.text-yellow-30\/75 {

    color: rgb(255 231 188 / 0.75);
}

.text-yellow-30\/80 {

    color: rgb(255 231 188 / 0.8);
}

.text-yellow-30\/85 {

    color: rgb(255 231 188 / 0.85);
}

.text-yellow-30\/90 {

    color: rgb(255 231 188 / 0.9);
}

.text-yellow-30\/95 {

    color: rgb(255 231 188 / 0.95);
}

.text-yellow-50 {

    --tw-text-opacity: 1;

    color: rgb(255 231 188 / var(--tw-text-opacity));
}

.text-yellow-50\/0 {

    color: rgb(255 231 188 / 0);
}

.text-yellow-50\/10 {

    color: rgb(255 231 188 / 0.1);
}

.text-yellow-50\/100 {

    color: rgb(255 231 188 / 1);
}

.text-yellow-50\/15 {

    color: rgb(255 231 188 / 0.15);
}

.text-yellow-50\/20 {

    color: rgb(255 231 188 / 0.2);
}

.text-yellow-50\/25 {

    color: rgb(255 231 188 / 0.25);
}

.text-yellow-50\/30 {

    color: rgb(255 231 188 / 0.3);
}

.text-yellow-50\/35 {

    color: rgb(255 231 188 / 0.35);
}

.text-yellow-50\/40 {

    color: rgb(255 231 188 / 0.4);
}

.text-yellow-50\/45 {

    color: rgb(255 231 188 / 0.45);
}

.text-yellow-50\/5 {

    color: rgb(255 231 188 / 0.05);
}

.text-yellow-50\/50 {

    color: rgb(255 231 188 / 0.5);
}

.text-yellow-50\/55 {

    color: rgb(255 231 188 / 0.55);
}

.text-yellow-50\/60 {

    color: rgb(255 231 188 / 0.6);
}

.text-yellow-50\/65 {

    color: rgb(255 231 188 / 0.65);
}

.text-yellow-50\/70 {

    color: rgb(255 231 188 / 0.7);
}

.text-yellow-50\/75 {

    color: rgb(255 231 188 / 0.75);
}

.text-yellow-50\/80 {

    color: rgb(255 231 188 / 0.8);
}

.text-yellow-50\/85 {

    color: rgb(255 231 188 / 0.85);
}

.text-yellow-50\/90 {

    color: rgb(255 231 188 / 0.9);
}

.text-yellow-50\/95 {

    color: rgb(255 231 188 / 0.95);
}

.text-yellow-secondary {

    --tw-text-opacity: 1;

    color: rgb(255 231 188 / var(--tw-text-opacity));
}

.text-yellow-secondary\/0 {

    color: rgb(255 231 188 / 0);
}

.text-yellow-secondary\/10 {

    color: rgb(255 231 188 / 0.1);
}

.text-yellow-secondary\/100 {

    color: rgb(255 231 188 / 1);
}

.text-yellow-secondary\/15 {

    color: rgb(255 231 188 / 0.15);
}

.text-yellow-secondary\/20 {

    color: rgb(255 231 188 / 0.2);
}

.text-yellow-secondary\/25 {

    color: rgb(255 231 188 / 0.25);
}

.text-yellow-secondary\/30 {

    color: rgb(255 231 188 / 0.3);
}

.text-yellow-secondary\/35 {

    color: rgb(255 231 188 / 0.35);
}

.text-yellow-secondary\/40 {

    color: rgb(255 231 188 / 0.4);
}

.text-yellow-secondary\/45 {

    color: rgb(255 231 188 / 0.45);
}

.text-yellow-secondary\/5 {

    color: rgb(255 231 188 / 0.05);
}

.text-yellow-secondary\/50 {

    color: rgb(255 231 188 / 0.5);
}

.text-yellow-secondary\/55 {

    color: rgb(255 231 188 / 0.55);
}

.text-yellow-secondary\/60 {

    color: rgb(255 231 188 / 0.6);
}

.text-yellow-secondary\/65 {

    color: rgb(255 231 188 / 0.65);
}

.text-yellow-secondary\/70 {

    color: rgb(255 231 188 / 0.7);
}

.text-yellow-secondary\/75 {

    color: rgb(255 231 188 / 0.75);
}

.text-yellow-secondary\/80 {

    color: rgb(255 231 188 / 0.8);
}

.text-yellow-secondary\/85 {

    color: rgb(255 231 188 / 0.85);
}

.text-yellow-secondary\/90 {

    color: rgb(255 231 188 / 0.9);
}

.text-yellow-secondary\/95 {

    color: rgb(255 231 188 / 0.95);
}

.text-yellow\/0 {

    color: rgb(249 198 107 / 0);
}

.text-yellow\/10 {

    color: rgb(249 198 107 / 0.1);
}

.text-yellow\/100 {

    color: rgb(249 198 107 / 1);
}

.text-yellow\/15 {

    color: rgb(249 198 107 / 0.15);
}

.text-yellow\/20 {

    color: rgb(249 198 107 / 0.2);
}

.text-yellow\/25 {

    color: rgb(249 198 107 / 0.25);
}

.text-yellow\/30 {

    color: rgb(249 198 107 / 0.3);
}

.text-yellow\/35 {

    color: rgb(249 198 107 / 0.35);
}

.text-yellow\/40 {

    color: rgb(249 198 107 / 0.4);
}

.text-yellow\/45 {

    color: rgb(249 198 107 / 0.45);
}

.text-yellow\/5 {

    color: rgb(249 198 107 / 0.05);
}

.text-yellow\/50 {

    color: rgb(249 198 107 / 0.5);
}

.text-yellow\/55 {

    color: rgb(249 198 107 / 0.55);
}

.text-yellow\/60 {

    color: rgb(249 198 107 / 0.6);
}

.text-yellow\/65 {

    color: rgb(249 198 107 / 0.65);
}

.text-yellow\/70 {

    color: rgb(249 198 107 / 0.7);
}

.text-yellow\/75 {

    color: rgb(249 198 107 / 0.75);
}

.text-yellow\/80 {

    color: rgb(249 198 107 / 0.8);
}

.text-yellow\/85 {

    color: rgb(249 198 107 / 0.85);
}

.text-yellow\/90 {

    color: rgb(249 198 107 / 0.9);
}

.text-yellow\/95 {

    color: rgb(249 198 107 / 0.95);
}

.text-opacity-0 {

    --tw-text-opacity: 0;
}

.text-opacity-10 {

    --tw-text-opacity: 0.1;
}

.text-opacity-100 {

    --tw-text-opacity: 1;
}

.text-opacity-15 {

    --tw-text-opacity: 0.15;
}

.text-opacity-20 {

    --tw-text-opacity: 0.2;
}

.text-opacity-25 {

    --tw-text-opacity: 0.25;
}

.text-opacity-30 {

    --tw-text-opacity: 0.3;
}

.text-opacity-35 {

    --tw-text-opacity: 0.35;
}

.text-opacity-40 {

    --tw-text-opacity: 0.4;
}

.text-opacity-45 {

    --tw-text-opacity: 0.45;
}

.text-opacity-5 {

    --tw-text-opacity: 0.05;
}

.text-opacity-50 {

    --tw-text-opacity: 0.5;
}

.text-opacity-55 {

    --tw-text-opacity: 0.55;
}

.text-opacity-60 {

    --tw-text-opacity: 0.6;
}

.text-opacity-65 {

    --tw-text-opacity: 0.65;
}

.text-opacity-70 {

    --tw-text-opacity: 0.7;
}

.text-opacity-75 {

    --tw-text-opacity: 0.75;
}

.text-opacity-80 {

    --tw-text-opacity: 0.8;
}

.text-opacity-85 {

    --tw-text-opacity: 0.85;
}

.text-opacity-90 {

    --tw-text-opacity: 0.9;
}

.text-opacity-95 {

    --tw-text-opacity: 0.95;
}

.underline {

    text-decoration-line: underline;
}

.no-underline {

    text-decoration-line: none;
}

.opacity-25 {

    opacity: 0.25;
}

.opacity-75 {

    opacity: 0.75;
}

.bg-blend-normal {

    background-blend-mode: normal;
}

.bg-blend-multiply {

    background-blend-mode: multiply;
}

.bg-blend-screen {

    background-blend-mode: screen;
}

.bg-blend-overlay {

    background-blend-mode: overlay;
}

.bg-blend-darken {

    background-blend-mode: darken;
}

.bg-blend-lighten {

    background-blend-mode: lighten;
}

.bg-blend-color-dodge {

    background-blend-mode: color-dodge;
}

.bg-blend-color-burn {

    background-blend-mode: color-burn;
}

.bg-blend-hard-light {

    background-blend-mode: hard-light;
}

.bg-blend-soft-light {

    background-blend-mode: soft-light;
}

.bg-blend-difference {

    background-blend-mode: difference;
}

.bg-blend-exclusion {

    background-blend-mode: exclusion;
}

.bg-blend-hue {

    background-blend-mode: hue;
}

.bg-blend-saturation {

    background-blend-mode: saturation;
}

.bg-blend-color {

    background-blend-mode: color;
}

.bg-blend-luminosity {

    background-blend-mode: luminosity;
}

.shadow-md {

    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring {

    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);

    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);

    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.blur {

    --tw-blur: blur(8px);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (max-width: 575px) {

    .mobile-only\:heading-2 {

        font-size: 1.5rem !important;

        font-weight: 400 !important;

        letter-spacing: 0.3px;
    }

    @media (min-width: 576px) {

        .mobile-only\:heading-2 {

            font-size: 1.875rem !important;
        }
    }

    @media (min-width: 1024px) {

        .mobile-only\:heading-2 {

            font-size: 2.25rem !important;
        }
    }

    .mobile-only\:heading-3 {

        font-size: 1.375rem !important;

        font-weight: 400 !important;

        letter-spacing: -0.3px;
    }

    @media (min-width: 576px) {

        .mobile-only\:heading-3 {

            font-size: 1.625rem !important;
        }
    }

    @media (min-width: 1024px) {

        .mobile-only\:heading-3 {

            font-size: 1.875rem !important;
        }
    }

    .mobile-only\:text-4 {

        font-size: 1rem;

        font-weight: 500;

        line-height: 1.5;
    }

    @media (min-width: 576px) {

        .mobile-only\:text-4 {

            font-size: 1rem;
        }
    }

    @media (min-width: 1024px) {

        .mobile-only\:text-4 {

            font-size: 1.125rem;
        }
    }

    .mobile-only\:text-7 {

        font-size: 1rem;

        font-weight: 300;

        line-height: 1.5;
    }

    @media (min-width: 576px) {

        .mobile-only\:text-7 {

            font-size: 1rem;
        }
    }

    @media (min-width: 1024px) {

        .mobile-only\:text-7 {

            font-size: 1.125rem;
        }
    }
}

@media (min-width: 576px) {

    .tablet\:heading-3 {

        font-size: 1.375rem !important;

        font-weight: 400 !important;

        letter-spacing: -0.3px;
    }

    @media (min-width: 576px) {

        .tablet\:heading-3 {

            font-size: 1.625rem !important;
        }
    }

    @media (min-width: 1024px) {

        .tablet\:heading-3 {

            font-size: 1.875rem !important;
        }
    }

    .tablet\:text-2 {

        font-size: 1.25rem;

        font-weight: 300;

        line-height: 1.5;
    }

    @media (min-width: 576px) {

        .tablet\:text-2 {

            font-size: 1.375rem;
        }
    }

    @media (min-width: 1024px) {

        .tablet\:text-2 {

            font-size: 1.5rem;
        }
    }

    .tablet\:text-5 {

        font-size: 1rem;

        font-weight: 300;

        line-height: 1.5;
    }

    @media (min-width: 576px) {

        .tablet\:text-5 {

            font-size: 1rem;
        }
    }

    @media (min-width: 1024px) {

        .tablet\:text-5 {

            font-size: 1.125rem;
        }
    }
}

.first\:mt-2:first-child {

    margin-top: 10px;
}

.last\:mb-2:last-child {

    margin-bottom: 10px;
}

.visited\:text-blue-dark:visited {

    color: rgb(42 40 89 );
}

.hover\:cursor-pointer:hover {

    cursor: pointer;
}

.hover\:border-blue-hover:hover {

    --tw-border-opacity: 1;

    border-color: rgb(31 66 170 / var(--tw-border-opacity));
}

.hover\:bg-blue-hover:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(31 66 170 / var(--tw-bg-opacity));
}

.hover\:text-blue:hover {

    --tw-text-opacity: 1;

    color: rgb(111 233 255 / var(--tw-text-opacity));
}

.hover\:text-blue-focus:hover {

    --tw-text-opacity: 1;

    color: rgb(31 66 170 / var(--tw-text-opacity));
}

.hover\:text-blue-hover:hover {

    --tw-text-opacity: 1;

    color: rgb(31 66 170 / var(--tw-text-opacity));
}

.hover\:text-white:hover {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {

    text-decoration-line: underline;
}

.hover\:no-underline:hover {

    text-decoration-line: none;
}

.hover\:placeholder-blue-hover:hover::placeholder {

    --tw-placeholder-opacity: 1;

    color: rgb(31 66 170 / var(--tw-placeholder-opacity));
}

.focus\:border-4:focus {

    border-width: 4px;
}

.focus\:border-blue-dark:focus {

    --tw-border-opacity: 1;

    border-color: rgb(42 40 89 / var(--tw-border-opacity));
}

.focus\:border-state-danger:focus {

    border-color: #FF8274B3;
}

.focus\:border-state-success:focus {

    border-color: #C7F6C9B3;
}

.focus\:border-state-warning:focus {

    border-color: #F9C66BB3;
}

.focus\:bg-blue-focus:focus {

    --tw-bg-opacity: 1;

    background-color: rgb(31 66 170 / var(--tw-bg-opacity));
}

.focus\:text-blue:focus {

    --tw-text-opacity: 1;

    color: rgb(111 233 255 / var(--tw-text-opacity));
}

.focus\:text-blue-focus:focus {

    --tw-text-opacity: 1;

    color: rgb(31 66 170 / var(--tw-text-opacity));
}

.focus\:underline:focus {

    text-decoration-line: underline;
}

.focus\:outline-none:focus {

    outline: 2px solid transparent;

    outline-offset: 2px;
}

@media (max-width: 575px) {

    .mobile-only\:relative {

        position: relative;
    }

    .mobile-only\:top-nav-small {

        top: 66px;
    }

    .mobile-only\:m-3 {

        margin: 15px;
    }

    .mobile-only\:mb-2 {

        margin-bottom: 10px;
    }

    .mobile-only\:mt-1 {

        margin-top: 5px;
    }

    .mobile-only\:hidden {

        display: none;
    }

    .mobile-only\:h-body-small-header {

        height: calc(100vh - 66px);
    }

    .mobile-only\:h-logo-small {

        height: 45px;
    }

    .mobile-only\:h-nav-small {

        height: 66px;
    }

    .mobile-only\:w-2\/3 {

        width: 66.666667%;
    }

    .mobile-only\:w-full {

        width: 100%;
    }

    .mobile-only\:bg-white {

        --tw-bg-opacity: 1;

        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }

    .mobile-only\:p-3 {

        padding: 15px;
    }

    .mobile-only\:pb-3 {

        padding-bottom: 15px;
    }

    .mobile-only\:pl-1 {

        padding-left: 5px;
    }
}

@media (min-width: 576px) {

    .tablet\:mx-\[80px\] {

        margin-left: 80px;

        margin-right: 80px;
    }

    .tablet\:w-10\/12 {

        width: 83.333333%;
    }

    .tablet\:w-11\/12 {

        width: 91.666667%;
    }

    .tablet\:max-w-3\/4 {

        max-width: 75%;
    }

    .tablet\:px-5 {

        padding-left: 50px;

        padding-right: 50px;
    }

    .tablet\:px-\[40px\] {

        padding-left: 40px;

        padding-right: 40px;
    }

    .tablet\:pb-6 {

        padding-bottom: 75px;
    }

    .tablet\:pt-6 {

        padding-top: 75px;
    }
}

@media (min-width: 1024px) {

    .desktop\:mx-0 {

        margin-left: 0px;

        margin-right: 0px;
    }

    .desktop\:mx-auto {

        margin-left: auto;

        margin-right: auto;
    }
}

@media (min-width: 1280px) {

    .widescreen\:w-6\/12 {

        width: 50%;
    }

    .widescreen\:w-8\/12 {

        width: 66.666667%;
    }
}

@media (min-width: 1600px) {

    .fullhd\:max-w-1\/2 {

        max-width: 50%;
    }
}
