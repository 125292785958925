.languageSelector-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .languageSelector-element {
    display: flex;
    flex-direction: column;
  }
}
