.bymelding-spinner-small {
  top: calc(50vh - 40px);
  left: calc(50vw - 40px);
}

.bymelding-spinner-large {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fade-in {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.bymelding-spinner {
  z-index: 9000;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
        &.bg-white {
      background: #fff;
    }
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}

.bymelding-spinner-inline {
  height: 24px;
  width: auto;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 24px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &.bg-white {
      background: #fff;
    }
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}

@keyframes lds-ellipsis1 {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(24px, 0);
  }
}

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}

.fade-in {
  opacity: 0;
  opacity: 0.75 \9; /*just in case ie*/
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }
}

.inline-fade-in {
  opacity: 0;
  opacity: 1 \9;
  animation: inline-fade-in ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes inline-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}