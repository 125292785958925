@import '~minireset.css/minireset.sass';
@import './style/ok-styleguide.scss';
@import './style/varables.scss';

:root {
  --full-inner-vh: calc(var(--vh, 1vh) * 100);
  --color-beige: #f8f0dd;
  --color-beige-dark: #d0bfae;
  --color-black: #2c2c2c;
  --color-blue: #6fe9ff;
  --color-blue-dark: #2a2859;
  --color-blue-hover: #1f42aa;
  --color-blue-light: #b3f5ff;
  --color-green: #43f8b6;
  --color-green-dark: #034b45;
  --color-green-light: #c7f6c9;
  --color-grey: #f2f2f2;
  --color-grey-light: #f9f9f9;
  --color-purple-visited: #8d50b1;
  --color-purple-visited-light: #e0adff;
  --color-red: #ff8274;
  --color-yellow: #f9c66b;

  --mobile: 0;
  --tablet: 769px;
  --desktop: 1024px;
  --widescreen: 1216px;
  --fullhd: 1408px;
}

@font-face {
  font-family: 'OsloSans';
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
  src: local('OsloSans'), url(./assets/fonts/OsloSans-Regular.woff2) format('woff2'),
    url(./assets/fonts/OsloSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'OsloIcons';
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: local('OsloIcons'), url(./assets/fonts/OsloIcons.woff) format('woff');
}

$font-family-sans-serif: 'OsloSans';
$font-family-base: 'OsloSans';
$enable-shadows: false;
$enable-gradients: false;
$enable-rounded: false;
$enable-transitions: true;

body {
  margin: 0;
  font-family: $font-family-sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$header-padding: 8px;
$header-content-height: 40px;
$header-content-padding: 0.3125rem;
$header-total-height: calc(#{$header-content-height} + (#{$header-padding} * 2) + (#{$header-content-padding} * 2));

$zindex-fixed: 1030;

body,
html {
  color: var(--color-blue-dark);
  font-family: 'OsloSans', Arial, 'sans-serif';
  font-size: 100%;
  font-weight: 300;
}

a {
  text-decoration: none;
}
button {
  font-family: 'OsloSans', Arial, 'sans-serif';
}
hr {
  height: 1px;
  background: inherit;
  border-bottom: 1px solid #dcdcdc;
}
.osg-sr-only + osg-sr-only h1,
h2,
h3,
h4,
h5 {
  hyphens: auto;
}
