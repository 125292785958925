$osg-icons: (
  'chevron-right': '\e908',
  'chevron-down': '\e906',
  'chevron-left': '\e907',
  'chevron-up': '\e909',
  '24h': '\e900',
  'backpack': '\e901',
  'bike': '\e902',
  'bus': '\e903',
  'cafe': '\e904',
  'calendar': '\e905',
  'coin-stacks': '\e90a',
  'crane': '\e90b',
  'dog': '\e90c',
  'drone': '\e90d',
  'exclamation-mark-circle': '\e90e',
  'filter': '\e910',
  'fire-emblem': '\e911',
  'handicap': '\e912',
  'house-heart': '\e913',
  'location-pin': '\e914',
  'magnifying-glass-big': '\e915',
  'magnifying-glass-small': '\e916',
  'menu': '\e917',
  'minus-sign': '\e918',
  'museum': '\e919',
  'park': '\e91a',
  'plus-sign': '\e91b',
  'recycling': '\e91c',
  'restaurant': '\e91d',
  'swingset': '\e91e',
  'toilet': '\e91f',
  'tram': '\e920',
  'user': '\e921',
  'water-faucet': '\e922',
  'x': '\e923',
  'facebook': '\e924',
  'instagram': '\e925',
  'linked-in': '\e926',
  'twitter': '\e927',
  'heart-plus': '\e928',
  'clock': '\e935',
  'garbage': '\e942',
  'tickets': '\e938',
  'email': '\e929',
  'chevron-thin-up': '\e939',
  'chevron-thin-down': '\e92a',
  'elevator': '\e92b',
  'street-vendor': '\e92c',
  'coffee-cup': '\e92d',
  'defibrillator': '\e92f',
  'bathroom': '\e930',
  'healthcare': '\e931',
  'livingroom': '\e932',
  'wardrobe': '\e933',
  'bedroom': '\e934',
);

[class*='osg-icon-'] {
  font-size: 32px;
  display: inline-flex;
}
// Generating icon classes
@each $name, $content in $osg-icons {
  .osg-icon-#{$name} {
    &:before,
    &:hover:before {
      color: inherit;
    }
    &:before {
      speak: none;
      font-family: 'OsloIcons', 'sans-serif';
      line-height: 1;
      font-style: normal;
      content: '#{$content}';
    }
  }
}
