.bymelding-publikum-navbar {
  /* Status bar height on iOS 11.0 */
  padding-top: constant(safe-area-inset-top);
  /* Status bar height on iOS 11+ */
  padding-top: env(safe-area-inset-top);

  border-bottom: 1px solid black;
  .ok-icon {
    fill: var(--color-blue-dark);
  }
  .bymelding-publikum-navbar-menu-button-icon {
    font-size: 42px;
  }
}

.bymelding-pullout {
  .bymelding-pullout-overlay {
    background-color: rgba(black, 0.5);
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3031;

    &:active,
    &:focus {
      outline: none !important;
    }
  }

  .bymelding-pullout-menu {
    position: fixed;
    top: 0;
    max-width: 400px;
    width: 75vw;
    height: 100vh;
    transition: right 300ms;
    z-index: 3032;
    padding: 15px 20px;
  }
  .navbar-btn {
    padding: 0;
    font-family: 'OsloSans', 'sans-serif';
  }
  .bymelding-pullout-menu-links-flex-end {
    margin-top: auto;
  }

  &.bymelding-pullout-open {
    .bymelding-pullout-overlay {
      left: 0;
    }

    .bymelding-pullout-menu {
      right: 0;
    }
  }

  &.bymelding-pullout-closed {
    .bymelding-pullout-overlay {
      right: -100vw;
    }

    .bymelding-pullout-menu {
      right: -100%;
    }
  }
}

@media (max-height: 700px) {
  .bymelding-pullout-menu {
    overflow-y: scroll;
  }
}

// @media (max-width: 770px) {
//   .bymelding-publikum-navbar {
//     .bymelding-publikum-navbar-menu-button-icon {
//       font-size: 42px;
//     }
//   }
// }
