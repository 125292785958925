.checkbox {
  .checkbox-label {
    display: flex;
    align-items: flex-start;
    position: relative;

    :hover {
      cursor: pointer;
    }

    input[type='checkbox'] {
      display: flex;
      appearance: none;
      flex-shrink: 0;
      background-color: #fff;
      border: 2px solid var(--color-blue-dark);
      width: 20px;
      height: 20px;
      &:checked {
        background-color: var(--color-blue-hover);
        border-color: var(--color-blue-hover);
        &::after {
          content: '';
          position: relative;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 8px;
          height: 16px;
          top: 7px;
          left: 9px;
        }
      }
    }
  }
}
