.inputfield {
  display: block;
  box-sizing: border-box;
  height: 55px;
  width: 100%;
  border: none;
  outline: 2px;
  font-family: OsloSans;
  &:invalid {
    outline: none;
    box-shadow: none;
  }
}

.textArea {
  font-family: OsloSans;
}
