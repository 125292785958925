.sok-input-textfield::-ms-clear {
  display: none;
}

.sok-input-textfield {
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 1.5;
  font-family: OsloSans;
  &:invalid {
    outline: none;
    box-shadow: none;
  }
}

@media (max-width: 450px) {
  .sok-input-textfield {
    font-size: 1rem;
  }
}
