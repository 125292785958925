.folgere-badge {
  border: 1px solid;
  border-radius: 1em;
  padding: 0 1em;
  max-width: max-content;

  span {
    margin-left: 0.5em;
  }
}
