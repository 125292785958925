.bymelding-register-page {
  .bymelding-register-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    button {
      margin-top: 20px;
    }
  }
}
