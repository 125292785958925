.vis-detaljer {
  display: inline-flex;
  align-items: center;
  color: #0071bb;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.vis-detaljer-arrow {
  display: inline-flex;
  border: 1px solid #0071bb;
  width: 0.8em;
  height: 0.8em;
  margin-left: 10px;
  border-width: 0 0.3em 0.3em 0;
  border-bottom-right-radius: 3px;
  &.up {
    align-self: baseline;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.down {
    align-self: center;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}
