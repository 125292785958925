.button-customization {
  &:disabled {
    border-color: #f2f2f2;
    &:hover {
      border-color: #f2f2f2;
    }
    opacity: 75%;
  }
}

.oslo-button {
  // transition: transform 500ms;
  // transition-timing-function: cubic-bezier(0.3, 0, 0.5, 1);
  &:active {
    transform: scale(0.95);
  }
}

.oslo-common-button {
  font-family: 'OsloSans', 'sans-serif';
  &:hover,
  &:focus {
    background-color: var(--color-blue-hover);
    color: #fff;
  }
}
.oslo-outline-button {
  font-family: 'OsloSans', 'sans-serif';
  &:hover,
  &:focus {
    border-color: var(--color-blue-hover);
    color: var(--color-blue-hover);
  }
}

.oslo-text-button {
  font-size: inherit;
  font-weight: inherit;
  &:hover,
  &:focus {
    color: var(--color-blue-hover);
  }
}
