@import 'src/assets/main';

.vilkaar-page {
  h1,
  h2,
  h3,
  h4,
  h5 {
    hyphens: auto;
  }

  p {
    @extend .text-5;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0.4em;
    hyphens: auto;
  }
  h1 {
    @extend .heading-1;
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
  h2 {
    @extend .heading-2;
    &:not(:first-child) {
      margin-top: 1.1428em;
    }
  }
  h3 {
    @extend .heading-3;
  }
  h4 {
    @extend .heading-4;
  }
  h5 {
    @extend .heading-5;
  }
  ul li,
  ol li {
    @extend .text-5;
  }
  strong {
    font-weight: 500;
  }

  ul {
    list-style: disc outside;
    margin-left: 2em;
    margin-top: 1em;
  }
  p {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}
