$item-content-border: #f2f2f2;
$oslo-blaa: #2a2859;

.meldinger,
.fulgte-meldinger {
  &:nth-child(2) {
    margin-top: 50px;
  }

  button.fane-button {
    color: rgb(51, 51, 51);

    &:focus {
      outline: 5px auto;
      outline-offset: -2px;
    }
    &.active {
      background-color: rgb(229, 229, 229);
    }
  }

  .meldingsfilter {
    .filter-chip {
      border: 1px solid lightgray;
      height: 2em;
      border-radius: 1em;
      font-size: 1em;
      padding: 0 1em;
      background-color: #fff;
      color: rgb(51, 51, 51);
      margin-right: 0.5em;
      outline: none;
      white-space: nowrap;
      &:hover {
        cursor: pointer;
      }

      &:active {
        transform: scale(0.95);
      }

      &:focus {
        //background-color: var(--color-blue-hover);
        border-color: var(--color-blue-hover);
        //color: white;
      }

      &.selected {
        background-color: rgb(229, 229, 229);

        &:focus {
          background-color: var(--color-blue-hover);
          //border-color: var(--color-blue-hover);
          color: white;
        }
      }
    }
  }

  ul.meldingsliste {
    li.meldingsliste-item {
      border-bottom: 1px solid $item-content-border;

      & > div {
        padding: 10px 10px 10px 0;
        display: flex;
        flex-direction: row;

        &:focus {
          outline: none;
        }

        &.clickable {
          cursor: pointer;

          &:hover,
          &:focus {
            color: #fff;
            background-color: var(--color-blue-hover);
          }
        }

        .meldingsliste-item-icon {
          flex: 0;
          padding: 10px;
          margin-right: 10px;
          min-width: 65px;
          width: 65px;
          height: 65px;

          i {
            font-size: 45px;
          }
          svg {
            height: 100%;
            width: 100%;
          }
        }

        .meldingsliste-item-content {
          flex: 1;
        }

        .meldingsliste-item-row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: row;

          .badges {
            display: flex;
            flex-direction: row;

            .folgere-badge {
              margin-right: 0.5em;
            }
          }

          // @media (max-width: 768px) {
          //   .badges {
          //     flex-direction: row-reverse;

          //     .folgere-badge {
          //       margin-right: unset;
          //     }
          //     .status-badge {
          //       margin-right: 0.5em;
          //     }
          //   }
          // }
        }

        .meldingsliste-item-meldingstype {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}

.status-badge {
  border: 1px solid;
  border-radius: 1em;
  padding: 0 1em;
  max-width: max-content;
}

.meldingsdetaljer {
  .leaflet-container {
    height: 200px;
    position: relative;
    top: 0;
  }

  .leaflet-interactive {
    cursor: default;
  }

  .marker-pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: $oslo-blaa;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
    cursor: default;

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      margin: 3px 0 0 3px;
      background: #fff;
      position: absolute;
      border-radius: 50%;
    }
  }

  .custom-div-icon {
    i {
      font-size: 30px;
      position: absolute;
      top: 6px;
    }
  }

  .info {
    padding: 10px;

    .status {
      .status-badge,
      .status-text {
        margin-top: 10px;
      }

      .status-badge {
        display: inline-block;
      }
    }

    dl {
      display: table;
      // border-top: 1px solid red;

      .row {
        display: table-row;

        dt {
          font-weight: bold;
          vertical-align: middle;
        }

        dt,
        dd {
          display: table-cell;
          padding: 0.5em;

          img {
            max-height: 150px;
            max-width: 200px;
            margin: 0 0.25em;
          }
        }
      }
    }
  }
}

@media (min-width: 1280px) {
  .meldingsdetaljer {
    .leaflet-container {
      height: 300px;
    }
  }
}
